import AcceptedPayment from "./AcceptedPayment"
import AccountIcon from "../../../components/AccountIcon"
import DisableAutocompletesEffect from "../../../helpers/DisableAutocompletesEffect.js"
import Dropdown from '../../../components/Dropdown'
import isValidAddress from '../../../helpers/isValidAddress'
import React, { useState, useEffect, useMemo } from "react"
import { ethers } from 'ethers'
import { GET } from '../../../helpers/Queries'
import { PublicKey } from '@depay/solana-web3.js'
import { Store } from '../../../Store'
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

export default function(props) {

  const account = useSelector(state => state.account)
  const accountWallets = useSelector(state => state.account_wallets)
  const [ accountManaged, setAccountManaged ] = useState()
  const navigate = useNavigate()

  const accountWalletsAsOptionData = useMemo(()=>{
    return accountWallets.map((accountWallet)=>({...accountWallet, label: `${accountWallet.name} ${accountWallet.external_id}`, value: accountWallet.external_id }))
  }, [])

  const setReceiver = (newAcceptedPayments, receiver, index, blockchain)=>{
    
    newAcceptedPayments[index] = {... newAcceptedPayments[index], receiver}

    if(!receiver || receiver.length === 0) {
      newAcceptedPayments[index].error = 'Please select or enter a receiver!'
    } else {
      if(isValidAddress(blockchain, receiver)) {
        newAcceptedPayments[index].error = undefined
      } else {
        newAcceptedPayments[index].error = 'This address is invalid!'
      }
    }

    return newAcceptedPayments
  }

  const updatePaymentAmount = (index, amount)=> {
    let updatedAcceptedPayments = JSON.parse(JSON.stringify(props.acceptedPayments || account.accepted))
    props?.amounts?.forEach((priorAmount, index)=>{
      if(!updatedAcceptedPayments[index]) { updatedAcceptedPayments[index] = {} }
      updatedAcceptedPayments[index].amount = priorAmount.amount
    })
    updatedAcceptedPayments[index].amount = amount
    props.onChange(updatedAcceptedPayments)
  }

  const addAcceptedPayment = ()=> {
    DePayWidgets.Select({ what: 'token' }).then((token)=>{
      if(props.acceptedPayments?.find((payment)=>payment.token.address == token.address && payment.token.blockchain == token.blockchain) == undefined) {
        let newAcceptedPayments = props.acceptedPayments instanceof Array ? props.acceptedPayments.slice() : []
        newAcceptedPayments.push({
          token
        })
        setReceiver(newAcceptedPayments, '', newAcceptedPayments.length-1, token.blockchain)
        props.onChange(newAcceptedPayments)
      }
    })
  }

  const removeAcceptedPayment = (index)=>{
    let newAcceptedPayments = props.acceptedPayments.slice()
    newAcceptedPayments.splice(index, 1)
    props.onChange(newAcceptedPayments)
  }

  useEffect(DisableAutocompletesEffect, [])

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/account_wallets`).then((accountWallets)=>{
      Store.dispatch({ type: 'accountWallets/set', accountWallets })
    })
  }, [])

  useEffect(()=>{
    setAccountManaged(account?.accepted && account?.accepted?.length && !props.acceptedPayments)
  }, [account, props.acceptedPayments])

  if(accountManaged) {

    return(
      <div>
        <div className="d-flex mb-2 align-items-center">
          <div className="flex-grow-1 ps-1">
            <small className="opacity-50"><strong>Account-wide settings</strong></small>
          </div>
          <div className="flex-grow-0">
            <Dropdown
              toggle={
                <button className="btn btn-action-hover" type="button">
                  <i className="fas fa-ellipsis-v text-black-50"></i>
                </button>
              }
              children={[
                ['Override settings', ()=>{ props.onChange([]) }, <i className="fa-solid fa-pen-to-square me-2"></i>],
                ['Edit account settings', ()=>{ navigate(`/settings/tokens`) }, <i className="fas fa-solid fa-gear me-2"></i>],
              ]}
            />
          </div>
        </div>

        { account.accepted?.map((payment, index)=>{
          return(
            <AcceptedPayment
              key={ `${index}-${payment.token.address}` }
              index={ index }
              accountManaged={ accountManaged }
              payment={ payment }
              accountWalletsAsOptionData={ accountWalletsAsOptionData }
              updatePaymentAmount={ updatePaymentAmount }
              removeAcceptedPayment={ removeAcceptedPayment }
              acceptedPayments={ props.acceptedPayments }
              onChange={ props.onChange }
              withAmounts={ props.withAmounts }
              amounts={ props.amounts }
            />
          )
        })}
      </div>
    )

  } else {

    return(
      <div>

        { props.hideAccountSettings !== true &&
          <div className="d-flex mb-2 align-items-center">
            <div className="flex-grow-1 ps-1">
              <small className="opacity-50"><strong>Instance-Specific configuration</strong></small>
            </div>
            <div className="flex-grow-0">
              <Dropdown
                toggle={
                  <button className="btn btn-action-hover" type="button">
                    <i className="fas fa-ellipsis-v text-black-50"></i>
                  </button>
                }
                children={[
                  account.accepted?.length > 0 ? ['Use account settings', ()=>{ props.onChange() }, <i className="fas fa-solid fa-gear me-2"></i>] : ['Edit account settings', ()=>{ navigate(`/settings/tokens`) }, <i className="fas fa-solid fa-gear me-2"></i>],
                ]}
              />
            </div>
          </div>
        }

        { props.acceptedPayments?.map((payment, index)=>{
          return(
            <AcceptedPayment
              key={ `${index}-${payment.token.address}` }
              index={ index }
              payment={ payment }
              accountWalletsAsOptionData={ accountWalletsAsOptionData }
              setReceiver={ setReceiver }
              updatePaymentAmount={ updatePaymentAmount }
              removeAcceptedPayment={ removeAcceptedPayment }
              acceptedPayments={ props.acceptedPayments }
              onChange={ props.onChange }
              withAmounts={ props.withAmounts }
            />
          )
        })}

        <div className='pt-2 pb-2'>
          <button type="button" className="btn btn-action btn-sm me-3" onClick={ addAcceptedPayment }>
            <i className="fas fa-plus me-2"></i>
            Add Token
          </button>
        </div>

      </div>
    )
  }

}
