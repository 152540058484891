export default function UserWalletsReducer(state = [], action) {
  let newState
  switch (action.type) {
    case 'userWallets/add':
      newState = (state).slice()
      newState.byExternalId = {}
      newState.push(action.userWallet)
      newState.byExternalId[action.userWallet.external_id] = action.userWallet
      return newState
    case 'userWallets/set':
      newState = action.userWallets.slice()
      newState.byExternalId = {}
      newState.forEach((userWallet)=>{
        newState.byExternalId = { [userWallet.external_id]: userWallet }
      })
      return newState
    case 'userWallets/update':
      newState = (state).slice()
      let updatedLinkIndex = newState.findIndex((i)=>action.userWallet.id==i.id)
      if(updatedLinkIndex > -1){
        newState[updatedLinkIndex] = action.userWallet
        newState.byExternalId = newState.byExternalId || {}
        newState.byExternalId[action.userWallet.external_id] = action.userWallet
      }
      return newState
    case 'userWallets/delete':
      let deletedAccountWallet
      newState = state.filter((userWallet)=>{
        if(userWallet.id == action.id) {
          deletedAccountWallet = userWallet
          return false
        } else {
          return true
        }
      })
      return newState
    default:
      return state
  }
}
