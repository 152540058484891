import moment from 'moment'

export default function(data, range){
  let returnData
  const now = moment().toDate()
  if(data) {
    returnData = data.map((entry)=>{
      if(moment(entry.time).toDate() <= now) {
        return entry
      } else {
        return Object.assign(entry, { value: undefined })
      }
    })
  } else {
    const explodedLength = range.split('-').length
    if(explodedLength === 1) {
    } else if(explodedLength === 2) {
      returnData = Array.from({ length: moment(range).daysInMonth() }, (_, i)=> i+1).map((day)=>{
        const time = `${range}-${day.toString().padStart(2, '0')}`
        const value = (moment(time).toDate() <= now) ? 0 : undefined
        return { time, value }
      })
    } else if(explodedLength === 3) {
    }
  }
  // add a little padding left
  const first = returnData[0]
  if(first && first.time) {
    const explodedLength = first.time.split('-').length
    if(explodedLength === 1) {
    } else if(explodedLength === 2) {
    } else if(explodedLength === 3) {
      const newMoment = moment(first.time).subtract(1, 'days')
      returnData.unshift({ time: newMoment.format('YYYY-MM-DD'), value: undefined })
    }
  }
  return returnData
}
