import Dialog from '../components/Dialog'
import React, { useState, useEffect } from "react"

export default function(props){

  const [quantity, setQuantity] = useState(props.quantity)
  const [valid, setValid] = useState()
  const [max, setMax] = useState( props.limit ? Math.min(props.limit, props.max) : props.max )
  const [col, setCol] = useState(2)

  const update = (event)=>{
    event.preventDefault()
    if(valid) {
      props.update(quantity)
      props.close()
    }
    return false
  }

  useEffect(()=>{
    setMax( props.limit ? Math.min(props.limit, props.max) : props.max )
  }, [props.max, props.limit])

  useEffect(()=>{
    setValid(
      quantity > 0 && 
      quantity >= props.min &&
      quantity <= max
    )
  }, [quantity])

  useEffect(()=>{
    if (max.toString().length > 7) {
      setCol(6)
    } else if (max.toString().length > 5) {
      setCol(5)
    } else if (max.toString().length > 3) {
      setCol(4)
    } else {
      setCol(3)
    }

    if(max < quantity) {
      setQuantity(max)
    }
  }, [max])

  useEffect(()=>{
    if(props.min > quantity) {
      setQuantity(props.min)
    }
  }, [props.min])

  return(
    <>
      <Dialog
        open={ props.open }
        close={ props.close }
        header={ 'Change quantity' }
        body={
          <form onSubmit={update} className="d-flex justify-content-center">
            <div className="d-flex align-items-center p-2">
              <button
                type="button"
                title="Decrease quantity"
                className="btn btn-primary rounded-circle p-0 d-flex align-items-center text-center justify-content-center"
                style={{ width: '30px', height: '30px', backgroundColor: props.primaryColor, borderColor: props.primaryColor, color: props.textColor }}
                onClick={()=>{ setQuantity(quantity-1) }}
              ><span className="font-size-xl" style={{ color: props.textColor, position: 'relative', top: '-2px' }}>-</span></button>
            </div>
            <div className={`col col-${col}`}>
              <input
                className="form-control form-control-lg text-center"
                type="number"
                min={ props.min }
                max={ max }
                step={ 1 }
                value={ quantity }
                onChange={ (event)=> setQuantity(parseInt(event.target.value), 10) }
              />
            </div>
            <div className="d-flex align-items-center p-2">
              <button
                type="button"
                title="Increase quantity"
                className="btn btn-primary rounded-circle p-0 d-flex align-items-center text-center justify-content-center"
                style={{ width: '30px', height: '30px', background: props.primaryColor, borderColor: props.primaryColor, color: props.textColor }}
                onClick={()=>{ setQuantity(quantity+1) }}
              ><span className="font-size-xl" style={{ color: props.textColor, position: 'relative', top: '-2px', left: '-0.5px' }}>+</span></button>
            </div>
          </form>
        }
        footer={
          <div className="p-2 text-center">
            { !valid &&
              <div className="alert alert-info p-2 px-3 line-height-extra-small mb-1">
                <small className="">
                  You need to set a value between { (new Intl.NumberFormat()).format(props.min) } and { (new Intl.NumberFormat()).format(max) }
                </small>
              </div>
            }
            <div className="mb-1">
              <button disabled={!valid} type="button" onClick={ update } className="btn btn-primary btn-lg rounded-2 py-2 mb-2 w-100" style={{ background: props.primaryColor, borderColor: props.primaryColor }}>
                <div className="py-1 w-100" style={{ color: props.textColor }}>
                  Update
                </div>
              </button>
            </div>
          </div>
        }
      />
    </>
  )
}
