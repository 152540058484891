import { ethers } from 'ethers'
import { PublicKey } from '@depay/solana-web3.js'

export default function(blockchain, address){
  try {

    if(blockchain === 'solana') {
      new PublicKey(address).toString()
    } else {
      ethers.utils.getAddress(address)
    }

    return true

  } catch { return false }
}
