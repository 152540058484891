import AddressEllipsis from "../helpers/AddressEllipsis"
import ApiKeyRow from "../components/api/ApiKeyRow"
import ApiSecretRow from "../components/api/ApiSecretRow"
import React, { useEffect } from "react"
import { GET } from '../helpers/Queries'
import { NavLink } from "react-router-dom"
import { Store } from '../Store'
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)

  return(

    <div className="screen api-screen">

      <div className="row">
        <div className="col-12 col-xl-6">
          
          <div className="pt-2">
            <div className="card d-block px-3">
              <div className="pb-3">
                <ApiKeyRow/>
              </div>
              <div className="">
                <a href="https://depay.com/documentation/api" className="link-light ps-2" target="_blank" title="Read documentation">
                  <i className="fas fa-book me-1"></i>
                  <span> API Documentation</span>
                  <i className="fas ms-2 fa-external-link-alt"></i>
                </a>
              </div>
              <div className="d-block w-100 text-center px-5 pt-3">
                <div className="">
                  <div className="pb-1 pt-1 text-center text-sm-start">
                    <div className="mascot mascot-api d-inline-block d-sm-block"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
