import Dropdown from '../components/Dropdown'
import ExportXLSX from "../helpers/ExportXLSX"
import loadAdditionalTokenDataForPayments from "../helpers/loadAdditionalTokenDataForPayments"
import LoadingSpinner from "../components/LoadingSpinner"
import moment from 'moment'
import Onboarding from '../components/Onboarding'
import Payment from "../models/Payment"
import PaymentList from '../components/payments/List'
import React, { useEffect, useState } from "react"
import Token from "../models/Token"
import { GET } from "../helpers/Queries"
import { NavLink, useParams } from "react-router-dom"
import { Store } from '../Store'
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const accountWallets = useSelector(state => state.account_wallets)
  const payments = useSelector(state => state.payments)
  const tokens = useSelector(state => state.tokens)
  const integrations = useSelector(state => state.integrations)
  const [ disabled, setDisabled ] = useState(false)
  const [ loading, setLoading ] = useState(true)
  const [ noPayments, setNoPayments ] = useState(false)
  const [ initialLoading, setInitialLoading ] = useState((payments?.groups?.length || 0) == 0)
  const [ hasMore, setHasMore ] = useState(true)
  const [ preparingExport, setPreparingExport ] = useState(false)
  const [ exportReady, setExportReady ] = useState()
  const [ exportData, setExportData ] = useState()
  const [ exportError, setExportError ] = useState()
  const [ exportCurrentCount, setExportCurrentCount ] = useState()
  const [ totalPayments, setTotalPayments ] = useState()
  const activeSubscription = useSelector(state => state.subscription)
  let [ accountWalletIds, setAccountWalletIds ] = useState({})

  const exportNow = async(data)=>{
    if(data === undefined) {
      data = exportData
    }
    let schema = {
      confirmed_at: (value)=>(new Date(value)).toISOString().slice(0,19).replace('T', ' '),
      blockchain: (value)=>value,
      transaction_id: (value)=>value,
      sender_id: (value)=>value,
      receiver_id: (value)=>value,
      receiver_token: (value, row)=>tokens.byId[`${row.blockchain}-${row.receiver_token_id}`]?.symbol,
      receiver_token_id: (value)=>value,
      receiver_amount: (value)=>value,
      receiver_amount_usd: (value)=>value,
    }
    if(data.some((datum)=>datum.form_data)) {
      let allFormFields = {}
      data.forEach((datum)=>{
        if(typeof(datum?.form_data) == 'object' && datum?.form_data != null) {
          Object.keys(datum.form_data).forEach((key)=>{
            schema[key] = ['form_data', (value)=>value]
          })
        }
      })
    }
    try {
      await ExportXLSX(data, schema, !activeSubscription)
    } catch (e){
      setExportError(e.toString())
    }
  }

  const exportFile = ()=>{
    let paymentsToExport = []
    setExportError()
    setExportReady()
    setPreparingExport(true)
    setExportCurrentCount(0)
    let currentCount = 0
    const loadMoreForExportFile = (oldestPayment)=>{
      GET(`/api/accounts/${account.id}/payments?after=${oldestPayment?.confirmed_at || ''}`).then(async (responseData)=>{
        await loadAdditionalTokenDataForPayments(responseData.items)
        currentCount += responseData.items.length
        setExportCurrentCount(currentCount)
        if(!activeSubscription){ paymentsToExport = responseData.items }
        if(responseData.items.length == 0 || !activeSubscription){
          setExportReady(true)
          setExportData(paymentsToExport)
          exportNow(paymentsToExport)
        } else {
          paymentsToExport = paymentsToExport.concat(responseData.items)
          loadMoreForExportFile(responseData.items[responseData.items.length-1])
        }
      })
    }
    loadMoreForExportFile()
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/integrations`).then((integrations)=>{
      Store.dispatch({ type: 'integrations/set', integrations })
    })
  }, [])

  useEffect(()=>{
    if(account) {
      GET(`/api/accounts/${account.id}/account_wallets`).then((accountWallets)=>{
        Store.dispatch({ type: 'accountWallets/set', accountWallets })
        GET(`/api/accounts/${account.id}/payments`).then((responseData)=>{
          setTotalPayments(responseData?.count)
          setInitialLoading(false)
          if(responseData.items.length == 0) {
            setHasMore(false)
            setNoPayments(true)
          } else {
            const latestGroup = payments.byGroupId[payments.groups[0]]
            let latestPayment
            if(latestGroup) { latestPayment  = payments.byId[latestGroup[0]] }
            if(latestPayment && latestPayment.confirmed_at == responseData.items[0].confirmed_at){
              // up to date
            } else {
              loadAdditionalTokenDataForPayments(responseData.items)
              Store.dispatch(({ type: 'payments/set', payments: responseData.items }))
            }
          }
        })
      })
    }
  }, [account])

  const loadMore = ()=>{
    if(payments.groups.length == 0) { return }
    const oldestGroup = payments.byGroupId[payments.groups[payments.groups.length-1]]
    const oldestPayment = payments.byId[oldestGroup[oldestGroup.length-1]]
    GET(`/api/accounts/${account.id}/payments?after=${oldestPayment.confirmed_at}`).then((responseData)=>{
      if(responseData?.items?.length == 0){
        setHasMore(false)
      } else {
        loadAdditionalTokenDataForPayments(responseData?.items)
        Store.dispatch(({ type: 'payments/add', payments: responseData?.items }))
      }
    })
  }

  if(initialLoading) { return(
    <div className="screen payments-screen">
      <div className="col-lg-6">
        <div className="pt-3">
          <div className="card skeleton w-100 py-2 pb-2">
            <div style={{ height: '6rem' }} className="w-100"/>
          </div>
        </div>
        <div className="pt-3">
          <div className="card skeleton w-100 py-2 pb-2">
            <div style={{ height: '6rem' }} className="w-100"/>
          </div>
        </div>
        <div className="pt-3">
          <div className="card skeleton w-100 py-2 pb-2">
            <div style={{ height: '6rem' }} className="w-100"/>
          </div>
        </div>
      </div>
    </div>
  ) }

  if(noPayments) {
    return(
      <div className="screen payments-screen">

        <div className="row pt-4 pt-lg-3">
          <div className="col">
            <h2 className="pb-2">No payments, yet 💸</h2>
            <div className="font-size-xl">Let's get started with Web3 Payments</div>
          </div>
        </div>

        <Onboarding/>
          
      </div>
    )
  }

  return(

    <div className="screen payments-screen">

      <div className="row">

        <div className="col-lg-6 pb-4">

          <div className="pb-2">
        
            <div className="row">
              <div className="col-6">
                <Dropdown
                  toggle={
                    <button className="btn btn-action-hover" type="button">
                      <i className="fa-solid fa-file-export me-2"></i>
                      Export
                    </button>
                  }
                  children={[
                    ['Export xlsx File', exportFile, null]
                  ]}
                />
              </div>
            </div>

            { preparingExport &&
              <div className="pb-2 pt-1">
                <div className="card d-block px-3 pb-3">
                  <div className="row d-flex align-items-center">
                    <div className="col-2 col-lg-1 text-center">
                      { exportError == undefined && !exportReady && <LoadingSpinner className='small'/> }
                      { exportError == undefined && exportReady && <i className="fa-solid fa-file font-size-xl"></i> }
                      { exportError != undefined && exportReady && <i className="fa-solid fa-circle-exclamation font-size-xl"></i> }
                    </div>
                    <div className="col-6 line-height-extra-small ps-lg-2">
                      <div>
                        <span className="font-weight-medium">
                          { exportError == undefined && !exportReady && "Preparing export..." }
                          { exportError == undefined && exportReady && "Export ready" }
                          { exportError != undefined && "Export failed" }
                        </span>
                      </div>
                      <div className="opacity-50">
                        <small>
                          { exportError == undefined && !exportReady && `Please wait ${exportCurrentCount}/${totalPayments}` }
                          { exportError == undefined && exportReady && "Download now" }
                          { exportError != undefined && exportError }
                        </small>
                      </div>
                    </div>
                    <div className="col-4 col-lg-5 line-height-extra-small text-end pe-1">
                      { exportReady && exportError == undefined &&
                        <button className="btn btn-action" onClick={()=>exportNow()}>
                          Download
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>

          <PaymentList
            payments={ payments }
            link={ (id)=>(`/payments/${id}`) }
            loadMore={ loadMore }
            hasMore={ hasMore }
          />
          
        </div>
      </div>
    </div>
  )
}
