import CopyButton from "../../components/CopyButton"
import React, { useState, useEffect } from "react"
import { GET, POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useSelector } from 'react-redux'

export default function(props) {

  const [ retrieving, setRetrieving ] = useState(false)
  const [ creating, setCreating ] = useState(false)
  const [ value, setValue ] = useState()
  const account = useSelector(state => state.account)
  const apiSecrets = useSelector(state => state.api_secrets)
  const activeSubscription = useSelector(state => state.subscription)

  const issueApiSecret = ()=>{
    setCreating(true)
    POST(`/api/accounts/${account.id}/api_secrets`).then(({secret})=>{
      GET(`/api/accounts/${account.id}/api_secrets`).then((api_secrets)=>{
        setValue(secret.replaceAll(/\n/g,"\\n"))
        Store.dispatch(({ type: 'api_secrets/set', api_secrets }))
      })
    })
  }

  const retrieveApiSecret = ()=>{
    setRetrieving(true)
    GET(`/api/accounts/${account.id}/api_secrets/${apiSecrets[0].id}`).then((api_secret)=>{
      setValue(api_secret.secret.replaceAll(/\n/g,"\\n"))
    })
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/api_secrets`).then((api_secrets)=>{
      Store.dispatch(({ type: 'api_secrets/set', api_secrets }))
    })
  }, [account])

  if(creating) {
    if(value) {
      return(
        <div className="d-block">
          <CopyButton value={ value } />
        </div>
      )
    } else {
      return(
        <div className="d-block">
          <button className='btn btn-action btn-sm loading' disabled>
            <i className="fa-solid fa-fingerprint me-2"></i>
            Issuing Api Public Key
            <span>
              <span className='dot'>.</span>
              <span className='dot'>.</span>
              <span className='dot'>.</span>
            </span>
          </button>
        </div>
      )
    }
  } else {
    if(apiSecrets.length) {
      if(retrieving) {
        if(value) {
          return(
            <div className="d-block" style={{ maxWidth: '420px' }}>
              <CopyButton value={ value } />
            </div>
          )   
        } else {
          return(
            <div className="d-block">
              <button className='btn btn-action btn-sm loading' disabled>
                <i className="fa-solid fa-fingerprint me-2"></i>
                Retrieving Api Public Key
                <span>
                  <span className='dot'>.</span>
                  <span className='dot'>.</span>
                  <span className='dot'>.</span>
                </span>
              </button>
            </div>
          )
        }
      } else {
        return(
          <div className="d-block w-100">
            <button onClick={ retrieveApiSecret } className="btn btn-action btn-sm me-3">
              <i className="fa-solid fa-fingerprint me-2"></i>
              Show Api Public Key
            </button>
            <small><a href="https://depay.com/docs/apis/payments/tracking#validate-request-signatures" className="link-light" target="_blank">
              Validate API callbacks
              <i className="fas ms-2 fa-external-link-alt"></i>
            </a></small>
          </div>
        )
      }
    } else {
      return(
        <div className="d-flex align-items-center">
          <button onClick={ issueApiSecret } className="btn btn-action btn-sm me-3" title="Issue API Public Key">
            <i className="fa-solid fa-fingerprint me-2"></i>
            Issue Api Public Key
          </button>
          <small><a href="https://depay.com/docs/apis/payments/tracking#validate-request-signatures" className="link-light" target="_blank">
            Validate API callbacks
            <i className="fas ms-2 fa-external-link-alt"></i>
          </a></small>
        </div>
      )
    }
  }
}
