import copy from 'copy-to-clipboard'
import React, { useState } from "react"
import SyntaxHighlighter from 'react-syntax-highlighter'
import { stackoverflowLight } from 'react-syntax-highlighter/dist/esm/styles/hljs'

export default function(props) {

  const installationCommands = [
    { name: 'JavaScript yarn', language: 'plain', command: 'yarn add @depay/widgets' },
    { name: 'JavaScript npm', language: 'plain', command: 'npm install @depay/widgets --save' },
    { name: 'CDN HTML', language: 'html', command: '<script defer async src="https://integrate.depay.com/widgets/v12.js"></script>' },
  ]
  const [ installationCommand, setInstallationCommand ] = useState(installationCommands[0])
  
  return(
    <div className="rounded bg-white d-block shadow-sm position-relative">
      <div className="row d-flex justify-content-between align-items-center">
        <div className="col-6 text-start p-2">
          <select className="form-select rounded-2 form-select-sm opacity-50" value={installationCommand.name} onChange={(event)=>{ setInstallationCommand(installationCommands.find((command)=>command.name==event.target.value)) }}>
            { installationCommands.map((option)=>{
              return <option key={option.name} value={ option.name }>{option.name}</option>
            }) }
          </select>
        </div>
        <div className="col-6 text-end p-2">
          <button onClick={ ()=>{ copy(installationCommand.command) } } className='btn btn-sm btn-action' title="Click to copy">
            Copy
            <i className="far fa-copy ms-2"></i>
          </button>
        </div>
      </div>
      <SyntaxHighlighter className="bg-transparent" customStyle={{ paddingTop: '1.3rem', paddingBottom: '1.6rem', paddingLeft: '1.6rem', paddingRight: '1.6rem' }} language="javascript" style={ stackoverflowLight }>
        { installationCommand.command }
      </SyntaxHighlighter>
    </div>
  )
}
