import Dropdown from '../../components/Dropdown'
import getInitials from "../../helpers/getInitials"
import React, { useEffect, useState } from "react"
import AccountSettingsSidebar from '../../components/AccountSettingsSidebar'
import { GET, POST, DELETE } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useNavigate, NavLink } from "react-router-dom"
import { useSelector } from "react-redux"

export default function(props) {

  const account = useSelector(state => state.account)
  const accountUsers = useSelector(state => state.account_users)
  const users = useSelector(state => state.users)
  const navigate = useNavigate()

  const deleteUser = (accountUser)=>{
    const user = users?.byId ? users.byId[accountUser.user_id] : undefined
    const userName = user?.name || accountUser.name
    let confirmed = confirm(`Confirm to delete ${userName || "selected user"}'s access to this account.`);
    if(confirmed) {
      Store.dispatch({ type: 'accountUsers/delete', id: accountUser.id })
      DELETE(`/api/accounts/${account.id}/account_users/${accountUser.id}`)
    }
  }

  useEffect(()=>{
    if(account) {
      GET(`/api/accounts/${account.id}/account_users`).then((accountUsers)=>{
        Store.dispatch({ type: 'accountUsers/set', accountUsers })
      })
      GET(`/api/accounts/${account.id}/users`).then((users)=>{
        Store.dispatch({ type: 'users/set', users })
      })
    }
  }, [account])

  return(

    <div className="screen teams-screen row">

      <div className="col-12 col-md-3 sticky-reset">
        <AccountSettingsSidebar/>
      </div>

      <div className="col-12 col-md-7 col-xl-6">

        <div className="d-block">
          <NavLink to="/settings/team/invite" className="btn btn-action btn-sm" title="Invite more team members">
            <i className="fa-solid fa-plus me-2"></i>
            Invite Member
          </NavLink>
        </div>

        <div className="pt-2">
          <div className="card d-block p-0">
            { accountUsers.map((accountUser, index)=>{
              const user = users?.byId ? users.byId[accountUser.user_id] : undefined
              const userName = user?.name || accountUser.name
              if(!user) { return }
              return(
                <div key={index} className="pt-1">
                  <div className={`${accountUser.user_id != account.owner_id ? 'circular-card-interactive' : '' } p-2 py-3 p-lg-3`}>
                    <div className="d-flex align-items-center">
                      <div className="ps-2">
                        { user.image &&
                          <div className="circular-icon me-1" style={{ left: '2px', backgroundColor: 'rgba(0,0,0,0.3)', backgroundImage: `url(https://uploads.depay.com/${user.image})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}/>
                        }

                        { !user.image &&
                          <div className="circular-icon me-1">
                            { userName && <span className="text-white">{ getInitials(userName) }</span> }
                            { !userName && <i className="fa-regular fa-user text-white"></i> }
                          </div>
                        }
                      </div>
                      <div className="flex-grow-1 line-height-extra-small px-3">
                        <div>
                          <span className="font-weight-medium">{ userName || 'User' }</span>
                        </div>
                        <div>
                          <small className="font-weight-medium opacity-50">Role: { account.owner_id == accountUser.user_id ? 'owner, admin' : accountUser.role }</small>
                        </div>
                      </div>
                      <div className="text-end">
                        { accountUser.user_id != account.owner_id &&
                          <Dropdown
                            toggle={
                              <button className="btn btn-action-hover" type="button">
                                <i className="fas fa-ellipsis-h text-black-50"></i>
                              </button>
                            }
                            children={[
                              ['Delete', ()=>{ deleteUser(accountUser) }, <i className="far fa-trash-alt me-2"></i>],
                            ]}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            }) }
            {
              accountUsers.length === 1 &&
              <div className="d-block w-100 text-center px-5 pb-4">
                <div className="">
                  <div className="pb-1 pt-1 text-center text-sm-start">
                    <div className="mascot mascot-team d-inline-block d-sm-block"></div>
                  </div>
                  <div className="font-size-l text-center text-sm-start text-lg-center">Invite the team</div>
                </div>
              </div>
            }
          </div>

          <div className="px-2 pt-1">
            <small className="text-muted ps-1">
              <i className="fas fa-info-circle me-2"></i>
              Each user added will have full access to your account.
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}
