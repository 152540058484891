export default function StatisticsReducer(state = { link: {} }, action) {
  let newState
  switch (action.type) {
    case 'statistics/set':
      newState = {
        ... state,
        [action.belongsTo]: {
          ... (state[action.belongsTo] || {}),
          [action.id]: {
            ... (state?.[action.belongsTo]?.[action.id] || {}),
            [action.name]: {
              ... (state?.[action.belongsTo]?.[action.id]?.[action.name] || {}),
              [action.range]: action.value
            }
          }
        }
      }
      return newState
    default:
      return state
  }
}
