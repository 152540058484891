import React, { useEffect, useState } from "react"
import Tooltip from './Tooltip'
import { Currency } from '@depay/local-currency'
import { GET } from '../helpers/Queries'

export default function(props) {

  const [title, setTitle] = useState('VAT is determined by your location')
  const [applies, setApplies] = useState(true)
  const [locationCountry, setLocationCountry] = useState()

  useEffect(()=>{
    GET(`/api/request/country`).then((data)=>{
      setLocationCountry(data?.country)
    })
  }, [])

  useEffect(()=>{
    if(props.link.requires_shipping_address) {
      setTitle('VAT is determined by your shipping address')
      setApplies(props.selectedShippingAddressCountry == props.vatCountry)
    } else if (props.link.requires_billing_address) {
      setTitle('VAT is determined by your billing address')
      setApplies(props.selectedBillingAddressCountry == props.vatCountry)
    } else if(props.link.requires_country) {
      setTitle('VAT is determined by your country')
      setApplies(props.selectedCountry == props.vatCountry)
    } else {
      setTitle('VAT is determined by your location')
      setApplies(locationCountry == props.vatCountry)
    }
  }, [locationCountry, props.link, props.selectedCountry, props.selectedBillingAddressCountry, props.selectedShippingAddressCountry])

  return(
    <>
      <div className="flex-grow-1">
        <span>VAT</span>
        <Tooltip title={title} placement="right">
          <i className="ms-1 font-size-s fas fa-solid fa-circle-info opacity-50"></i>
        </Tooltip>
      </div>
      <div>{ applies ? props.displayedVat : (new Currency({ amount: 0, code: props.displayCurrency })).toString() }</div>
    </>
  )
}
