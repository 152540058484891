import copy from 'copy-to-clipboard'
import Dropdown from '../components/Dropdown'
import moment from 'moment'
import React, { useState, useEffect } from "react"
import { GET, POST, DELETE } from '../helpers/Queries'
import { NavLink, useNavigate } from "react-router-dom"
import { Store } from '../Store'
import { useSelector, useDispatch } from 'react-redux'

export default function(props) {

  const dispatch = useDispatch()
  const account = useSelector(state => state.account)
  const requests = useSelector(state => state.requests)
  const navigate = useNavigate()
  const [ creating, setCreating ] = useState(false)

  const createNewRequest = ()=>{
    if(creating != false) { return }
    setCreating(true)
    POST(`/api/accounts/${account.id}/requests`).then((request)=>{
      dispatch(({ type: 'requests/add', request: {...request} }))
      navigate(`/requests/${request.id}/edit`)
    }).catch(()=>{ setCreating(false) })
  }

  const deleteRequest = (request)=>{
    let confirmation = confirm(`Are you sure you want to delete this request?`)
    if(confirmation) {
      Store.dispatch({ type: 'requests/delete', id: request.id })
      DELETE(`/api/accounts/${account.id}/requests/${request.id}`)
    }
  }

  const renderRequest = (id, type)=>{
    const request = requests.byId[id]
    if(!request) { return null }
    return(
      <NavLink key={id} to={`/requests/${id}/edit`} className="circular-card-interactive p-2 py-3 p-lg-3">
        <div className="row d-flex align-items-center">
          <div className="col-10 line-height-extra-small px-3">
            { (request.payer_name || request.amount) &&
              <div className="font-weight-medium">
                { request.amount && <span>{ request.currency } { request.amount } </span> }
                { request.payer_name && <span>from { request.payer_name }</span> }
              </div>
            }
            <div className="opacity-50">
              <small>
                { type === 'incomplete' &&
                  <span className="font-weight-medium">created { moment(request.created_at).fromNow() }</span>
                }
                { type === 'overdue' &&
                  <span className="font-weight-medium">{ moment(request.due_date).fromNow(true) } overdue</span>
                }
                { type === 'today' &&
                  <span className="font-weight-medium">due today</span>
                }
                { !['overdue', 'incomplete', 'today'].includes(type) &&
                  <span className="font-weight-medium">due in { moment(request.due_date).diff(moment().startOf('day'), 'days') } days</span>
                }
                { request.last_delivery_at &&
                  <span className="ps-2" title={`last delivery at ${moment(request.last_delivery_at).format('LLL')}`}>
                    •
                    <i className="fa-solid fa-envelope me-2 ms-2"></i>
                    { moment(request.last_delivery_at).fromNow() }
                  </span>
                }
              </small>
            </div>
          </div>
          <div className="col-2 text-end">
            <Dropdown
              toggle={
                <button className="btn btn-action-hover rounded-3" type="button">
                  <i className="fas fa-ellipsis-h text-black-50"></i>
                </button>
              }
              children={[
                ['Copy Link', ()=>{ copy(`https://request.depay.com/${requests.byId[id].id}`) }, <i className="far fa-copy me-2"></i>],
                ['Edit', ()=>{ navigate(`/requests/${id}/edit`) }, <i className="fa-solid fa-pen-to-square me-2"></i>],
                ['Delete', ()=>{ deleteRequest(request) }, <i className="far fa-trash-alt me-2"></i>],
              ]}
            />
          </div>
        </div>
      </NavLink>
    )
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/requests`).then((requests)=>{
      dispatch({ type: 'requests/set', requests })
    })
  }, [])

  return(
    <div className="screen requests-screen">

      <div className="row">
        <div className="col-12 col-xl-6">
          
          <div>

            <div className="d-block pb-2">
              <button onClick={createNewRequest} className="btn btn-action btn-sm" title="Create a new request">
                <i className="fa-solid fa-plus me-2"></i>
                New Request
              </button>
            </div>

            { (!requests || Object.keys(requests.byId)?.length === 0) &&
              <div className="card d-block p-0">
                <div className="d-block w-100 text-center px-5 pb-4">
                  <div className="">
                    <div className="pb-2 pt-1 text-center text-sm-start">
                      <div className="mascot mascot-request d-inline-block d-sm-block"></div>
                    </div>
                    <div className="font-size-l text-center text-sm-start text-lg-center">Create your first request</div>
                    <div className="font-size-m pt-1 text-center text-sm-start text-lg-center opacity-50">and recieve a single payment</div>
                  </div>
                </div>
              </div>
            }

            {
              requests?.incomplete?.length > 0 &&
              <div className="pt-2">
                <h2 className="h5 px-2 px-lg-0 text-black opacity-50 mb-2 font-size-s">
                  Incomplete
                </h2>
                <div className="rounded p-1 mb-2 bg-white">
                  { requests.incomplete.map((id)=>renderRequest(id, 'incomplete'))}
                </div>
              </div>
            }

            {
              requests?.byGroupId?.overdue?.length > 0 &&
              <div className="pt-2">
                <h2 className="h5 px-2 px-lg-0 text-danger opacity-85 mb-2 font-size-s">
                  Overdue
                </h2>
                <div className="rounded p-1 mb-2 bg-white border border-danger">
                  { requests.byGroupId.overdue.map((id)=>renderRequest(id, 'overdue'))}
                </div>
              </div>
            }

            {
              requests?.byGroupId?.today?.length > 0 &&
              <div className="pt-2">
                <h2 className="h5 px-2 px-lg-0 text-black opacity-50 mb-2 font-size-s">
                  Today
                </h2>
                <div className="rounded p-1 mb-2 bg-white">
                  { requests.byGroupId.today.map((id)=>renderRequest(id, 'today'))}
                </div>
              </div>
            }

            {
              requests?.byGroupId?.thisWeek?.length > 0 &&
              <div className="pt-2">
                <h2 className="h5 px-2 px-lg-0 text-black opacity-50 mb-2 font-size-s">
                  This Week
                </h2>
                <div className="rounded p-1 mb-2 bg-white">
                  { requests.byGroupId.thisWeek.map(renderRequest)}
                </div>
              </div>
            }

            {
              requests?.byGroupId?.nextWeek?.length > 0 &&
              <div className="pt-2">
                <h2 className="h5 px-2 px-lg-0 text-black opacity-50 mb-2 font-size-s">
                  Next Week
                </h2>
                <div className="rounded p-1 mb-2 bg-white">
                  { requests.byGroupId.nextWeek.map(renderRequest)}
                </div>
              </div>
            }

            {
              requests?.byGroupId?.thisMonth?.length > 0 &&
              <div className="pt-2">
                <h2 className="h5 px-2 px-lg-0 text-black opacity-50 mb-2 font-size-s">
                  This Month
                </h2>
                <div className="rounded p-1 mb-2 bg-white">
                  { requests.byGroupId.thisMonth.map(renderRequest)}
                </div>
              </div>
            }

            {
              requests?.byGroupId?.nextMonth?.length > 0 &&
              <div className="pt-2">
                <h2 className="h5 px-2 px-lg-0 text-black opacity-50 mb-2 font-size-s">
                  Next Month
                </h2>
                <div className="rounded p-1 mb-2 bg-white">
                  { requests.byGroupId.nextMonth.map(renderRequest)}
                </div>
              </div>
            }

            {
              requests?.byGroupId?.thisYear?.length > 0 &&
              <div className="pt-2">
                <h2 className="h5 px-2 px-lg-0 text-black opacity-50 mb-2 font-size-s">
                  This Year
                </h2>
                <div className="rounded p-1 mb-2 bg-white">
                  { requests.byGroupId.thisYear.map(renderRequest)}
                </div>
              </div>
            }

            {
              requests?.byGroupId?.nextYear?.length > 0 &&
              <div className="pt-2">
                <h2 className="h5 px-2 px-lg-0 text-black opacity-50 mb-2 font-size-s">
                  Next Year
                </h2>
                <div className="rounded p-1 mb-2 bg-white">
                  { requests.byGroupId.nextYear.map(renderRequest)}
                </div>
              </div>
            }

            {
              requests?.byGroupId?.later?.length > 0 &&
              <div className="pt-2">
                <h2 className="h5 px-2 px-lg-0 text-black opacity-50 mb-2 font-size-s">
                  Far Future
                </h2>
                <div className="rounded p-1 mb-2 bg-white">
                  { requests.byGroupId.later.map(renderRequest)}
                </div>
              </div>
            }

          </div>
        </div>
      </div>
    </div>
  )
}
