import AddressEllipsis from "../helpers/AddressEllipsis"
import moment from 'moment'
import Blockchains from '@depay/web3-blockchains'

export default class Payment {

  constructor(data, accountWallets){
    this.data = data
    this.accountWallets = accountWallets
    this.amountUsd = this.data.receiver_amount_usd
    this.title =`From ${AddressEllipsis(this.data.sender_id)}`
    this.address = this.data.sender_id
    this.usdAmountFormatted = `$${(parseFloat(Math.abs(this.amountUsd)).toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 }))}`
    this.amountToken = this.data.receiver_amount
    this.tokenId = this.data.receiver_token_id
    this.blockchain = Blockchains.findByName(this.data.blockchain)
    this.explorerUrl = this.blockchain.explorerUrlFor({ transaction: { id: this.data.transaction_id } })
  }

  time(type) {
    if(type == 'ago') {
      return moment(this.data.confirmed_at).fromNow()
    } else {
      return moment(this.data.confirmed_at).format('LT')
    }
  }

}
