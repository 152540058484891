require('exceljs/dist/exceljs.js')

const formatHeader = (value)=>{
  return value.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())
}

export default async function(data, format, addUpgradeNote){

  const workbook = new window.ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Export')
  const maxWidths = {}

  const addRow = (row)=>{
    row.forEach((value, index)=>{
      if(value == undefined) { return }
      if(maxWidths[index] == undefined || maxWidths[index] < value.length){
        maxWidths[index] = value.length
      }
    })
    worksheet.addRow(row)
  }

  addRow((Object.keys(format).map((key)=>formatHeader(key))))
  worksheet.getRow(1).font = { bold: true }

  data.forEach((datum)=>{
    addRow((Object.keys(format).map((key)=>{
      if(format[key] instanceof Array) {
        if(datum[format[key][0]]) {
          return format[key][1](datum[format[key][0]][key], datum)
        }
      } else {
        return format[key](datum[key], datum)
      }

    })))
  })

  Object.keys(maxWidths).forEach((index)=>{
    worksheet.getColumn(parseInt(index,10)+1).width = maxWidths[index] * 1.1
  })

  if(addUpgradeNote) {
    addRow(['This export only contains a limited amount of rows. Please upgrade to DePay PRO to receive the full export: https://app.depay.com/#pro'])
  }
  
  let buffer = await workbook.xlsx.writeBuffer()
  var xlsblob = new Blob([buffer], {type:"application/octet-stream"})

  let url = window.URL.createObjectURL(xlsblob)
  let anchor = document.createElement("a")
  anchor.href = url
  anchor.download = "export.xlsx"
  anchor.click()
  
  window.URL.revokeObjectURL(url)
}
