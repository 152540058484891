import Dropdown from '../components/Dropdown'
import React, { useState, useEffect } from "react"
import { GET, DELETE } from '../helpers/Queries'
import { NavLink, useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'
import { Store } from '../Store'

export default function(props) {

  const account = useSelector(state => state.account)
  const apps = useSelector(state => state.apps)
  const [ logos, setLogos ] = useState({})
  const navigate = useNavigate()

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/apps`).then((apps)=>{
      Store.dispatch({ type: 'apps/set', apps })
    })
  }, [])

  const deleteApp = (app)=> {
    let confirmation = confirm(`Are you sure you want to delete ${app.name || 'this app'}?`)
    if(confirmation) {
      Store.dispatch({ type: 'apps/delete', id: app.id })
      DELETE(`/api/accounts/${account.id}/apps/${app.id}`)
    }
  }

  useEffect(()=>{
    if(!apps){ return }
    let logos = {}
    Promise.all(apps.map((app)=>{
      return new Promise((resolve)=>{
        GET(`/api/accounts/${account.id}/uploads/preview?key=${app.logo}`).then((presignedUrl)=>{
          logos[app.id] = presignedUrl
          resolve()
        })
      })
    })).then(()=>{
      setLogos(logos)
    })
  }, [apps])

  let appsListElement
  if(apps && apps.length) {
    appsListElement = apps.map((app, index)=>{
      return(
        <div key={app.id}>
          <NavLink to={`/dev/apps/${app.id}`} className="circular-card-interactive p-2 py-3 p-lg-3">
            <div className="row d-flex align-items-center">
              <div className="col-10 line-height-extra-small px-3">
                <div>
                  <span className="font-weight-medium">{ app.name }</span>
                </div>
                <div className="opacity-50 pt-1">
                  <small className="font-weight-medium">{ app.link }</small>
                </div>
              </div>
              <div className="col-2 text-end">
                <Dropdown
                  toggle={
                    <button className="btn btn-action-hover rounded-3" type="button">
                      <i className="fas fa-ellipsis-h text-black-50"></i>
                    </button>
                  }
                  children={[
                    ['Delete', ()=>{ deleteApp(app) }, <i className="far fa-trash-alt me-2"></i>]
                  ]}
                />
              </div>
            </div>
          </NavLink>
        </div>
      )
    })
  } else {
    appsListElement = (
      <div className="d-block w-100 text-center px-5 pb-4 pt-2">
        <div className="">
          <div className="pt-1 text-center text-sm-start">
            <div className="mascot mascot-app d-inline-block d-sm-block"></div>
          </div>
          <div className="font-size-l text-center text-sm-start text-lg-center">Create your first app</div>
          <div className="font-size-m pt-1 text-center text-sm-start text-lg-center opacity-50">and access users' DePay account</div>
        </div>
      </div>
    )
  }

  return(

    <div className="screen apps-screen">

      <div className="row">
        <div className="col-12 col-xl-6">
          
          <div className="pt-2">
            <div className="d-block pb-2">
              <NavLink to="/dev/apps/new" className="btn btn-action btn-sm" title="Create a new app">
                <i className="fa-solid fa-plus me-2"></i>
                New App
              </NavLink>
            </div>
            <div className="card d-block px-0 py-1">
              { appsListElement }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
