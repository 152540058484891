import groupRequests from '../helpers/groupRequests'

const addRequests = (state, requests)=>{
  let newState = groupRequests(requests, state)
  return newState
}

const emptyState = ()=>({ byId: {}, byGroupId: {} })

export default function RequestsReducer(state = emptyState(), action) {
  let newState

  switch (action.type) {
    case 'requests/set':
      return addRequests(emptyState(), action.requests)
    case 'requests/add':
      return addRequests(state, [action.request])
    case 'requests/update':
      newState = JSON.parse(JSON.stringify(state))
      newState.byId[action.request.id] = action.request
      return newState
    case 'requests/delete':
      newState = JSON.parse(JSON.stringify(state))
      delete newState.byId[action.id]
      newState.incomplete = newState.incomplete?.filter((id)=> id !== action.id)
      if(newState.byGroupId) {
        Object.keys(newState.byGroupId).forEach((key)=>{
          newState.byGroupId[key] = newState.byGroupId[key].filter((id)=> id !== action.id)
        })
      }
      return newState
    default:
      return state
  }
}
