import InteractiveRangeChart from '../components/InteractiveRangeChart'
import Blockchains from "@depay/web3-blockchains"
import moment from 'moment'
import OnboardingScreen from "./overview/Onboarding"
import Payment from "../models/Payment"
import React, { useState, useEffect } from "react"
import Token from "../models/Token"
import { Currency } from '@depay/local-currency'
import { GET } from '../helpers/Queries'
import { NavLink } from "react-router-dom"
import { Store } from '../Store'
import { TokenImage } from '@depay/react-token-image'
import { useSelector } from 'react-redux'

export default function(props) {

  const [ statisticsLoaded, setStatisticsLoaded ] = useState(false)
  const [ paymentsLoaded, setPaymentsLoaded ] = useState(false)
  const [ latestPayments, setLatestPayments ] = useState()
  const account = useSelector(state => state.account)
  const user = useSelector(state => state.user)
  const tokens = useSelector(state => state.tokens)

  useEffect(()=>{
    Currency.fromUSD({ amount: 1, code: user.currency }) // preload local currency quote for sync display rendering
  }, [])

  useEffect(()=>{
    if(account){
      const now = moment()
      const range = `${now.year()}-${now.format("MM")}`
      Promise.all([
        GET(`/api/accounts/${account.id}/statistics/account/${account.id}/payments/total`),
        GET(`/api/accounts/${account.id}/statistics/account/${account.id}/volume/total`),
        GET(`/api/accounts/${account.id}/statistics/account/${account.id}/payments/${range}`),
        GET(`/api/accounts/${account.id}/statistics/account/${account.id}/volume/${range}`),
      ]).then(([totalPaymentsStatistic, totalVolumeStatistic, monthlyPaymentsStatistic, monthlyVolumeStatistic])=>{
        Store.dispatch({ type: 'statistics/set', belongsTo: 'account', id: account.id, name: 'payments', range: 'total', value: totalPaymentsStatistic })
        Store.dispatch({ type: 'statistics/set', belongsTo: 'account', id: account.id, name: 'volume', range: 'total', value: totalVolumeStatistic })
        Store.dispatch({ type: 'statistics/set', belongsTo: 'account', id: account.id, name: 'payments', range: range, value: monthlyPaymentsStatistic })
        Store.dispatch({ type: 'statistics/set', belongsTo: 'account', id: account.id, name: 'volume', range: range, value: monthlyVolumeStatistic })
        setStatisticsLoaded(true)
      })

      GET(`/api/accounts/${account.id}/payments`).then((responseData)=>{
        if(responseData?.items) {
          setLatestPayments(responseData.items.slice(0,3))
        }
        setTimeout(()=>setPaymentsLoaded(true), 1000)
      })
    }
  }, [account])

  if(!(statisticsLoaded && paymentsLoaded)) {
    return(
      <div className="screen overview-screen">
        
        <div className="row">
          <div className="col-12">
            <div className="row rounded-2 p-3 skeleton" style={{ minHeight: '295px' }}/>
          </div>
        </div>

        <div className="pt-4">
          <div className="h5 px-2 px-lg-0 text-black opacity-50 mb-0 font-size-s pt-2">
            Latest Payments
          </div>
          <div className="col-12 col-lg-8 col-xl-6 pt-1">
            <div className="row rounded-2 skeleton" style={{ minHeight: '220px' }}/>
          </div>
        </div>
      </div>
    )
  }

  if( latestPayments.length <= 0 ){
    return(<OnboardingScreen/>)
  }

  return(
    <div className="screen overview-screen">

      <div className="row">
        <div className="col-12">
          <div className="row bg-white shadow rounded-2 p-3" style={{ minHeight: '296px' }}>
            <div className="col-12 col-md-6 p-2 p-md-4">
              <InteractiveRangeChart belongsTo={ 'account' } belongsToId={ account.id } label={ 'Total Volume' } name={ 'volume' } format={ 'currency' }/>
            </div>
            <div className="col-12 col-md-6 p-2 p-md-4">
              <InteractiveRangeChart belongsTo={ 'account' } belongsToId={ account.id } label={ 'Total Payments' } name={ 'payments' }/>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4">
        <div className="h5 px-2 px-lg-0 text-black opacity-50 mb-0 font-size-s pt-2">
          Latest Payments
        </div>
        <div className="col-12 col-lg-8 col-xl-6 pt-1">
          <div className="bg-white rounded-2">
            {
              latestPayments.map((rawPayment, index)=>{
                const payment = new Payment(rawPayment)
                const token = tokens.byId[`${payment.data.blockchain}-${payment.tokenId}`] ? new Token(tokens.byId[`${payment.data.blockchain}-${payment.tokenId}`]) : undefined
                const blockchain = Blockchains[payment.data.blockchain]

                return(
                  <NavLink to={`/payments/${rawPayment.id}`} key={rawPayment.id} className="circular-card-interactive p-2 py-3 p-lg-3">
                    <div className="row d-flex align-items-center">
                      <div className="col-2 col-lg-1 text-center">
                        <div className='position-relative d-inline-block'>
                          <TokenImage
                            className="me-1 token-image-normal"
                            blockchain={payment.data.blockchain}
                            address={payment.tokenId}
                          />
                          <img className="blockchain-logo-small" style={{ backgroundColor: blockchain.logoBackgroundColor }} src={ blockchain.logo } title={`on ${ blockchain.label }`}/>
                        </div>
                      </div>
                      <div className="col-6 line-height-extra-small ps-lg-3">
                        <div>
                          <span className="font-weight-medium">{ payment.title }</span>
                        </div>
                        <div className="opacity-50">
                          <small>{ payment.time('ago') }</small>
                        </div>
                      </div>
                      <div className="col-4 col-lg-5 line-height-extra-small text-end pe-1">
                        <div>
                          <span className="font-weight-medium">{ Currency.fromUSDSync({ amount: payment.amountUsd || 0, code: user.currency }).toString() }</span>
                        </div>
                        <div className="opacity-50 text-truncate">
                          { token &&
                            <small className="text-nowrap">{ payment.amountPrefix } { token.data.symbol } { payment.amountToken }</small>
                          }
                        </div>
                      </div>
                    </div>
                  </NavLink>
                )
              })
            }
          </div>
          <div className="pt-3 pb-4">
            <NavLink to="/payments" className="btn btn-action">
              <i className="fa-solid fa-list me-2"></i>
              Show all payments
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}
