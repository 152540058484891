import { Store } from '../Store'
import { POST } from './Queries'

export default (account)=>{

  const user = Store.getState().user

  return POST(`/api/user/account/${account.id}`).then(()=>{
    Store.dispatch(({ type: 'LOGOUT' }))
    Store.dispatch(({ type: 'user/set', user }))
    Store.dispatch({ type: 'account/select', account })
  })
}
