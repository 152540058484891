export default function LinksReducer(state = [], action) {
  let newState
  switch (action.type) {
    case 'links/add':
      newState = (state).slice()
      newState.push(action.link)
      return newState
    case 'links/set':
      return action.links
    case 'links/update':
      newState = (state).slice()
      let updatedLinkIndex = newState.findIndex((i)=>action.link.id==i.id)
      if(updatedLinkIndex > -1){
        newState[updatedLinkIndex] = action.link
      }
      return newState
    case 'links/delete':
      newState = state.filter((link)=>{
        return link.id != action.id
      })
      return newState
    default:
      return state
  }
}
