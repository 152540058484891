import copy from 'copy-to-clipboard'
import Dropdown from '../../components/Dropdown'
import groupPayments from '../../helpers/groupPayments'
import InteractiveRangeChart from '../../components/InteractiveRangeChart'
import loadAdditionalTokenDataForPayments from "../../helpers/loadAdditionalTokenDataForPayments"
import moment from 'moment'
import Payment from "../../models/Payment"
import PaymentList from '../../components/payments/List'
import React, { useState, useEffect, useRef } from "react"
import { GET } from '../../helpers/Queries'
import { Store } from '../../Store'
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const { id: selectedLinkId } = useParams()
  const payments = useSelector(state => state.payments_for_link ? state.payments_for_link[selectedLinkId] : null)
  const link = useSelector(state => state.links.find((link)=>link.id===selectedLinkId))
  const statistic = useSelector(state => state?.statistics?.link?.[link.id])
  const accountWallets = useSelector(state => state.account_wallets)
  const [ linkImageUrl, setLinkImageUrl ] = useState()
  const [ statisticsAvailable, setStatisticsAvailable ] = useState(false)
  const [ statisticsLoaded, setStatisticsLoaded ] = useState(false)
  const [ paymentsLoaded, setPaymentsLoaded ] = useState(false)
  const navigate = useNavigate()

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/links`).then((links)=>{
      Store.dispatch({ type: 'links/set', links })
    })
  }, [])

  useEffect(()=>{
    if(link.purchase_image) {
      GET(`/api/accounts/${account.id}/uploads/preview?key=${link.purchase_image}`).then((presigned_url)=>{
        setLinkImageUrl(presigned_url)
      })
    }

    const now = moment()
    const range = `${now.year()}-${now.format("MM")}`
    Promise.all([
      GET(`/api/accounts/${account.id}/statistics/link/${link.id}/payments/total`),
      GET(`/api/accounts/${account.id}/statistics/link/${link.id}/volume/total`),
      GET(`/api/accounts/${account.id}/statistics/link/${link.id}/payments/${range}`),
      GET(`/api/accounts/${account.id}/statistics/link/${link.id}/volume/${range}`),
    ]).then(([totalPaymentsStatistic, totalVolumeStatistic, monthlyPaymentsStatistic, monthlyVolumeStatistic])=>{
      Store.dispatch({ type: 'statistics/set', belongsTo: 'link', id: link.id, name: 'payments', range: 'total', value: totalPaymentsStatistic })
      Store.dispatch({ type: 'statistics/set', belongsTo: 'link', id: link.id, name: 'volume', range: 'total', value: totalVolumeStatistic })
      Store.dispatch({ type: 'statistics/set', belongsTo: 'link', id: link.id, name: 'payments', range: range, value: monthlyPaymentsStatistic })
      Store.dispatch({ type: 'statistics/set', belongsTo: 'link', id: link.id, name: 'volume', range: range, value: monthlyVolumeStatistic })
      setStatisticsAvailable(!!(totalPaymentsStatistic && totalVolumeStatistic))
      setStatisticsLoaded(true)
    })

    GET(`/api/accounts/${account.id}/payments?wallets=&integrations=&links=${link.id}`).then((responseData)=>{
      if(responseData?.items?.length > 0) {
        loadAdditionalTokenDataForPayments(responseData?.items)
        Store.dispatch(({ type: 'payments/link/set', id: link.id, payments: responseData?.items }))
      }
      setPaymentsLoaded(true)
    }).catch(()=>{ setPaymentsLoaded(true) })

  }, [link])

  if(!link || !statisticsLoaded || !paymentsLoaded) {
    return(
      <div className="screen link-overview-screen">
        
        <div>

          <div className="row">
            <div className="col-12">
              <div className="card skeleton d-inline-block me-3" style={{ width: '58px' }}>
                <div style={{ height: '3rem', width: '58px' }}/>
              </div>
            </div>
          </div>

          <div className="pt-4">

            <div className="row">

              <div className="col-12 col-md-6">
                <div className="pb-3">
                  <div className="card skeleton w-100 py-2">
                    <div style={{ height: '70px' }} className="w-100"/>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="card skeleton w-100 py-2">
                    <div style={{ height: '70px' }} className="w-100"/>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="card skeleton w-100 py-2">
                    <div style={{ height: '70px' }} className="w-100"/>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 ps-md-4">

                <div className="card p-4 row skeleton">
                  <div style={{ height: '36rem' }} className="w-100"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className="screen link-overview-screen">

      <div className="row">

        <div className="col-12 col-md-6 order-1 order-md-0">

          <div>

            <h2>
              <strong>{ link.purchase_name || 'Payment Link' }</strong>
            </h2>

            { payments &&
              <div className="pt-3 pb-2">
                <button onClick={ ()=>copy(`https://link.depay.com/${link.external_id}`) } className="btn btn-action-hover" type="button">
                  <i className="far fa-copy me-2"></i>
                  Copy Link
                </button>
                <button onClick={ ()=>navigate(`/links/${link.id}/edit`) } className="btn btn-action-hover" type="button">
                  <i className="fa-solid fa-pen-to-square me-2"></i>
                  Edit
                </button>
              </div>
            }

          </div>

          <div className="pt-1">

            { !payments &&
              <div>
                <h5 className="pb-2 pt-3">No payments, yet</h5>
                <div>
                  <p className="text-black-50 mb-1">
                    Share your payment link and effortlessly start collecting payments:
                  </p>
                </div>
                <div className="pt-2 pb-4">
                  <div className="col-12 col-md-6 pt-2"><button className="btn btn-action w-100 text-start" type="button" onClick={()=>{ copy(`https://link.depay.com/${link.external_id}`) }}><i className="far fa-copy me-2"></i>Copy Link</button></div>
                  <div className="col-12 col-md-6 pt-2"><a className="btn btn-action w-100 text-start" href={`/links/${link.id}/edit`}><i className="far fa-pen-to-square me-2"></i>Edit Link</a></div>
                </div>
              </div>
            }

            { payments &&

              <>
                <PaymentList
                  payments={ payments }
                  link={ (id)=>(`/payments/${id}`) }
                  loadMore={ ()=>{} }
                  hasMore={ false }
                />
                <div>
                  <NavLink to={`/payments?belongs_to=link:${selectedLinkId}`} className="btn btn-action">
                    <i className="fa-solid fa-list me-2"></i>
                    Show all payments
                  </NavLink>
                </div>
              </>
            }
          </div>
        </div>

        <div className="col-12 col-md-6 ps-md-3 order-0 order-md-1 sticky-wrapper sticky-reset">
          <div className="sticky-item px-0 px-md-3 pb-2 pb-md-4">
            <div className="sticky-reset-padding">
              <div className="card row mt-3 pt-2 pt-md-3 pb-2 pb-md-3">
                <div>
                  {
                    !statisticsAvailable &&
                    <div className="text-center pb-4 pt-3">
                      <div className="pt-1 text-center m-auto w-50">
                        <div className="mascot mascot-not-found d-inline-block"></div>
                      </div>
                      <div className="font-size-l text-center">No statistics found</div>
                    </div>
                  }

                  {
                    statisticsAvailable &&
                    <div className="row">
                      <div className="col-12 p-2 pb-md-3 p-md-3">
                        <InteractiveRangeChart belongsTo={ 'link' } belongsToId={ link.id } label={ 'Volume' } name={ 'volume' } format={ 'currency' }/>
                      </div>
                      <div className="col-12 p-2 pb-md-3 p-md-3">
                        <InteractiveRangeChart belongsTo={ 'link' } belongsToId={ link.id } label={ 'Payments' } name={ 'payments' }/>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
