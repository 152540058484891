import copy from 'copy-to-clipboard'
import React, { useState } from "react"
import TooltipDrawer from './TooltipDrawer'

export default function(props) {

  const [ showTooltip, setShowTooltip ] = useState()

  const onClickCopy = ()=>{
    setShowTooltip(true)
    copy(props.value)
  }

  return(
    <div className={`d-inline-block position-relative ${props.className}`}>
      <span onClick={ onClickCopy } title="Copy to clipboard">
        { props.children || props.value }
      </span>
      <TooltipDrawer show={showTooltip} set={setShowTooltip} text={"Copied"} icon={<i className="far fa-copy me-2"></i>}/>
    </div>
  )
}
