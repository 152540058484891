import AppSecret from "./Show/AppSecret"
import CopyButton from "../../components/CopyButton"
import React, { useState, useEffect } from "react"
import { GET, POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useDebounce } from 'use-debounce'
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

export default function(props) {

  const { id } = useParams()
  const account = useSelector(state => state.account)
  const apps = useSelector(state => state.apps)
  const app = apps.find((app)=>app.id==id)
  const [ logo, setLogo ] = useState()
  const [ authenticationUrl, setAuthenticationUrl ] = useState()
  const [ authorizationUrl, setAuthorizationUrl ] = useState()
  const [ userAuthentication, setUserAuthentication ] = useState(app && app.user_authentication)
  const navigate = useNavigate()

  const [ storeAuthentication ] = useDebounce(userAuthentication, 500)
  useEffect(()=>{
    if(storeAuthentication != undefined) {
      POST(`/api/accounts/${account.id}/apps/${id}/user_authentication`, userAuthentication)
      Store.dispatch(({ type: 'apps/update', app: {...app, user_authentication: userAuthentication }}))
    }
  }, [ storeAuthentication ])

  const enableUserAuthentication = ()=>{
    let userAuthentication = { permissions: [], redirect_url: undefined }
    Store.dispatch(({ type: 'apps/update', app: {...app, user_authentication: userAuthentication }}))
    setUserAuthentication(userAuthentication)
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/apps`).then((apps)=>{
      Store.dispatch({ type: 'apps/set', apps })
      GET(`/api/accounts/${account.id}/apps/${id}`).then((app)=>{
        setUserAuthentication(app.user_authentication)
        Store.dispatch(({ type: 'apps/update', app }))
      })
    })
  }, [])

  useEffect(()=>{
    if(!app || logo){ return }
    GET(`/api/accounts/${account.id}/uploads/preview?key=${app.logo}`).then((presignedUrl)=>{
      setLogo(presignedUrl)
    })
  }, [app])

  useEffect(()=>{
    if(userAuthentication && userAuthentication.redirect_url) {
      setAuthorizationUrl(`https://app.depay.com/authorize/${app.id}?code_challenge=`)
    } else {
      setAuthorizationUrl()
    }
  }, [userAuthentication])

  if(!app){ return }
  return(
    <div className="screen show-app-screen">

      <div className="row">
        
        <div className="col-12 col-xl-6">
          
          <div>

            <div className="pb-3 d-flex">
              { logo && <img style={{ height: '100px', width: '100px' }} className='shadow-sm bg-white rounded-circle' src={ logo }/> }
              { !logo && <span style={{ height: '100px', width: '100px' }} className='shadow-sm bg-white rounded-circle'/> }
              <div className="d-inline-block ps-4 pt-1">
                <div><h2><strong>{ app.name }</strong></h2></div>
                <div><p className="text-black-50 mb-0">{ app.description }</p></div>
                <div><a className="font-size-xs" target="_blank" rel="noopener noreferrer" href={ app.link }>{ app.link }</a></div>
              </div>
            </div>

            <div className="pt-3">
              <h4 className="h5 px-2 px-lg-0 text-black opacity-50 font-size-s">App Authentication</h4>
              <div className="card d-block px-2 pb-3">
                <div className="d-block pb-2 px-2">
                  <span className="form-text pb-2 d-block">App ID</span>
                  <div className="d-block">
                    <CopyButton value={ app.id } />
                  </div>
                </div>
                <div className="d-block pb-2 px-2">
                  <span className="form-text pb-2 d-block">App Secret</span>
                  <div className="d-block">
                    <AppSecret app={app}/>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-3">
              <h4 className="h5 px-2 px-lg-0 text-black opacity-50 font-size-s">User Authentication</h4>
              <div className="card d-block px-0 pb-1">
                <div className="d-block px-3 pb-2">
                  { app.user_authentication == undefined &&
                    <div className="d-block">
                      <button onClick={ enableUserAuthentication } className="btn btn-action btn-sm me-3">
                        <i className="fa-solid fa-toggle-on me-2"></i>
                        Enable
                      </button>
                    </div>
                  }
                  { app.user_authentication != undefined &&
                    <div className="px-1">
                      <div className="d-block pb-2">
                        <span className="form-text pb-2 d-block">Required Permissions</span>
                        <label className="d-block" role="button">
                          <input checked={ userAuthentication.permissions.includes('payments.read') } onChange={(event)=>{
                            if(userAuthentication.permissions.includes('payments.read')) {
                              setUserAuthentication({...userAuthentication, permissions: userAuthentication.permissions.filter((i)=>i!='payments.read') })
                            } else {
                              setUserAuthentication({...userAuthentication, permissions: userAuthentication.permissions.concat(['payments.read']) })
                            }
                          }} className="form-check-input" type="checkbox"/>
                          <span className="ps-2">Read Payments</span>
                        </label>
                        <label className="d-block" role="button">
                          <input checked={ userAuthentication.permissions.includes('integrations.read') } onChange={(event)=>{
                            if(userAuthentication.permissions.includes('integrations.read')) {
                              setUserAuthentication({...userAuthentication, permissions: userAuthentication.permissions.filter((i)=>i!='integrations.read') })
                            } else {
                              setUserAuthentication({...userAuthentication, permissions: userAuthentication.permissions.concat(['integrations.read']) })
                            }
                          }} className="form-check-input" type="checkbox"/>
                          <span className="ps-2">Read Integrations</span>
                        </label>
                      </div>

                      <label className="d-block pt-2 pb-2">
                        <span className="form-text pb-2 d-block">Redirect URL</span>
                        <div className="d-block">
                          <input value={ userAuthentication ? (userAuthentication.redirect_url || '') : '' } onChange={(event)=>{
                            setUserAuthentication({...userAuthentication, redirect_url: event.target.value.trim() })
                          }} type="url" className="form-control rounded-1" placeholder="https://myapp.com/auth/redirect"/>
                        </div>
                      </label>

                      { authorizationUrl &&
                        <div className="d-block pt-2 pb-2">
                          <span className="form-text pb-2 d-block">Authentication URL</span>
                          <div className="d-block">
                            <CopyButton value={ authorizationUrl } />
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
