import FileUploader from '../../components/FileUploader'
import React, { useState, useEffect, useRef } from "react"
import UserSettingsSidebar from '../../components/UserSettingsSidebar'
import { all } from '../../helpers/Currencies'
import { Currency } from '@depay/local-currency'
import { GET, POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useDebounce } from 'use-debounce'
import { useSelector } from 'react-redux'

export default function(props) {

  const user = useSelector(state => state.user)
  const account = useSelector(state => state.account)
  const [ userName, setUserName ] = useState(user ? user.name : undefined)
  const [ userEmail, setUserEmail ] = useState(user ? user.email : undefined)
  const [ userCurrency, setUserCurrency ] = useState(user ? (user.currency || Currency.getCode()) : Currency.getCode())
  const [ imageUrl, setImageUrl ] = useState()
  const [ verifyEmailError, setVerifyEmailError ] = useState()
  const [ image, setImage ] = useState(user.image)
  const [ editingUserEmail, setEditingUserEmail ] = useState(false)
  const [ verifyEmail, setVerifyEmail ] = useState(false)
  const userEmailRef = useRef()

  useEffect(()=>{
    GET(`/api/user`).then((user)=>{
      setUserEmail(user.email)
      setUserCurrency(user.currency)
      Store.dispatch(({ type: 'user/change', user }))
    })
  }, [])

  useEffect(()=>{
    if(image && account) {
      GET(`/api/accounts/${account.id}/uploads/preview?key=${image}`).then((presigned_url)=>{
        setImageUrl(presigned_url)
      })
    }
  }, [account, image])

  const handleNameChange = (event) => {
    const name = event.target.value
    setUserName(name)
    Store.dispatch(({ type: 'user/change', user: { name } }))
  }

  const handleCurrencyChange = (event)=> {
    const currency = event.target.value
    setUserCurrency(currency)
    Store.dispatch(({ type: 'user/change', user: { currency } }))
  }

  const handleEmailChange = (event) => {
    const email = event.target.value
    setUserEmail(email)
  }

  const createVeficiation = (event)=>{
    if(userEmail == undefined || !userEmail.match('@') || !userEmail.match('.')) {
      userEmailRef.current.focus()
      return
    }
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    setEditingUserEmail(false)
    setVerifyEmail(true)
    POST(`/api/user/email`, { address: userEmail }).catch((response)=>{
      setEditingUserEmail(true)
      setVerifyEmail(false)
      let responseData
      try { responseData = JSON.parse(response) } catch {}
      setVerifyEmailError(responseData?.error)
    })
    return false
  }

  const [ storeUser ] = useDebounce(userName, 500)
  useEffect(()=>{
    if(userName != undefined && storeUser != undefined) {
      POST(`/api/user`, { name: userName })
      Store.dispatch(({ type: 'user/change', user: {...user, name: userName, } }))
    }
  }, [storeUser])

  useEffect(()=>{
    POST(`/api/user`, { currency: userCurrency })
    Store.dispatch(({ type: 'user/change', user: {...user, currency: userCurrency } }))
  }, [userCurrency])

  const [ storeImage ] = useDebounce(image, 500)
  useEffect(()=>{
    if(storeImage != undefined) {
      POST(`/api/user`, { image })
      Store.dispatch(({ type: 'user/change', user: {...user, image } }))
    }
  }, [storeImage])

  const createAndVerifyElement = (
    <div>
      { verifyEmailError &&
        <div className="alert alert-info">
          <i className="fa-solid fa-triangle-exclamation me-2"></i>
          { verifyEmailError }
        </div>
      }
      <button onClick={ createVeficiation } className='btn btn-action' title="Click to save and verify">
        <span>Save & Verify</span>
        <i className="far fa-envelope ms-2"></i>
      </button>
    </div>
  )

  return(
    <div className="screen user-settings-screen row">

      <div className="col-12 col-md-3 order-md-1 sticky-reset">
        <UserSettingsSidebar/>
      </div>

      <div className="d-block col-12 col-md-9 d-md-flex justify-content-end pe-md-4">

        <div className="col-lg-8">

          <div className="form-group pb-2 mb-2">
            <label htmlFor="userName" className="pb-1 ps-1">
              <h5>Name</h5>
            </label>
            <div className="pt-1 pb-1">
              <input value={ userName || '' } onChange={ handleNameChange } type="text" className="form-control" id="userName" aria-describedby="userNameHelp" placeholder="Your name"></input>
            </div>
            <div className="px-2 pt-1">
              <small className="text-muted ps-1">
                <i className="fa-solid fa-globe me-2"/>
                Displayed to others.
              </small>
            </div>
          </div>

          <div className="form-group pb-2 pt-3 mb-2">
            <label  className="ps-1">
              <h5>Image</h5>
            </label>
            <div className="pt-1 pb-1">
              <div className='pt-2 px-1 text-center col-5 position-relative d-block' title="Click to change">
                {
                  imageUrl &&
                  <div className="ratio ratio-1x1">
                    <div className="rounded-circle d-inline-block" style={{ width: '100%', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.3)', backgroundImage: `url(${imageUrl})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}/>
                  </div>
                }
                <FileUploader 
                  className="ratio ratio-1x1"
                  label="Click or drop an image, max. 1 MB, square, min. 400x400px"
                  name="userImage"
                  types={["jpg", "png", "gif"]}
                  style={(image || imageUrl) ? { opacity: 0, position: 'absolute', top: 0 } : {}}
                  onUploadStart={()=>{
                    setImageUrl('')
                  }}
                  onChange={(key)=>{ 
                    setImage(key)
                    GET(`/api/accounts/${account.id}/uploads/preview?key=${key}`).then((presigned_url)=>{
                      setImageUrl(presigned_url)
                    })
                  }}
                />
              </div>
            </div>
            <div className="px-2 pt-1">
              <small className="text-muted ps-1">
                <i className="fa-solid fa-globe me-2"/>
                Displayed to others.
              </small>
            </div>
          </div>

          <div className="form-group pb-2 pt-3 mb-2">
            <label htmlFor="userEmail" className="pb-1 ps-1">
              <h5>Email Address</h5>
            </label>
            <div className="pt-1 pb-1">
            { user.email == undefined &&
              <div>
                <div>
                  <form onSubmit={ createVeficiation }>
                    <input ref={ userEmailRef } value={ userEmail || '' } onChange={ handleEmailChange } disabled={ verifyEmail } type="text" className="form-control" id="userEmail" aria-describedby="userNameHelp" placeholder="your@email.com"></input>
                  </form>
                </div>
                <div>
                  <div className="mt-2">
                    { !verifyEmail && createAndVerifyElement }
                  </div>
                </div>
              </div>
            }
            {
              user.email != undefined &&
              <div>
                <div className="col-7">
                  <form onSubmit={ createVeficiation }>
                    <input ref={ userEmailRef } value={ userEmail } onChange={ handleEmailChange } disabled={ !editingUserEmail } type="text" className="form-control" id="userEmail" aria-describedby="userNameHelp" placeholder="your@email.com"></input>
                  </form>
                </div>
                <div>
                  <div className="d-flex align-items-center mt-2">
                    { !editingUserEmail &&
                      <button onClick={ ()=>{ 
                        setEditingUserEmail(true)
                        setUserEmail('')
                        setTimeout(()=>{ userEmailRef.current.focus() }, 200)
                      }}  className='btn btn-action' title="Click to save and verify">
                        <span>Change</span>
                        <i className="far fa-edit ms-2"></i>
                      </button>
                    }
                  </div>
                  <div>{ editingUserEmail && createAndVerifyElement }</div>
                </div>
              </div>
            }
            </div>
            { verifyEmail &&
              <div className="alert alert-info">
                <i className="fas fa-envelope me-2"></i>
                Email sent. Please check now.
              </div>
            }
            <div className="px-2 pt-1">
              <small className="text-muted ps-1">
                <i className="fa-solid fa-lock position-relative me-2" />
                Private. Used for log-in and user notifications.
              </small>
            </div>
          </div>

          <div className="form-group pb-2 pt-3 mb-2">
            <label className="pb-1 ps-1">
              <h5>Display Currency</h5>
            </label>
            <div className="pt-1 pb-1">
              <select className="form-select" value={ userCurrency } onChange={ handleCurrencyChange }>
                { all.map((currency)=>{
                    return(
                      <option key={ currency } value={ currency }>{ currency }</option>
                    )
                  })
                }
              </select>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
