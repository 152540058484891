import React from "react"
import ReactDOM from "react-dom"
import CloseIcon from "../components/CloseIcon"
import { ReactDialog } from '@depay/react-dialog'

export default function(props) {

  return ReactDOM.createPortal(
    <ReactDialog background={'rgba(0,0,0,0.5)'} open={ props.open } close={ props.close }>
      <div className="dialog ReactDialogAnimation">

        <div className={["dialog-header"].join(' ')}>
          <div className="dialog-header-title">
            <div className="py-2 px-3">
              <span className="dialog-header-title-text">{ props.header }</span>
            </div>
          </div>
          <div className="dialog-header-action py-2 px-3">
            <button onClick={ props.close } className="btn-dialog-control" title="Close">
              <CloseIcon/>
            </button>
          </div>
        </div>

        <div className="dialog-body">
          <div className="px-3 py-2">
            { props.body }
          </div>
        </div>

        { props.noFooter != true &&
          <div className="dialog-footer">
            <div className="px-3">
              { props.footer }
            </div>
          </div>
        }

      </div>
    </ReactDialog>,
    document.getElementById('app')
  );
}
