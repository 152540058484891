import moment from 'moment'

export default function(requests, parent){

  const format = 'YYYY-MM-DD'

  let groupedRequests = JSON.parse(JSON.stringify(parent))
  if(groupedRequests.byId === undefined) { groupedRequests.byId = {} }
  if(groupedRequests.byGroupId === undefined) { groupedRequests.byGroupId = {} }

  requests.forEach((request)=>{
    groupedRequests.byId[request.id] = request

    if(request.due_date && request.price) {

      let key
      if(request.due_date == moment().format('YYYY-MM-DD')){
        key = 'today'
      } else if(moment(request.due_date, "YYYY-MM-DD").isBefore(moment().startOf('day'))) {
        key = 'overdue'
      } else if(
        moment(request.due_date).isoWeek() === moment().isoWeek() &&
        moment(request.due_date).year() === moment().year()
      ){
        key = 'thisWeek'
      } else if(
        moment(request.due_date).isoWeek() === moment().isoWeek()+1 &&
        moment(request.due_date).year() === moment().year()
      ){
        key = 'nextWeek'
      } else if(
        moment(request.due_date).month() === moment().month() &&
        moment(request.due_date).year() === moment().year()
      ) {
        key = 'thisMonth'
      } else if(
        moment(request.due_date).month() === moment().month()+1 &&
        moment(request.due_date).year() === moment().year()
      ) {
        key = 'nextMonth'
      } else if(moment(request.due_date).year() === moment().year()) {
        key = 'thisYear'
      } else if(moment(request.due_date).year() === moment().year()+1) {
        key = 'nextYear'
      } else {
        key = 'later'
      }

      if(groupedRequests.byGroupId[key] === undefined) {
        groupedRequests.byGroupId[key] = []
      }

      let requestsInGroup = groupedRequests.byGroupId[key]
      if(requestsInGroup.indexOf(request.id) > -1) { return }
      if(requestsInGroup.length == 0 || moment(groupedRequests.byId[requestsInGroup[requestsInGroup.length-1]].due_date).isBefore(moment(request.due_date))) {
        requestsInGroup.push(request.id)
      } else {
        const index = requestsInGroup.findIndex((existingPaymentId)=>{
          return moment(groupedRequests.byId[existingPaymentId].due_date).isAfter(moment(request.due_date))
        })
        groupedRequests.byGroupId[key].splice(index, 0, request.id)
      }

    } else {
      if(groupedRequests.incomplete === undefined) { groupedRequests.incomplete = [] }
      let requestsInGroup = groupedRequests.incomplete
      if(requestsInGroup.indexOf(request.id) > -1) { return }
      if(requestsInGroup.length == 0 || moment(groupedRequests.byId[requestsInGroup[requestsInGroup.length-1]].created_at).isAfter(moment(request.created_at))) {
        groupedRequests.incomplete.push(request.id)
      } else {
        const index = requestsInGroup.findIndex((existingPaymentId)=>{
          return moment(groupedRequests.byId[existingPaymentId].created_at).isBefore(moment(request.created_at))
        })
        groupedRequests.incomplete.splice(index, 0, request.id)
      }
    }
  })

  return groupedRequests
}
