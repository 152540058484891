import Blockchains from "@depay/web3-blockchains"
import moment from 'moment'
import Payment from "../../models/Payment"
import React, { useEffect } from "react"
import Token from "../../models/Token"
import { Currency } from '@depay/local-currency'
import { NavLink } from "react-router-dom"
import { TokenImage } from '@depay/react-token-image'
import { useSelector } from 'react-redux'

export default function(props) {

  const groupDate = new Date(props.groupKey)
  const groupLabel = moment(props.groupKey, 'YYYY-MM-DD').format('LL')
  const accountWallets = useSelector(state => state.account_wallets)
  const tokens = useSelector(state => state.tokens)
  const user = useSelector(state => state.user)

  useEffect(()=>{
    Currency.fromUSD({ amount: 1, code: user.currency }) // preload local currency quote for sync display rendering
  }, [])

  if(!accountWallets || accountWallets.lenth === 0) { return(null) }

  return(
    <div key={props.groupKey}>
      <h2 className="h5 px-2 px-lg-0 text-black opacity-50 mb-2 font-size-s">{
        groupLabel
      }</h2>
      <div className="rounded p-1 mb-3 bg-white">
        {
          props.payments.byGroupId[props.groupKey].map((paymentId)=>{
            const payment = new Payment(props.payments.byId[paymentId], accountWallets)
            const token = tokens.byId[`${payment.data.blockchain}-${payment.tokenId}`] ? new Token(tokens.byId[`${payment.data.blockchain}-${payment.tokenId}`]) : undefined
            const blockchain = Blockchains[payment.data.blockchain]

            return(
              <NavLink to={props.link(paymentId)} key={paymentId} className="circular-card-interactive p-2 py-3 p-lg-3">
                <div className="row d-flex align-items-center">
                  <div className="col-2 col-lg-1 text-center">
                    <div className='position-relative d-inline-block'>
                      <TokenImage
                        className="me-1 token-image-normal"
                        blockchain={payment.data.blockchain}
                        address={payment.tokenId}
                      />
                      <img className="blockchain-logo-small" style={{ backgroundColor: blockchain.logoBackgroundColor }} src={ blockchain.logo } title={`on ${ blockchain.label }`}/>
                    </div>
                  </div>
                  <div className="col-6 line-height-extra-small ps-lg-3">
                    <div>
                      <span className="font-weight-medium">{ payment.title }</span>
                    </div>
                    <div className="opacity-50">
                      <small>{ payment.time() }</small>
                    </div>
                  </div>
                  <div className="col-4 col-lg-5 line-height-extra-small text-end pe-1">
                    <div>
                      <span className="font-weight-medium">{ Currency.fromUSDSync({ amount: payment.amountUsd || 0, code: user.currency }).toString() }</span>
                    </div>
                    <div className="opacity-50 text-truncate">
                      { token &&
                        <small className="text-nowrap">{ payment.amountPrefix } { token.data.symbol } { payment.amountToken }</small>
                      }
                    </div>
                  </div>
                </div>
              </NavLink>
            )
          })
        }
      </div>
    </div>
  )
}
