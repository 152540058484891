const DEFAULT_RATES = {
  "AD": 4.5, // Andorra
  "AE": 5.0,  // United Arab Emirates
  "AF": 10.0, // Afghanistan
  "AG": 15.0,// Antigua and Barbuda
  "AI": 13.0, // Anguilla
  "AL": 20.0, // Albania
  "AM": 20.0, // Armenia
  "AO": 14.0, // Angola
  "AQ": 0.0, // Antarctica
  "AR": 21.0, // Argentina
  "AS": 0.0, // American Samoa
  "AT": 20.0, // Austria
  "AU": 10.0, // Australia
  "AW": 4.0, // Aruba
  "AX": 0.0, // Åland Islands
  "AZ": 18.0, // Azerbaijan
  "BA": 17.0, // Bosnia and Herzegovina
  "BB": 17.5,// Barbados
  "BD": 15.0, // Bangladesh
  "BE": 21.0, // Belgium
  "BF": 18.0, // Burkina Faso
  "BG": 20.0, // Bulgaria
  "BH": 10.0, // Bahrain
  "BI": 18.0, // Burundi
  "BJ": 18.0, // Benin
  "BL": 0.0, // Saint Barthélemy
  "BM": 0.0, // Bermuda
  "BN": 0.0,  // Brunei
  "BO": 13.0, // Bolivia
  "BQ": 6.0, // Bonaire, Sint Eustatius and Saba
  "BR": 17.0, // Brazil
  "BS": 12.0,// Bahamas
  "BT": 0.0, // Bhutan
  "BV": 0.0, // Bouvet Island
  "BW": 14.0, // Botswana
  "BY": 20.0, // Belarus
  "BZ": 12.5,// Belize
  "CA": 5.0,  // Canada (GST)
  "CC": 0.0, // Cocos (Keeling) Islands
  "CD": 16.0,// Democratic Republic of the Congo
  "CF": 19.0, // Central African Republic
  "CG": 18.0,// Republic of the Congo
  "CH": 8.1,  // Switzerland
  "CI": 18.0, // Ivory Coast
  "CK": 15.0,// Cook Islands
  "CL": 19.0, // Chile
  "CM": 19.25,// Cameroon
  "CN": 13.0, // China
  "CO": 19.0, // Colombia
  "CR": 13.0, // Costa Rica
  "CU": 10.0, // Cuba
  "CV": 15.0, // Cape Verde
  "CW": 6.0, // Curaçao
  "CX": 0.0, // Christmas Island
  "CY": 19.0, // Cyprus
  "CZ": 21.0, // Czech Republic
  "DE": 19.0, // Germany
  "DJ": 10.0, // Djibouti
  "DK": 25.0, // Denmark
  "DM": 15.0,// Dominica
  "DO": 18.0, // Dominican Republic
  "DZ": 19.0, // Algeria
  "EC": 12.0, // Ecuador
  "EE": 20.0, // Estonia
  "EG": 14.0, // Egypt
  "EH": 20.0, // Western Sahara
  "ER": 15.0, // Eritrea
  "ES": 21.0, // Spain
  "ET": 15.0, // Ethiopia
  "FI": 24.0, // Finland
  "FJ": 9.0,  // Fiji
  "FK": 0.0, // Falkland Islands
  "FM": 5.0, // Micronesia
  "FO": 25.0,// Faroe Islands
  "FR": 20.0, // France
  "GA": 18.0, // Gabon
  "GB": 20.0, // United Kingdom
  "GD": 15.0,// Grenada
  "GE": 18.0, // Georgia
  "GF": 0.0, // French Guiana
  "GG": 0.0, // Guernsey
  "GH": 12.5, // Ghana
  "GI": 0.0, // Gibraltar
  "GL": 0.0, // Greenland
  "GM": 15.0, // Gambia
  "GN": 18.0, // Guinea
  "GP": 0.0, // Guadeloupe
  "GQ": 15.0, // Equatorial Guinea
  "GR": 24.0, // Greece
  "GS": 0.0, // South Georgia and the South Sandwich Islands
  "GT": 12.0, // Guatemala
  "GU": 0.0, // Guam
  "GW": 15.0, // Guinea-Bissau
  "GY": 14.0, // Guyana
  "HK": 0.0,  // Hong Kong
  "HM": 0.0, // Heard Island and McDonald Islands
  "HN": 15.0, // Honduras
  "HR": 25.0, // Croatia
  "HT": 10.0,// Haiti
  "HU": 27.0, // Hungary
  "ID": 11.0, // Indonesia
  "IE": 23.0, // Ireland
  "IL": 17.0, // Israel
  "IM": 20.0,// Isle of Man
  "IN": 18.0, // India (GST)
  "IO": 0.0, // British Indian Ocean Territory
  "IQ": 10.0, // Iraq
  "IR": 9.0,  // Iran
  "IS": 24.0, // Iceland
  "IT": 22.0, // Italy
  "JE": 5.0, // Jersey
  "JM": 15.0, // Jamaica
  "JO": 16.0, // Jordan
  "JP": 10.0, // Japan
  "KE": 16.0, // Kenya
  "KG": 12.0, // Kyrgyzstan
  "KH": 10.0, // Cambodia
  "KI": 12.5,// Kiribati
  "KM": 10.0, // Comoros
  "KN": 17.0,// Saint Kitts and Nevis
  "KP": 0.0, // North Korea
  "KR": 10.0, // Korea (South)
  "KW": 5.0,  // Kuwait
  "KY": 0.0, // Cayman Islands
  "KZ": 12.0, // Kazakhstan
  "LA": 10.0, // Laos
  "LB": 11.0, // Lebanon
  "LC": 12.5,// Saint Lucia
  "LI": 7.7,  // Liechtenstein
  "LK": 8.0,  // Sri Lanka
  "LR": 10.0, // Liberia
  "LS": 15.0, // Lesotho
  "LT": 21.0, // Lithuania
  "LU": 17.0, // Luxembourg
  "LV": 21.0, // Latvia
  "LY": 20.0, // Libya
  "MA": 20.0, // Morocco
  "MC": 20.0,// Monaco
  "MD": 20.0, // Moldova
  "ME": 21.0, // Montenegro
  "MF": 0.0, // Saint Martin (French part)
  "MG": 20.0, // Madagascar
  "MH": 0.0, // Marshall Islands
  "MK": 18.0,// North Macedonia
  "ML": 18.0, // Mali
  "MM": 5.0,  // Myanmar
  "MN": 10.0, // Mongolia
  "MO": 0.0,  // Macau
  "MP": 0.0, // Northern Mariana Islands
  "MQ": 0.0, // Martinique
  "MR": 16.0, // Mauritania
  "MS": 0.0, // Montserrat
  "MT": 18.0, // Malta
  "MU": 15.0, // Mauritius
  "MV": 6.0,  // Maldives
  "MW": 16.5, // Malawi
  "MX": 16.0, // Mexico
  "MY": 6.0,  // Malaysia
  "MZ": 17.0, // Mozambique
  "NA": 15.0, // Namibia
  "NC": 11.0,// New Caledonia
  "NE": 19.0, // Niger
  "NF": 0.0, // Norfolk Island
  "NG": 7.5,  // Nigeria
  "NI": 15.0, // Nicaragua
  "NL": 21.0, // Netherlands
  "NO": 25.0, // Norway
  "NP": 13.0, // Nepal
  "NR": 0.0, // Nauru
  "NU": 12.5,// Niue
  "NZ": 15.0, // New Zealand
  "OM": 5.0,  // Oman
  "PA": 7.0,  // Panama
  "PE": 18.0, // Peru
  "PF": 13.0,// French Polynesia
  "PG": 10.0, // Papua New Guinea
  "PH": 12.0, // Philippines
  "PK": 17.0, // Pakistan
  "PL": 23.0, // Poland
  "PM": 0.0, // Saint Pierre and Miquelon
  "PN": 0.0, // Pitcairn Islands
  "PR": 10.5,// Puerto Rico
  "PS": 16.0,// Palestine
  "PT": 23.0, // Portugal
  "PW": 0.0, // Palau
  "PY": 10.0, // Paraguay
  "QA": 5.0,  // Qatar
  "RE": 8.5, // Réunion
  "RO": 19.0, // Romania
  "RS": 20.0, // Serbia
  "RU": 20.0, // Russia
  "RW": 18.0, // Rwanda
  "SA": 15.0, // Saudi Arabia
  "SB": 10.0,// Solomon Islands
  "SC": 15.0, // Seychelles
  "SD": 17.0, // Sudan
  "SE": 25.0, // Sweden
  "SG": 8.0,  // Singapore (GST)
  "SH": 15.0,// Saint Helena
  "SI": 22.0, // Slovenia
  "SJ": 25.0,// Svalbard and Jan Mayen
  "SK": 20.0, // Slovakia
  "SL": 15.0, // Sierra Leone
  "SM": 0.0, // San Marino
  "SN": 18.0, // Senegal
  "SO": 5.0, // Somalia
  "SR": 10.0, // Suriname
  "SS": 0.0, // South Sudan
  "ST": 15.0,// Sao Tome and Principe
  "SV": 13.0, // El Salvador
  "SX": 5.0, // Sint Maarten (Dutch part)
  "SY": 11.0, // Syria
  "SZ": 15.0, // Swaziland
  "TC": 0.0, // Turks and Caicos Islands
  "TD": 18.0, // Chad
  "TF": 0.0, // French Southern Territories
  "TG": 18.0, // Togo
  "TH": 7.0,  // Thailand
  "TJ": 18.0, // Tajikistan
  "TK": 0.0, // Tokelau
  "TL": 10.0,// Timor-Leste
  "TM": 15.0, // Turkmenistan
  "TN": 19.0, // Tunisia
  "TO": 15.0, // Tonga
  "TR": 18.0, // Turkey
  "TT": 12.5, // Trinidad and Tobago
  "TV": 0.0, // Tuvalu
  "TW": 5.0,  // Taiwan
  "TZ": 18.0, // Tanzania
  "UA": 20.0, // Ukraine
  "UG": 18.0, // Uganda
  "UM": 0.0, // United States Minor Outlying Islands
  "US": 0.0,  // United States (no VAT)
  "UY": 22.0, // Uruguay
  "UZ": 15.0, // Uzbekistan
  "VA": 0.0,  // Vatican City
  "VC": 16.0,// Saint Vincent and the Grenadines
  "VE": 16.0, // Venezuela
  "VG": 0.0, // British Virgin Islands
  "VI": 0.0, // United States Virgin Islands
  "VN": 10.0, // Vietnam
  "VU": 12.5, // Vanuatu
  "WF": 0.0, // Wallis and Futuna
  "WS": 15.0,// Samoa
  "YE": 5.0,  // Yemen
  "YT": 0.0, // Mayotte
  "ZA": 15.0, // South Africa
  "ZM": 16.0, // Zambia
  "ZW": 14.5  // Zimbabwe
}

export { DEFAULT_RATES }
