export default function AccountWalletsReducer(state = [], action) {
  let newState
  switch (action.type) {
    case 'accountWallets/add':
      newState = (state).slice()
      newState.byExternalId = {}
      newState.push(action.accountWallet)
      newState.byExternalId[action.accountWallet.external_id] = action.accountWallet
      return newState
    case 'accountWallets/set':
      newState = action.accountWallets.slice()
      newState.byExternalId = {}
      newState.forEach((accountWallet)=>{
        newState.byExternalId = { [accountWallet.external_id]: accountWallet }
      })
      return newState
    case 'accountWallets/update':
      newState = (state).slice()
      let updatedLinkIndex = newState.findIndex((i)=>action.accountWallet.id==i.id)
      if(updatedLinkIndex > -1){
        newState[updatedLinkIndex] = action.accountWallet
        newState.byExternalId = newState.byExternalId || {}
        newState.byExternalId[action.accountWallet.external_id] = action.accountWallet
      }
      return newState
    case 'accountWallets/delete':
      let deletedAccountWallet
      newState = state.filter((accountWallet)=>{
        if(accountWallet.id == action.id) {
          deletedAccountWallet = accountWallet
          return false
        } else {
          return true
        }
      })
      return newState
    default:
      return state
  }
}
