export default (input)=>{

  if(!input || input.length === 0) { return '' } 

  let initials = input
    .split(' ')
    .slice(0, 2) // Get first two words, if available
    .map(word => word.charAt(0).toUpperCase()) // Get the first letter of each word
    .join(''); // Join the letters together

  return initials;
}
