import React, { useEffect } from "react"

export default function(props) {

  useEffect(()=> {
    if(props.show) {
      let handleClick = (event)=>props.set(false)
      setTimeout(()=>{ document.body.addEventListener('click', handleClick) }, 100)
      setTimeout(()=>{ props.set(false) }, 1400)
      return ()=>document.body.removeEventListener('click', handleClick)
    }
  }, [props.show])

  return(
    <div style={{ zIndex: props.show ? 1000 : -1 }} className={`tooltip user-select-none bs-tooltip-bottom text-center m-auto w-100 d-flex justify-content-center fade ${props.show ? "show" : ""}`}>
      <span className="tooltip-arrow"></span>
      <span className="tooltip-inner">{ props.icon }{ props.text }</span>
    </div>
  )
}
