import moment from 'moment'
import React, { useEffect, useState, useRef } from "react"
import sanitizeSeriesData from '../helpers/sanitizeSeriesData'
import { createChart, ColorType } from 'lightweight-charts'
import { Currency } from '@depay/local-currency'
import { useDebouncedCallback } from 'use-debounce'
import { useSelector } from 'react-redux'

export default function(props) {

  const chartRef = useRef()
  const tooltipRef = useRef()
  const user = useSelector(state => state.user)
  const now = moment()
  const totalStatistic = useSelector(state => state?.statistics?.[props.belongsTo]?.[props.belongsToId]?.[props.name]?.total)
  const [ range, setRange ] = useState(`${now.year()}-${now.format("MM")}`)
  const rangeStatistic = useSelector(state => state?.statistics?.[props.belongsTo]?.[props.belongsToId]?.[props.name]?.[range])
  const [ conversionRate, setConversionRate ] = useState()
  const [ currentMarkerTime, setCurrentMarketTime ] = useState(`${now.year()}-${now.format("MM")}-${now.format("DD")}`)
  const height = 200
  const [ width, setWidth ] = useState()
  const [ series, setSeries ] = useState()
  const setLatestTooltipValue = async function() {
    let value = (props.format === 'currency' && conversionRate) ? new Currency({ code: user.currency, amount: ((totalStatistic?.total||0)/1000) * conversionRate }).toString() : (totalStatistic?.total||0)
    tooltipRef.current.innerHTML = `
      <div>${ props.label }</div>
      <div><h3 class="pt-1 pb-1 line-height-small">${ value }</h3></div>
    `
  }

  const debouncedCrosshairMove = useDebouncedCallback(
    async(param) => {
      if (
        param === undefined ||
        param.time === undefined ||
        param.point.x < 0 ||
        param.point.x > width ||
        param.point.y < 0 ||
        param.point.y > height
      ) {
        setLatestTooltipValue()
      } else {
        var data = param.seriesData.get(series)
        if(data){
          let value = (props.format === 'currency' && conversionRate) ? new Currency({ code: user.currency, amount: (data.value/1000) * conversionRate }).toString() : data.value
          tooltipRef.current.innerHTML = `
            <div>${ props.label }</div>
            <div class="position-relative">
              <h3 class="pt-1 line-height-small">${ value }</h3>
            </div>
            <div class="position-relative">
              <div class="text-muted position-relative"><small>${moment(param.time).format('DD MMMM YYYY')}</small></div>
            </div>
          `
        } else {
          setLatestTooltipValue()
        }
      }
    },
    10
  )

  useEffect(()=>{
    (async ()=>{
      setConversionRate((await Currency.fromUSD({ amount: 1, code: user.currency })).amount)
    })()
  }, [])

  useEffect(()=>{
    if(conversionRate) { setLatestTooltipValue() }
  }, [conversionRate])

  useEffect(()=>{

    if(chartRef && chartRef.current) {

      setWidth(chartRef.current.clientWidth)

      const chart = createChart(chartRef.current, {
        width,
        height,
        handleScale: {
          mouseWheel: false,
          axisPressedMouseMove: {
            time: false,
            price: false,
          },
        },
        handleScroll: {
          mouseWheel: false,
          pressedMouseMove: false,
          horzTouchDrag: false,
          vertTouchDrag: false,
        },
        layout: {
          backgroundColor: 'transparent',
          textColor: '#430137'
        },
        rightPriceScale: {
          visible: false,
        },
        timeScale: {
          borderVisible: true,
          ticksVisible: true,
          borderColor: 'rgba(197, 203, 206, 0.5)'
        },
        grid: {
          horzLines: { visible: false },
          vertLines: { visible: false },
        },
        crosshair: {
          horzLine: { visible: false, labelVisible: false },
          vertLine: {
            visible: true,
            style: 2,
            width: 2,
            color: 'rgba(32, 38, 46, 0.1)',
            labelVisible: false
          }
        },
      })

      const padding = rangeStatistic ? 0.4 * rangeStatistic.total : 0;

      const series = chart.addAreaSeries({
        lineColor: '#EA357A',
        lineWidth: 2,
        topColor: '#EA357A',
        bottomColor: 'rgba(255, 255, 255, 0)',
        priceLineVisible: false,
        autoscaleInfoProvider: () => {
          return {
            priceRange: {
              minValue: 0,
              maxValue: rangeStatistic ? rangeStatistic.total + padding : 200,
            },
          };
        },
      })

      series.setData(sanitizeSeriesData(rangeStatistic ? rangeStatistic.data : undefined, range))
      series.setMarkers([ { time: currentMarkerTime, position: 'inBar', color: '#EA357A', shape: 'circle', size: 0.5 } ])
      setSeries(series)

      setLatestTooltipValue()

      chart.subscribeCrosshairMove(debouncedCrosshairMove)

      chart.timeScale().fitContent()

      const handleResize = () => { 
        let width = chartRef.current.clientWidth
        setWidth(width)
        chart.applyOptions({ width })
      }

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
        chart.remove()
      }
    }
  }, [props.statistic, chartRef, tooltipRef])

  return(
    <div className="position-relative">
      <div className="position-relative d-block" ref={ chartRef }/>
      <div className="position-absolute top-0 start-0" ref={ tooltipRef } style={{ zIndex: 1 }}/>
    </div>  
  )
}
