export default function TokenReducer(state = { byId: {} }, action) {
  switch (action.type) {
    case 'tokens/add':
      let byId = JSON.parse(JSON.stringify(state.byId))
      action.tokens.forEach((token)=>{
        byId[`${token.blockchain}-${token.external_id}`] = token
      })
      return { byId }
    default:
      return state
  }
}
