export default function IntegrationsReducer(state = [], action) {
  let newState
  switch (action.type) {
    case 'integrations/add':
      newState = (state).slice()
      newState.push(action.integration)
      return newState
    case 'integrations/set':
      return action.integrations
    case 'integrations/update':
      newState = (state).slice()
      let updatedIntegrationIndex = newState.findIndex((i)=>action.integration.id==i.id)
      if(updatedIntegrationIndex > -1){
        newState[updatedIntegrationIndex] = action.integration
      }
      return newState
    case 'integrations/delete':
      newState = state.filter((integration)=>{
        return integration.id != action.id
      })
      return newState
    default:
      return state
  }
}
