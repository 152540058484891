import ActivateProDialog from "../../dialogs/ActivateProDialog"
import Dialog from "../../components/Dialog"
import moment from 'moment'
import React, { useState, useEffect } from "react"
import { GET, POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const showPaywall = useSelector(state => state.paywall)
  const [ loadedActiveSubscription, setLoadedActiveSubscription ] = useState(false)
  const [ startedAt, setStartedAt ] = useState()
  const [ expiringAt, setExpiringAt ] = useState()
  const [ justPurchased, setJustPurchased ] = useState(false)
  const [ showSubscriptionDialog, setShowSubscriptionDialog ] = useState(false)
  const activeSubscription = useSelector(state => state.subscription)

  useEffect(()=>{ 
    Store.dispatch(({ type: 'paywall/hide' }))
    if(window.location.hash.toLowerCase() == '#pro' && !activeSubscription) {
      setTimeout(()=>{ Store.dispatch(({ type: 'paywall/show' })) }, 400)
    }
  }, [])

  useEffect(()=>{
    setExpiringAt()
    GET(`/api/accounts/${account.id}/active_subscription`).then((subscription)=>{
      if(!!subscription) {
        Store.dispatch(({ type: 'subscription/active', subscription: subscription }))
        setStartedAt(moment(subscription.started_at))
        setExpiringAt(subscription.expiring_at ? moment(subscription.expiring_at) : undefined)
      } else {
        POST(`/api/accounts/${account.id}/pro`).then((validated)=>{
          if(validated) {
            setJustPurchased(true)
            setStartedAt(moment())
            setTimeout(()=>setLoadedActiveSubscription(true), 200)
          } else {
            Store.dispatch(({ type: 'subscription/active', subscription: null }))
            setStartedAt()
            setExpiringAt()
          }
        })
      }
      setLoadedActiveSubscription(true)
    })
  }, [account])

  let buttonElement
  if(loadedActiveSubscription) {
    if(justPurchased) {
      buttonElement = (
        <div className="pro-nav-item-container pb-0 pb-md-1" onClick={()=>setJustPurchased(false)}>
          <button className="pro-nav-item mt-2 mt-md-0 border-success bg-white pb-2 nav-item text-center" onClick={()=>{}} >
            <i className="fas fa-check-circle text-success me-3" style={{ fontSize: '0.82em', top: '1px', position: 'relative' }}></i>
            <span className="font-size-xs">PRO activated</span>
          </button>
        </div>
      )
    } else if(!startedAt) {
      buttonElement = (
        <div className="pro-nav-item-container pb-0 pb-md-1">
          <button className="pro-nav-item mt-2 mt-md-0 border-secondary pb-2 nav-item text-center" onClick={()=>Store.dispatch(({ type: 'paywall/show' }))}>
            <i className="fas fa-rocket text-secondary ms-1 me-2" style={{ fontSize: '0.65em', opacity: 0.7 }}></i>
            <span className="font-size-xs text-secondary">Activate PRO</span>
          </button>
        </div>
      )
    }
  } else {
    buttonElement = null
  }
  
  return(
    <div>
      <ActivateProDialog
        close={ ()=>Store.dispatch(({ type: 'paywall/hide' })) }
        open={ loadedActiveSubscription && showPaywall }
        done={ ()=>{
          Store.dispatch(({ type: 'paywall/hide' }))
          setStartedAt(moment(new Date))
          setLoadedActiveSubscription(true)
          setJustPurchased(true)
        } }
      />
      <Dialog
        open={ showSubscriptionDialog }
        close={ ()=>setShowSubscriptionDialog(false) }
        header={ 'Subscription' }
        body={
          startedAt &&
          <div className="pb-1">
            <div className="card py-2 d-block line-height-extra-small">
              <div className="ps-2 pt-1">
                <strong className="font-size-s line-height-extra-small">
                  Started { startedAt.fromNow() }
                </strong>
              </div>
              <div className="px-2 pb-2">
                <small className="opacity-50 line-height-extra-small">
                  { startedAt.format('LLL') }
                </small>
              </div>
            </div>
            { expiringAt &&
              <div className="card py-2 d-block line-height-extra-small">
                <div className="ps-2 pt-1">
                  <strong className="font-size-s line-height-extra-small">
                    Ends { expiringAt.fromNow() }
                  </strong>
                </div>
                <div className="px-2 pb-2">
                  <small className="opacity-50 line-height-extra-small">
                    { expiringAt.format('LLL') }
                  </small>
                </div>
              </div>
            }
          </div>
        }
      />
      { buttonElement }
    </div>
  )
}
