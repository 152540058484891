export default function AccountReducer(state = null, action) {
  switch (action.type) {
    case 'account/change':
      if(state) {
        return Object.assign({}, state, action.account)
      } else {
        return action.account
      }
    case 'account/select':
      return action.account
    default:
      return state
  }
}
