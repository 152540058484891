export default function formatQuantityNumber(num) {
  if (num < 1000) {
    return num; // return the number directly if less than 1000
  }

  const symbols = ["", "K", "M", "B", "T"]; // symbols for Thousand, Million, Billion, Trillion
  const tier = Math.log10(num) / 3 | 0; // determine the scale of the number
  if (tier == 0) return num; // return the original number if less than 1000
  
  const scale = Math.pow(10, tier * 3);
  const scaled = num / scale; // scale the number
  let formatted = scaled.toFixed(1); // format to one decimal place

  // Remove trailing .0
  if (formatted.indexOf('.0') === formatted.length - 2) {
    formatted = formatted.substr(0, formatted.length - 2);
  }

  return formatted + symbols[tier];
}
