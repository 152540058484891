export default () => {
  let disable1Password = ()=> {
    setTimeout(()=>{
      ["com-1password-button", "com-1password-menu"].forEach((tag)=>{
        let length = document.getElementsByTagName(tag).length
        for (let i = 0; i < length; i++) {
          document.getElementsByTagName(tag)[0]?.remove()
        }
      })
    }, 50)
  }
  document.addEventListener('focus', disable1Password, true)
  return () => { document.removeEventListener('focus', disable1Password, true) }
}
