import AddressEllipsis from "../helpers/AddressEllipsis"
import Blockchains from "@depay/web3-blockchains"
import CloseIcon from "../components/CloseIcon"
import copy from 'copy-to-clipboard'
import CopyAttribute from '../components/CopyAttribute'
import Dropdown from '../components/Dropdown'
import loadAdditionalTokenDataForPayments from "../helpers/loadAdditionalTokenDataForPayments"
import moment from 'moment'
import PaymentSkeleton from './payment/Skeleton'
import React, { useState, useEffect } from "react"
import Token from "../models/Token"
import TokenAmountRow from "../components/TokenAmountRow"
import { Currency } from '@depay/local-currency'
import { GET } from "../helpers/Queries"
import { NavLink } from "react-router-dom"
import { Store } from '../Store'
import { TokenImage } from '@depay/react-token-image'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

export default function(props) {

  const { id } = useParams()
  const account = useSelector(state => state.account)
  const payment = useSelector(state => state.payments?.byId?.[id])
  const tokens = useSelector(state => state.tokens)
  const user = useSelector(state => state.user)
  const blockchain = Blockchains[payment?.blockchain]
  const [ displayedCurrencyAmount, setDisplayedCurrencyAmount ] = useState()
  const formatFormDataKey = (key)=>{
    key = key.replace(/_/g, ' ')
    key = key.charAt(0).toUpperCase() + key.slice(1)
    return key
  }

  useEffect(()=>{
    if(payment) {
      
      Currency.fromUSD({ amount: payment.receiver_amount_usd, code: user.currency }).then((currencyAmount)=>{
        setDisplayedCurrencyAmount(currencyAmount.toString())
      }).catch(()=>{
        setDisplayedCurrencyAmount(payment.receiver_amount_usd)
      })

    } else {
      
      GET(`/api/accounts/${account.id}/payments/${id}`).then(async (responseData)=>{
        if(responseData) {
          await loadAdditionalTokenDataForPayments([responseData])
          Store.dispatch(({ type: 'payments/add', payments: [responseData] }))
        }
      })
      
    }
  }, [payment])

  if(!payment) { return(<PaymentSkeleton/>) }

  return(

    <div className="screen payment-screen">

      <div className="row pb-3">

        <div className="col-12">
          <div className="d-flex flex-row position-relative">
            
            <div className="me-3">
              <div className="bg-white d-inline-block rounded-circle" style={{ height: '60px', width: '60px' }}>
                <div className='position-relative d-inline-block'>
                  <TokenImage
                    className="token-image-big"
                    blockchain={payment.blockchain}
                    address={payment.receiver_token_id}
                  />
                  <img className="blockchain-logo-medium" style={{ position: 'absolute', bottom: 0, right: 0, backgroundColor: blockchain.logoBackgroundColor }} src={ blockchain.logo } title={`on ${ blockchain.label }`}/>
                </div>
              </div>
            </div>

            <div className="ps-2 pt-2">

              <div className="font-size-xl line-height-small">
                <strong className="font-size-l">
                  { displayedCurrencyAmount }
                </strong>
              </div>
              { tokens.byId[`${payment.blockchain}-${payment.receiver_token_id}`] &&
                <div className="line-height-extra-small pt-2 opacity-50">
                  { payment.amountPrefix } { tokens.byId[`${payment.blockchain}-${payment.receiver_token_id}`].symbol } { payment.receiver_amount }
                </div>
              }

            </div>
          </div>
        </div>
      </div>

      <div className="pt-2 px-1">
        { moment(payment.confirmed_at).format('LLL') }
      </div>

      <div className="row pt-3">

        <div className="col-12 col-md-10 col-lg-8 col-xl-6">

          <div className="bg-white rounded-2 p-1 pb-2">

            <div className="py-2 px-3">

              <div className="row pt-2 pb-1">
                <div className="col-4 opacity-50">
                  Blockchain
                </div>
                <div className="col-8 text-end">
                  { blockchain.label }
                </div>
              </div>

              <div className="row pt-2 pb-1">
                <div className="col-4 opacity-50">
                  From
                </div>
                <div className="col-8 text-end">
                  <CopyAttribute value={ payment.sender_id }>
                    { AddressEllipsis(payment.sender_id) }
                  </CopyAttribute>
                </div>
              </div>

              <div className="row pt-2 pb-1">
                <div className="col-4 opacity-50">
                  To
                </div>
                <div className="col-8 text-end position-relative">
                  <CopyAttribute value={ payment.receiver_id }>
                    { AddressEllipsis(payment.receiver_id) }
                  </CopyAttribute>
                </div>
              </div>

              <div className="row pt-2 pb-1">
                <div className="col-4 opacity-50">
                  Explorer
                </div>
                <div className="col-8 text-end">
                  <a href={ blockchain.explorerUrlFor({ transaction: { id: payment.transaction_id } }) } target="_blank" rel="noopener noreferrer">
                    Link
                    <i className="ps-2 fas fa-external-link-alt"></i>
                  </a>
                </div>
              </div>

            </div>
          </div>


          { payment.form_data && Object.keys(payment.form_data).length &&
            <div className="bg-white rounded-2 p-1 pb-2 mt-3">

              <div className="py-2 px-3">

                { Object.keys(payment.form_data).map((key, index)=>{
                  return (
                    <div className="position-relative" key={key}>
                      
                      <div className="row pt-2 pb-1">
                      
                        <div className="col-6 opacity-50 text-truncate" title={ formatFormDataKey(key) }>
                          { formatFormDataKey(key) }
                        </div>
                      
                        <div className="col-6 text-end position-relative">
                          <CopyAttribute className="max-w-100" value={ payment.form_data[key] }>
                            <span className="text-truncate d-block w-100">{ payment.form_data[key] }</span>
                          </CopyAttribute>
                        </div>
                      
                      </div>
                    </div>
                  )
                })}

              </div>
            </div>
          }

        </div>
      </div>
    </div>
  )
}
