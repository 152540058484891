import React from "react"
import { NavLink } from "react-router-dom"

export default function(props) {

  return(

    <div className="row pt-4 pb-5">

      <div className="col-12 col-xl-6 pt-2">

        <div className="card d-block pb-2 pt-3 border-bottom mb-4">
          <div className="onboarding-background links" alt="DePay Payment Links"/>
          <div className="card-body pt-0">
            <h5 className="card-title">Links</h5>
            <p className="card-text text-black-50 pb-3">Simply create a link and start accepting no-code Web3 Payments directly into your wallet.</p>
            <NavLink to="/links/new" className="btn btn-primary btn-square w-100 py-2">
              Create a link
            </NavLink>
          </div>
        </div>

        <div className="card d-block pb-2 pt-3 border-bottom mb-4">
          <div className="onboarding-background plugins" alt="DePay Payment Plugins"/>
          <div className="card-body pt-0">
            <h5 className="card-title">Plugins</h5>
            <p className="card-text text-black-50 pb-3">Use one of DePay's ready made plugins for common platforms.</p>
            <NavLink to="/plugins" className="btn btn-primary btn-square w-100 py-2">
              Usa a plugin
            </NavLink>
          </div>
        </div>

        <div className="card d-block pb-2 pt-3 border-bottom mb-4">
          <div className="onboarding-background integrate" alt="Integrate DePay"/>
          <div className="card-body pt-0">
            <h5 className="card-title">Integrate</h5>
            <p className="card-text text-black-50 pb-3">Integrate DePay and start accepting Web3 Payments directly in your app or on your website.</p>
            <NavLink to="/dev/integrations/new" className="btn btn-primary btn-square w-100 py-2">
              Start integrating
            </NavLink>
          </div>
        </div>
      </div>
    </div>

  )
}
