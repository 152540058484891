import AccountIcon from "../components/AccountIcon"
import React, { useState, useEffect } from "react"
import { GET } from "../helpers/Queries"
import { NavLink } from "react-router-dom"
import { Store } from '../Store'
import { useSelector } from 'react-redux'

export default function(props) {
  
  const account = useSelector(state => state.account)
  const accountWallets = useSelector(state => state.account_wallets)
  const [ showAccountDropDown, setShowAccountDropDown ] = useState(false)

  useEffect(()=> {
    if(showAccountDropDown) {
      let handleClick = (event)=>setShowAccountDropDown(false)
      setTimeout(()=>{ document.body.addEventListener('click', handleClick) }, 100)
      return ()=>document.body.removeEventListener('click', handleClick)
    }
  }, [showAccountDropDown])

  useEffect(()=> {
    GET(`/api/accounts/${account.id}/account_wallets`).then((accountWallets)=>{
      Store.dispatch({ type: 'accountWallets/set', accountWallets })
    })
  }, [])

  if(account) {
    return(
      <div className={["dropdown account-dropdown", ( showAccountDropDown ? "show" : '' ), props.className].filter(Boolean).join(' ')}>
        <button title={ account.name } className="btn dropdown-button d-flex align-items-center account-management-button" type="button" id="dropdownMenuButton" onClick={()=>setShowAccountDropDown(!showAccountDropDown)} aria-haspopup="true" aria-expanded="false">
          <AccountIcon account={ account } className="me-2"/>
          <span style={{ left: "1px" }} className="position-relative d-none d-md-block ps-2 flex-grow-1 text-truncate">{ account.name || 'Account' }</span>
        </button>
        <div className={["dropdown-menu", ( showAccountDropDown ? "show" : '' )].join(' ')} aria-labelledby="dropdownMenuButton">
          <div className="pt-2 pb-2">
            
            <div className="border-bottom pb-3">
              
              <div className="px-4">

                <h6 className="opacity-50">Account</h6>
                <div className="opacity-50">{ account.name }</div>

              </div>

              <div className="pt-2">
                <NavLink to="/plan" className={()=>"dropdown-item py-1"}>
                  Plan
                </NavLink>
                <NavLink to="/settings" className={()=>"dropdown-item py-1"}>
                  Settings
                </NavLink>
              </div>
            </div>

          </div>

          <NavLink to="/user/accounts" className={()=>"dropdown-item py-1"}>
            <span className="font-size-s">Switch account</span>
          </NavLink>
        </div>
      </div>
    )
  } else {
    return(null)
  }
}
