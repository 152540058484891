import React from "react"

export default function() {

  return(

  <div className="screen payment-screen">

      <div className="row pb-3">

        <div className="col-12">
          
          <div className="d-flex flex-row position-relative">
            
            <div className="me-3">
              <div className="d-inline-block rounded-circle skeleton" style={{ height: '60px', width: '60px' }}/>              
            </div>

            <div className="ps-2 pt-2">

              <div className="font-size-xl line-height-small">
                <div className="skeleton">
                  <div style={{ height: '1.8rem', width: '10rem' }}/>
                </div>
              </div>

              <div className="line-height-extra-small pt-2 opacity-50">
                <div className="skeleton d-inline-block">
                  <div style={{ height: '1rem', width: '4rem' }}/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="pt-1 px-1">
        <div className="skeleton d-inline-block">
          <div style={{ height: '0.8rem', width: '12rem' }}/> 
        </div>
      </div>

      <div className="row pt-3">

        <div className="col-12 col-md-10 col-lg-8 col-xl-6">

          <div className="card bg-white-transparent skeleton w-100 py-2 pb-2">
            <div style={{ height: '11rem' }} className="w-100"/>
          </div>

        </div>
      </div>
    </div>
  )
}
