import moment from 'moment'
import React, { useRef, useEffect, useState } from 'react'
import Tick from '@pqina/flip'

const FlipClock = (props) => {
  
  const flipClockRef = useRef(null)
  const counterRef = useRef(null)
  const [initialized, setInitialized] = useState(false)
  let tickInstance = null

  useEffect(() => {

    flipClockRef.current.innerHTML = `
      <div class="tick">

        <div data-repeat="true" data-layout="horizontal left">

          <div class="tick-group pe-2">

            <div data-key="value" data-repeat="true" data-transform="pad(00) -> split">
              <span data-view="flip"></span>
            </div>

            <span data-key="label" data-view="text" class="tick-label text-center w-100" data-transform="value('')"></span>

          </div>
        </div>
      </div>
    `

    const now = moment()
    const target = moment(props.value)
    const remaining = moment.duration(target.diff(now))
    const updateLabels = (tickInstance)=>{
      const labelList = tickInstance.root.querySelectorAll('.tick-label')
      if(labelList.item(labelList.length-1)) { labelList.item(labelList.length-1).innerHTML = 'Seconds' }
      if(labelList.item(labelList.length-2)) { labelList.item(labelList.length-2).innerHTML = 'Minutes' }
      if(labelList.item(labelList.length-3)) { labelList.item(labelList.length-3).innerHTML = 'Hours' }
      if(labelList.item(labelList.length-4)) { labelList.item(labelList.length-4).innerHTML = 'Days' }
    }
    const format = []
    if (remaining.asDays() >= 1) { format.push('d') }
    if (remaining.asHours() % 24 >= 1) { format.push('h') }
    if (remaining.asMinutes() % 60 >= 1) { format.push('m') }
    format.push('s')
    tickInstance = Tick.DOM.create(flipClockRef.current.querySelector('.tick'), {
      didInit: (tick)=>{
        setTimeout(()=>updateLabels(tick), 500)
      },
      didUpdate: (tick)=>{
        tick._element.querySelector('.tick-credits')?.remove()
      }
    })
    const counter = Tick.count.down(props.value, { format })
    counter.onupdate = function(value) {
      tickInstance.value = value
      setInitialized(true)
      updateLabels(tickInstance)
    }
    counter.onended = ()=>{}

    return () => {
      if(counter) {
        counter.onupdate = () => {};
      }
      const instance = flipClockRef?.current?.querySelector('.tick')
      if (instance) {
        Tick.DOM.destroy(instance)
      }
      setInitialized(false)
    }
  }, [props.value])

  return (
    <div className={ `flipclock ${initialized ? 'initialized' : ''}` } ref={ flipClockRef }/>
  );
};

export default FlipClock;
