import groupPayments from '../helpers/groupPayments'
import moment from 'moment'

const addPayments = (state, payments)=>{
  return groupPayments(payments, state)
}

export default function PaymentReducer(state = {}, action) {
  let newState
  switch (action.type) {
    case 'payments/link/set':
      newState = JSON.parse(JSON.stringify(state))
      newState[action.id] = addPayments({ byId: {}, groups: [], byGroupId: {} }, action.payments)
      return newState
    case 'payments/link/add':
      newState = JSON.parse(JSON.stringify(state))
      newState[action.id] = addPayments(newState[action.id], action.payments)
      return newState
    default:
      return state
  }
}
