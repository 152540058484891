import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const scrollPositions = new Map();

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  document.documentElement.style.scrollBehavior = 'auto'; // Disable smooth scroll

  const saveScrollPosition = () => {
    scrollPositions.set(location.key, { top: window.scrollY, left: window.scrollX });
  };

  const restoreScrollPosition = () => {
    const savedPosition = scrollPositions.get(location.key);
    if (savedPosition) {
      window.scrollTo({ top: savedPosition.top, left: savedPosition.left });
    } else {
      window.scrollTo({ top: 0, left: 0 });
    }
  };

  useEffect(() => {
    // Save scroll position when navigating away
    return () => saveScrollPosition();
  }, [location.key]);

  useEffect(() => {
    // Restore scroll position on new navigation
    restoreScrollPosition();
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
