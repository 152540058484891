import copy from 'copy-to-clipboard'
import moment from 'moment'
import React, { useState, useEffect } from "react"
import { GET, DELETE } from '../../../helpers/Queries'
import { useSelector, useDispatch } from 'react-redux'

export default function(props) {

  const dispatch = useDispatch()
  const account = useSelector(state => state.account)
  const logs = useSelector(state => state.integration_logs[props.integration.id])
  const [ filter, setFilter ] = useState('all')

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/integrations/${props.integration.id}/logs`).then((logs)=>{
      dispatch({ type: 'integration_logs/set', integration_id: props.integration.id, logs })
    })
  }, [])

  useEffect(()=>{
    let socket = new WebSocket(location.host.match(/^(localhost|127\.0\.0\.1)/) ? `ws://${window.location.host}/cable` : `wss://${window.location.host}/cable`)
    socket.onopen = async function(event) {
      const msg = {
        command: 'subscribe',
        identifier: JSON.stringify({
          integration_id: props.integration.id,
          channel: 'IntegrationLogsChannel'
        }),
      }
      socket.send(JSON.stringify(msg))
    }
    
    socket.onmessage = function(event) {
      const item = JSON.parse(event.data)
      if(item.type === "ping" || !item.message) { return }
      if(logs === undefined || logs.length === 0) { props.firstLogCallback() }
      dispatch({ type: 'integration_logs/add', integration_id: props.integration.id, log: { ...item.message, new: true } })
      setTimeout(()=>{
        dispatch({ type: 'integration_logs/update', integration_id: props.integration.id, log: item.message })
      }, 1000)
    }
    
    socket.onerror = function(error) {
      console.log('WebSocket Error: ', error)
    }
    return ()=>{ socket.close() }
  }, [])

  if(!props.integration || !logs) { return null }

  return(
    <div className="rounded bg-white d-block shadow-sm position-relative pb-1">
      <div className="row d-flex justify-content-between align-items-center">
        <div className="col-6 text-start p-2">
          <select
            className="form-select rounded-2 form-select-sm opacity-50"
            defaultValue={'all'}
            onChange={(event)=>{
              setFilter(event.target.value)
            }}
          >
            <option value="all">All</option>
            <option value="error">Errors</option>
            <option value="warn">Warnings</option>
            <option value="info">Info</option>
          </select>
        </div>
        <div className="col-6 text-end p-2">
          <div className="d-inline-block">
            <button
              onClick={()=>{ 
                let confirmed = confirm('Do you want to delete all logs?');
                if(confirmed) {
                  DELETE(`/api/accounts/${account.id}/integrations/${props.integration.id}/logs`)
                  dispatch({ type: 'integration_logs/set', integration_id: props.integration.id, logs: [] })
                }
              }}
              className='btn btn-sm btn-link p-1 me-2 hover-opacity-50 font-size-xs'
              title="Clear logs"
            >
              <i className="far fa-trash-alt font-size-xs"></i>
            </button>
            <button
              onClick={ ()=>{ 
                copy(
                  logs.filter((log)=>{
                    if(filter === 'all') {
                      return log
                    } else {
                      return log.type === filter
                    }
                  }).map((log)=>{
                    return `${log.type} ${log.created_at} ${log.text}`
                  }).join("\n")
                )
              }}
              className='btn btn-sm btn-action'
              title="Click to copy"
            >
              Copy All
              <i className="far fa-copy ms-2"></i>
            </button>
          </div>
        </div>
      </div>
      <div style={{ height: "180px", overflowY: 'auto' }}>
        {
          logs.filter((log)=>{
            if(filter === 'all') {
              return log
            } else {
              return log.type === filter
            }
          }).map((log, index)=>{
            return(
              <div 
                key={ index }
                title="Click to copy to clipboard"
                role="button"
                className={`px-2 m-0 user-select-none row log-line font-size-s ${log.new ? 'is-new' : ''}`}
                style={{ letterSpacing: '-1px' }}
                onClick={ ()=>{
                  copy(`${log.type} ${log.created_at} ${log.text}`)
                }}
              >
                <div className="col-8 text-truncate ps-1">
                  {
                    ['warn','error'].includes(log.type) &&
                    <span className="font-size-xs pe-1">
                      { log.type === 'warn' && <i className="fa-solid text-warning fa-triangle-exclamation"></i> }
                      { log.type === 'error' && <i className="fa-solid text-danger fa-triangle-exclamation"></i> }
                    </span>
                  }
                  <pre className="m-0 d-inline"><small>{ log.text }</small></pre>
                </div>
                <div className="col-4 text-truncate text-end pe-2">
                  <pre className="m-0 d-inline"><small>{ moment(log.created_at).fromNow() }</small></pre>
                </div>
              </div>
            )
          })
        }
      </div>
      <div className="text-end pe-2">
        <small className="opacity-50 font-size-xs pe-2" title="Logs will automatically be deleted after 7 days">7 days retention</small>
      </div>
    </div>
  )
}
