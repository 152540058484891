export default function AppsReducer(state = [], action) {
  let newState
  switch (action.type) {
    case 'apps/add':
      newState = (state).slice()
      newState.push(action.app)
      return newState
    case 'apps/set':
      return action.apps
    case 'apps/update':
      newState = (state).slice()
      let updatedIntegrationIndex = newState.findIndex((i)=>action.app.id==i.id)
      if(updatedIntegrationIndex > -1){
        newState[updatedIntegrationIndex] = action.app
      }
      return newState
    case 'apps/delete':
      newState = state.filter((link)=>{
        return link.id != action.id
      })
      return newState
    default:
      return state
  }
}
