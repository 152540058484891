import copy from 'copy-to-clipboard'
import React, { useState } from "react"
import TooltipDrawer from './TooltipDrawer'

export default function(props) {

  const [ showTooltip, setShowTooltip ] = useState()

  const onClickCopy = ()=>{
    setShowTooltip(true)
    copy(props.value)
  }

  return(
    <div className="position-relative d-inline-block">
      <div className='d-block w-100 position-relative'>
        <button type="button" onClick={ onClickCopy } className='btn btn-sm btn-action btn-copy' title="Click to copy">
          <div className="w-100 text-truncate" style={{maxWidth: "420px"}} >{ props.children || props.value }</div>
          <i className="far fa-copy right ms-2"></i>
        </button>
      </div>
      <TooltipDrawer show={showTooltip} set={setShowTooltip} text={"Copied to clipboard"}/>
    </div>
  )
}
