import React, { useState } from "react"
import { NavLink, useLocation } from "react-router-dom"

export default function(props) {

  const location = useLocation()
  const [submenuOpen, setSubmenuOpen] = useState(false)
  const toggleSubmenu = (submenuOpen)=>{
    setSubmenuOpen(!submenuOpen)
  }

  return(
    <div className="sticky-item submenu indent">

      <div className={`sticky-reset-padding position-relative subnavigation ${submenuOpen ? 'open' : null}`}>

        <NavLink to="/settings" className={(isActive)=>{
          let className = "nav-item small mb-1 mt-1"
          if(isActive && location.pathname === '/settings') {
            className += " active"
          }
          return className
        }}>
          <span className="icon">
            <i className="fas fa-solid fa-gear"></i>
          </span>
          <span className='ps-4 ms-2'>Account</span>
        </NavLink>

        <NavLink to="/settings/wallets" className="nav-item small mb-1 mt-1">
          <span className="icon">
            <i className="fas fa-solid fa-wallet"></i>
          </span>
          <span className='ps-4 ms-2'>Wallets</span>
        </NavLink>

        <NavLink to="/settings/tokens" className="nav-item small mb-1 mt-1">
          <span className="icon">
            <i className="fa-solid fa-coins"></i>
          </span>
          <span className='ps-4 ms-2'>Tokens</span>
        </NavLink>

        <NavLink to="/settings/billing" className="nav-item small mb-1 mt-1">
          <span className="icon">
            <i className="fas fa-solid fa-receipt"></i>
          </span>
          <span className='ps-4 ms-2'>Billing</span>
        </NavLink>

        <NavLink to="/settings/branding" className="nav-item small mb-1 mt-1">
          <span className="icon">
            <i className="fas fa-solid fa-palette"></i>
          </span>
          <span className='ps-4 ms-2'>Branding</span>
        </NavLink>

        <NavLink to="/settings/team" className="nav-item small mb-1 mt-1">
          <span className="icon">
            <i className="fas fa-solid fa-users"></i>
          </span>
          <span className='ps-4 ms-2'>Team</span>
        </NavLink>

        <div className="subnavigation-toggle" onClick={()=>toggleSubmenu(submenuOpen)}>
          <i className="fas fa-chevron-down ms-2 pr-1"></i>
        </div>

      </div>
    </div>
  )
}
