import Dropdown from '../../components/Dropdown'
import React, { useEffect, useState } from "react"
import AccountSettingsSidebar from '../../components/AccountSettingsSidebar'
import { GET, POST, DELETE } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useNavigate, NavLink } from "react-router-dom"
import { useSelector } from "react-redux"

export default function(props) {

  const account = useSelector(state => state.account)
  const accountUsers = useSelector(state => state.account_users)
  const users = useSelector(state => state.users)
  const navigate = useNavigate()

  const deleteUser = (accountUser)=>{

  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/account_users`).then((accountUsers)=>{
      Store.dispatch({ type: 'accountUsers/set', accountUsers })
    })
    GET(`/api/accounts/${account.id}/users`).then((users)=>{
      Store.dispatch({ type: 'users/set', users })
    })
  }, [])

  return(

    <div className="screen teams-screen row">

      <div className="col-12 col-md-3 sticky-reset">
        <AccountSettingsSidebar/>
      </div>

      <div className="col-12 col-md-7 col-xl-6">

        <div>
          <div className="card d-block px-0 pb-1">
            <div className="d-block px-3 pb-2">
              <NavLink to="/settings/team/invite" className="btn btn-action btn-sm" title="Invite more team members">
                <i className="fa-solid fa-plus me-2"></i>
                Invite
              </NavLink>
            </div>
            { accountUsers.map((accountUser, index)=>{
              return(
                <div key={index} className="pt-1">
                  <div className="circular-card-interactive p-2 py-3 p-lg-3">
                    <div className="d-flex align-items-center">
                      <div className="ps-2">
                        <div className="circular-icon me-1">
                          <span className="text-white">I</span>
                        </div>
                      </div>
                      <div className="flex-grow-1 line-height-extra-small px-3">
                        <div>
                          <span className="font-weight-medium">Name</span>
                        </div>
                        <div>
                          <small className="font-weight-medium opacity-50">{ accountUser.role }</small>
                        </div>
                      </div>
                      <div className="text-end">
                        <Dropdown
                          toggle={
                            <button className="btn btn-action-hover" type="button">
                              <i className="fas fa-ellipsis-h text-black-50"></i>
                            </button>
                          }
                          children={[
                            ['Edit', ()=>{ navigate(`/settings/wallets/${accountWallet.id}`) }, <i className="fa-solid fa-pen-to-square me-2"></i>],
                            (accountUser.user_id != account.owner_id ? ['Delete', ()=>{ deleteUser(accountUser) }, <i className="far fa-trash-alt me-2"></i>] : null)
                          ].filter(Boolean)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            }) }
            {
              accountUsers.length === 1 &&
              <div className="d-block w-100 text-center px-5 pb-4">
                <div className="">
                  <div className="pb-1 pt-1 text-center text-sm-start">
                    <div className="mascot mascot-team d-inline-block d-sm-block"></div>
                  </div>
                  <div className="font-size-l text-center text-sm-start text-lg-center">Invite some team members</div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
