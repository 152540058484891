import copy from 'copy-to-clipboard'
import ButtonLabel from '../../../helpers/ButtonLabel'
import React, { useState, useEffect } from "react"
import SyntaxHighlighter from 'react-syntax-highlighter'
import { stackoverflowLight } from 'react-syntax-highlighter/dist/esm/styles/hljs'

export default function(props) {

  const [ code, setCode ] = useState('')

  const getButtonLabel = (type)=>{
    switch (type) {
      case 'payment':
        return 'Pay'
      case 'donation':
        return 'Donate'
      case 'tip':
        return 'Tip'
    }
  }

  const getBlockchains = (integration)=>{
    if(integration?.configuration?.acceptedPayments) {
      return [...new Set(integration.configuration.acceptedPayments.map((payment)=>payment.token.blockchain))]
    }
    return []
  }

  const updateCode = ()=>{
    if(props.integration.form == 'widget') {
      setCode(`DePayWidgets.Payment({\n  integration: '${props.integration.external_id}'\n})`)
    } else if(props.integration.form == 'button') {
      setCode(`<div\n  class="DePayButton"\n  label="${getButtonLabel(props.integration.type)}"\n  integration="${props.integration.external_id}"\n  blockchains='${JSON.stringify(getBlockchains(props.integration))}'\n></div>\n<script src="https://integrate.depay.com/buttons/v12.js"></script>\n<noscript><a href="https://depay.com">Web3 Payments</a> are only supported with JavaScript enabled.</noscript>\n<script>DePayButtons.init({document: document});</script>`)
    }
  }
  useEffect(updateCode, [props.integration])

  return(
    <div className="rounded bg-white d-block shadow-sm position-relative">
      <div className="row d-flex justify-content-between align-items-center">
        <div className="col-6 text-start p-2">
          { props.integration.form == 'widget' &&
            <select className="form-select rounded-2 form-select-sm opacity-50" defaultValue={'JavaScript'}>
              <option value="JavaScript">JavaScript</option>
            </select>
          }
          { props.integration.form == 'button' &&
            <select className="form-select rounded-2 form-select-sm opacity-50" defaultValue={'HTML'}>
              <option value="HTML">HTML</option>
            </select>
          }
        </div>
        <div className="col-6 text-end p-2">
          <button onClick={ ()=>{ copy(code) } } className='btn btn-sm btn-action' title="Click to copy">
            Copy
            <i className="far fa-copy ms-2"></i>
          </button>
        </div>
      </div>
      { code != undefined &&
        <SyntaxHighlighter className="bg-transparent d-block" customStyle={{ paddingTop: '0.9rem', paddingBottom: '1.6rem', paddingLeft: '1.6rem', paddingRight: '1.6rem' }} language="javascript" style={ stackoverflowLight }>
          { code }
        </SyntaxHighlighter>
      }
    </div>
  )
}
