import AccountSettingsSidebar from '../../components/AccountSettingsSidebar'
import getInitials from "../../helpers/getInitials"
import React, { useState, useEffect, useRef } from "react"
import { GET, POST } from '../../helpers/Queries'
import { NavLink } from "react-router-dom"
import { Store } from '../../Store'
import { useDebounce } from 'use-debounce'
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const accountUsers = useSelector(state => state.account_users)
  const users = useSelector(state => state.users)
  const user = useSelector(state => state.user)
  const owner = useSelector(state => (state.users?.byId || {})[account?.owner_id])
  const [ accountName, setAccountName ] = useState(account ? account.name : undefined)
  const [ accountEmail, setAccountEmail ] = useState(account ? account.email : undefined)
  const [ editingAccountEmail, setEditingAccountEmail ] = useState(false)
  const [ verifyEmail, setVerifyEmail ] = useState(false)
  const accountEmailRef = useRef()

  useEffect(()=>{
    GET(`/api/accounts/${account.id}`).then((account)=>{
      setAccountEmail(account.email)
      setAccountName(account.name)
      Store.dispatch(({ type: 'account/change', account }))
    })
  }, [])

  useEffect(()=>{
    if(account) {
      GET(`/api/accounts/${account.id}/account_users`).then((accountUsers)=>{
        Store.dispatch({ type: 'accountUsers/set', accountUsers })
      })
      GET(`/api/accounts/${account.id}/users`).then((users)=>{
        Store.dispatch({ type: 'users/set', users })
      })
    }
  }, [account])

  const [ storeAccountName ] = useDebounce(accountName, 500)
  useEffect(()=>{
    POST(`/api/accounts/${account.id}`, { name: storeAccountName || null })
    Store.dispatch(({ type: 'account/change', account: {...account, name: storeAccountName } }))
  }, [storeAccountName])

  const handleAccountNameChange = (event) => {
    const name = event.target.value
    setAccountName(name)
  }

  const handleAccountEmailChange = (event) => {
    const email = event.target.value
    setAccountEmail(email)
  }

  const createVeficiation = (event)=>{
    if(accountEmail == undefined || !accountEmail.match('@') || !accountEmail.match('.')) {
      accountEmailRef.current.focus()
      return
    }
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    setEditingAccountEmail(false)
    setVerifyEmail(true)
    POST(`/api/accounts/${account.id}/email`, { address: accountEmail })
    return false
  }

  const createAndVerifyElement = (
    <button onClick={ createVeficiation } className='btn btn-action' title="Click to save and verify">
      <span>Save & Verify</span>
      <i className="far fa-envelope ms-2"></i>
    </button>
  )

  return(
    <div className="screen account-settings-screen row">

      <div className="col-12 col-md-3 sticky-reset">
        <AccountSettingsSidebar/>
      </div>

      <div className="col-12 col-md-9">

        <div className="col-lg-7">

          <div className="form-group pb-2 mb-2">
            <label htmlFor="accountName" className="pb-1">
              <h5>Display Name</h5>
            </label>
            <div className="pt-1 pb-1">
              <input value={ accountName || '' } onChange={ handleAccountNameChange } type="text" className="form-control" id="accountName" aria-describedby="accountNameHelp" placeholder="Your name, company or brand"></input>
            </div>
            <div className="px-2 pt-1">
              <small className="text-muted">
                <i className="fa-solid fa-globe me-2"/>
                Displayed to others.
              </small>
            </div>
          </div>

          <div className="form-group pb-2 pt-3 mb-2">
            <label htmlFor="accountEmail" className="pb-1">
              <h5>Email Address</h5>
            </label>
            <div className="pt-1 pb-1">
            { account.email == undefined &&
              <div>
                <div>
                  <form onSubmit={ createVeficiation }>
                    <input ref={ accountEmailRef } value={ accountEmail || '' } onChange={ handleAccountEmailChange } disabled={ verifyEmail } type="text" className="form-control" id="accountEmail" aria-describedby="accountNameHelp" placeholder="your@email.com"></input>
                  </form>
                </div>
                <div>
                  <div className="col-5 d-flex align-items-center mt-2">
                    { !verifyEmail && createAndVerifyElement }
                  </div>
                </div>
              </div>
            }
            {
              account.email != undefined &&
              <div>
                <div className="col-7">
                  <form onSubmit={ createVeficiation }>
                    <input ref={ accountEmailRef } value={ accountEmail } onChange={ handleAccountEmailChange } disabled={ !editingAccountEmail } type="text" className="form-control" id="accountEmail" aria-describedby="accountNameHelp" placeholder="your@email.com"></input>
                  </form>
                </div>
                <div>
                  <div className="d-flex align-items-center mt-2">
                    { !editingAccountEmail &&
                      <button onClick={ ()=>{ 
                        setEditingAccountEmail(true)
                        setAccountEmail('')
                        setTimeout(()=>{ accountEmailRef.current.focus() }, 200)
                      }}  className='btn btn-action' title="Click to save and verify">
                        <span>Change</span>
                        <i className="far fa-edit ms-2"></i>
                      </button>
                    }
                    { editingAccountEmail && createAndVerifyElement }
                  </div>
                </div>
              </div>
            }
            </div>
            { verifyEmail &&
              <div className="alert alert-info">
                <i className="fas fa-envelope me-2"></i>
                Email sent. Please check now.
              </div>
            }
            <div className="px-2 pt-1">
              <small className="text-muted ps-1 pt-1">
                <i className="fa-solid fa-lock me-2"/>
                Private. Used for sending account notifications.
              </small>
            </div>
          </div>

          <div className="form-group pb-2 pt-3 mb-2">
            <label className="pb-1">
              <h5>Ownership</h5>
            </label>
            <div className="pt-1 pb-1">
              <div className="rounded-2 bg-white mb-2 p-3">
                { owner &&
                  <div className="d-flex d-flex align-items-center">
                    <div className="ps-2">
                      { owner.image &&
                        <div className="circular-icon me-1" style={{ left: '2px', backgroundColor: 'rgba(0,0,0,0.3)', backgroundImage: `url(https://uploads.depay.com/${owner.image})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}/>
                      }

                      { !owner.image &&
                        <div className="circular-icon me-1">
                          { owner.name && <span className="text-white">{ getInitials(owner.name) }</span> }
                          { !owner.name && <i className="fa-regular fa-user text-white"></i> }
                        </div>
                      }
                    </div>
                    <div className="flex-grow-1 line-height-extra-small px-3">
                      <div>
                        <span className="font-weight-medium">{ owner.name || 'User' }</span>
                      </div>
                    </div>
                    <div className="text-end">
                      { user && owner && user?.id === owner?.id &&
                        <NavLink to="/settings/owner/transfer" className="btn btn-action">Transfer</NavLink>
                      }
                    </div>
                  </div>
                }
              </div>
              <div className="px-2 pt-1">
                <small className="text-muted ps-1">
                  <i className="fas fa-info-circle me-2"></i>
                  This account is currently owned by this user.
                </small>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
