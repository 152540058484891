import CopyButton from "../../components/CopyButton"
import React, { useState, useEffect } from "react"
import { GET, POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useSelector } from 'react-redux'

export default function(props) {

  const [ retrieving, setRetrieving ] = useState(false)
  const [ creating, setCreating ] = useState(false)
  const [ value, setValue ] = useState()
  const account = useSelector(state => state.account)
  const apiKeys = useSelector(state => state.api_keys)
  const activeSubscription = useSelector(state => state.subscription)

  const issueApiKey = ()=>{
    if(activeSubscription){
      setCreating(true)
      POST(`/api/accounts/${account.id}/api_keys`).then((api_key)=>{
        GET(`/api/accounts/${account.id}/api_keys`).then((api_keys)=>{
          setValue(api_key.key)
          Store.dispatch(({ type: 'api_keys/set', api_keys }))
        })
      })
    } else {
      Store.dispatch(({ type: 'paywall/show' }))
    }
  }

  const retrieveApiKey = ()=>{
    if(activeSubscription){
      setRetrieving(true)
      GET(`/api/accounts/${account.id}/api_keys/${apiKeys[0].id}`).then((api_key)=>{
        setValue(api_key.key)
      })
    } else {
      Store.dispatch(({ type: 'paywall/show' }))
    }
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/api_keys`).then((api_keys)=>{
      Store.dispatch(({ type: 'api_keys/set', api_keys }))
    })
  }, [account])

  if(creating) {
    if(value) {
      return(
        <div className="d-block">
          <CopyButton value={ value } />
        </div>
      )
    } else {
      return(
        <div className="d-block">
          <button className='btn btn-action btn-sm loading' disabled>
            <i className="fas fa-key me-2"></i>
            Issuing Api Key
            <span>
              <span className='dot'>.</span>
              <span className='dot'>.</span>
              <span className='dot'>.</span>
            </span>
          </button>
        </div>
      )
    }
  } else {
    if(apiKeys.length) {
      if(retrieving) {
        if(value) {
          return(
            <div className="d-block">
              <CopyButton value={ value } />
            </div>
          )   
        } else {
          return(
            <div className="d-block">
              <button className='btn btn-action btn-sm loading' disabled>
                <i className="fas fa-key me-2"></i>
                Retrieving Api Key
                <span>
                  <span className='dot'>.</span>
                  <span className='dot'>.</span>
                  <span className='dot'>.</span>
                </span>
              </button>
            </div>
          )
        }
      } else {
        return(
          <div className="d-block">
            <button onClick={ retrieveApiKey } className="btn btn-action btn-sm">
              <i className="fas fa-key me-2"></i>
              Show Api Key
            </button>
          </div>
        )
      }
    } else {
      return(
        <div className="d-flex align-items-center">
          <button onClick={ issueApiKey } className="btn btn-action btn-sm me-3" title="Issue API key">
            <i className="fas fa-key me-2"></i>
            Issue Api Key
          </button>
        </div>
      )
    }
  }
}
