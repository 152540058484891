import FileUploader from '../../components/FileUploader'
import React, { useState } from "react"
import { POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const [ creating, setCreating ] = useState(false)
  const [ name, setName ] = useState()
  const [ description, setDescription ] = useState()
  const [ link, setLink ] = useState()
  const [ logo, setLogo ] = useState()
  const navigate = useNavigate()

  const create = ()=> {
    if(creating != false) { return }
    if(
      (name == undefined || name.length == 0) ||
      (description == undefined || description.length == 0 || description.length > 140) ||
      (link == undefined || link.length == 0) ||
      (logo == undefined || logo.length == 0)
    ) { return }
    setCreating(true)
    POST(`/api/accounts/${account.id}/apps`, { name, description, link, logo }).then((app)=>{
      Store.dispatch(({ type: 'apps/add', app }))
      navigate(`/dev/apps/${app.id}`)
    }).catch(()=>{ setCreating(false) })
  }

  return(

    <div className="screen new-app-screen">

      <div className="row">

        <div className="col-lg-5">
          
          <form onSubmit={(event)=>{ create(); event.preventDefault(); }}>

            <div className="form-group pb-2">
              <div>
                <label htmlFor="appName" className="pb-1">
                  <h5>App Name</h5>
                </label>
              </div>
              <div className="pt-1 pb-1">
                <input 
                  required
                  autoFocus
                  type="text"
                  aria-describedby="appNameHelp"
                  placeholder="Give it a name..."
                  id="appName"
                  className="form-control font-size-s"
                  value={ name || '' }
                  disabled={ creating }
                  onChange={ (event)=> setName(event.target.value) } 
                />
              </div>
              <div className='line-height-extra-small pt-1'>
                <small id="appNameHelp" className="form-text text-muted">
                  Allows you and others to identify your app.
                </small>
              </div>
            </div>

            <div className="form-group pb-2">
              <div className="pt-3">
                <label htmlFor="appDescription" className="pb-1">
                  <h5>App Short Description</h5>
                </label>
              </div>
              <div className="pt-1 pb-1">
                <textarea 
                  required
                  placeholder="This app allows users to..."
                  id="appDescription"
                  className="form-control font-size-s"
                  rows={3}
                  value={ description || '' }
                  disabled={ creating }
                  style={{ resize: 'none' }}
                  maxLength="140"
                  onChange={ (event)=>setDescription(event.target.value) } 
                />
              </div>
              <div className='line-height-extra-small pt-1'>
                <small id="appDescriptionHelp" className="form-text text-muted">
                  max. 140 characters.
                </small>
              </div>
            </div>

            <div className="form-group pb-2">
              <div className="pt-3">
                <label htmlFor="appLink" className="pb-1">
                  <h5>App Link</h5>
                </label>
              </div>
              <div className="pt-1 pb-1">
                <input 
                  required
                  type="url"
                  aria-describedby="appLinkHelp"
                  placeholder="https://"
                  id="appLink"
                  className="form-control font-size-s"
                  value={ link || '' }
                  disabled={ creating }
                  onChange={ (event)=>setLink(event.target.value) } 
                />
              </div>
              <div className='line-height-extra-small pt-1'>
                <small id="appLinkHelp" className="form-text text-muted">
                  Allows users to find more information about the app.
                </small>
              </div>
            </div>

            <div className="form-group pb-2">
              <div className="pt-3">
                <label className="pb-1">
                  <h5>App Logo</h5>
                </label>
              </div>
              <div className="pb-1">
                <div className='pt-2 px-1 text-center'>
                  <div className='position-relative'>
                    <FileUploader
                      style={{ width: '160px', height: '160px' }}
                      previewStyle={{ width: '160px', height: '160px' }}
                      name="appLogo"
                      types={ ["jpg", "png", "gif"] } 
                      onUploadStart={ ()=>setLogo() }
                      onChange={ (key)=>{ setLogo(key) } }
                      preview={ true }
                    />
                  </div>
                  <input type='text' style={{ height: '1px', width: '1px', padding: 0, display: 'block', opacity: 0 }} required={ true } value={ logo || '' } onChange={()=>{}}/>
                </div>
              </div>
              <div className='line-height-extra-small pt-1'>
                <small id="appLogoHelp" className="form-text text-muted">
                  A square image: min. 600px and max. 1200px.
                </small>
              </div>
            </div>

            <div className="pt-4">
              {
                creating &&
                <button className='btn btn-primary loading' disabled>
                  Continue
                  <span>
                    <span className='dot'>.</span>
                    <span className='dot'>.</span>
                    <span className='dot'>.</span>
                  </span>
                </button>
              }
              {
                !creating &&
                <button className="btn btn-primary" onClick={ create }>
                  Continue
                </button>
              }
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
