import React, { useState } from "react"
import { NavLink, useLocation } from "react-router-dom"

export default function(props) {

  const location = useLocation()
  const [submenuOpen, setSubmenuOpen] = useState(false)
  const toggleSubmenu = (submenuOpen)=>{
    setSubmenuOpen(!submenuOpen)
  }

  return(
    <div className="sticky-item submenu">

      <div className={`sticky-reset-padding position-relative subnavigation ${submenuOpen ? 'open' : null}`}>
      
        <NavLink to="/user/settings" className={(isActive)=>{
          let className = "nav-item small mb-1 mt-1"
          if(isActive && location.pathname === '/user/settings') {
            className += " active"
          }
          return className
        }}>
          <span className="icon">
            <i className="fas fa-solid fa-gear"></i>
          </span>
          <span className='ps-4 ms-2'>User</span>
        </NavLink>

        <NavLink to="/user/authentication" className="nav-item small mb-1 mt-1">
          <span className="icon">
            <i className="fa-solid fa-fingerprint"></i>
          </span>
          <span className='ps-4 ms-2'>Authentication</span>
        </NavLink>

        <NavLink to="/user/accounts" className="nav-item small mb-1 mt-1">
          <span className="icon">
            <i className="fa-solid fa-circle"></i>
          </span>
          <span className='ps-4 ms-2'>Accounts</span>
        </NavLink>

        <div className="subnavigation-toggle" onClick={()=>toggleSubmenu(submenuOpen)}>
          <i className="fas fa-chevron-down ms-2 pr-1"></i>
        </div>

      </div>
    </div>
  )
}
