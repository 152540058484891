import moment from 'moment'

export default function(payments, parent){

  const format = 'YYYY-MM-DD'

  let groupedPayments = JSON.parse(JSON.stringify(parent))
  if(groupedPayments.byId === undefined) { groupedPayments.byId = {} }
  if(groupedPayments.byGroupId === undefined) { groupedPayments.byGroupId = {} }
  if(groupedPayments.groups === undefined) { groupedPayments.groups = [] }

  payments.forEach((payment)=>{
    groupedPayments.byId[payment.id] = payment
    const date = (new Date(payment.confirmed_at));
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const day = date.getDate();
    const key = year+"-"+month+"-"+day;
    if(groupedPayments.byGroupId[key] == undefined) {
      groupedPayments.byGroupId[key] = []
      if(groupedPayments.groups.length == 0 || moment(groupedPayments.groups[groupedPayments.groups.length-1], format).isBefore(moment(key, format))) {
        groupedPayments.groups.push(key)
      } else {
        const index = groupedPayments.groups.findIndex((group)=>moment(group, format).isAfter(moment(key, format)))
        if(index) {
          groupedPayments.groups.splice(index, 0, key)
        } else {
          groupedPayments.groups.push(key)
        }
      }
    }
    let paymentsInGroup = groupedPayments.byGroupId[key]
    if(paymentsInGroup.indexOf(payment.id) > -1) { return }
    if(paymentsInGroup.length == 0 || moment(groupedPayments.byId[paymentsInGroup[paymentsInGroup.length-1]].confirmed_at).isAfter(moment(payment.confirmed_at))) {
      paymentsInGroup.push(payment.id)
    } else {
      const index = paymentsInGroup.findIndex((existingPaymentId)=>{
        return moment(groupedPayments.byId[existingPaymentId].confirmed_at).isBefore(moment(payment.confirmed_at))
      })
      groupedPayments.byGroupId[key].splice(index, 0, payment.id)
    }
  })

  return groupedPayments
}
