import DePayWidgets from "@depay/widgets"
import Jazzicon from "jazzicon"
import React, { useEffect, useRef, useMemo } from "react"

export default function(props) {

  const ref = useRef()
  const size = props.size || 24

  const walletLogo = useMemo(()=>{
    return DePayWidgets.allWallets.find((wallet)=>wallet.name === props.wallet.wallet_type)?.logo
  }, [])

  useEffect(() => {
    if (props.wallet?.external_id && ref.current) {
      ref.current.innerHTML = ''
      ref.current.appendChild(Jazzicon(size*0.5, parseInt(props.wallet.external_id.slice(2, 10), 16)))
    }
  }, [props.wallet])

  return(

    <div className="position-relative d-inline-block d-flex align-items-center flex-column">
      {
        walletLogo &&
        <div className="wallet-type-icon" style={{ backgroundImage: `url(${walletLogo})`, backgroundSize: "cover", width: size*0.5, height: size*0.5, top: '-1px', position: 'relative' }}/>
      }
      <span style={{ width: size*0.5, height: size*0.5 }} className={["icon-wallet-identifier", props.className].join(' ')} ref={ref} />
    </div>

  )

}
