import React, { useState } from "react"
import SubscriptionButton from "../components/header/SubscriptionButton"
import { NavLink, useLocation } from "react-router-dom"

// <div className={['dropdown-extension', (moreNavigationOpen ? 'open' : 'closed')].join(' ')}>

//   <button type="button" className="nav-item dropdown-button position-relative rounded"
//     onClick={(event)=>{ 
//       if(moreNavigationOpen) {
//         setMoreNavigationOpen(false)
//       } else {
//         setMoreNavigationOpen(true)
//       }
//       event.stopPropagation()
//       return false
//     }}
//   >
//     <span className="icon">
//       <i className="fas fa-solid fa-ellipsis"></i>
//     </span>
//     <span className='ps-4 ms-2'>More</span>
//     <i className="fas fa-chevron-down ms-2 pr-1"></i>
//   </button>

//   <NavLink to="/plugins" className="nav-item dropdown-extension-target position-relative rounded w-100">
//     <span className="icon">
//       <i className="fas fa-plug"></i>
//     </span>
//     <span className='ps-4 ms-2'>Plugins</span>
//   </NavLink>
// </div>

export default function(props) {

  const location = useLocation()
  const [ developerNavigationOpen, setDeveloperNavigationOpen ] = useState(location.pathname.match(/^\/dev\//))
  const [ moreNavigationOpen, setMoreNavigationOpen ] = useState([/^\/plugins/].some((regexp)=>location.pathname.match(regexp)))

  return(
    <div className={"col navigation-sidebar sticky-wrapper " + (props.open ? "open" : "")}>
      
      <nav className="sticky-item mobile-sticky-overlay">

        <div className="ps-0 ps-lg-4 pe-lg-2 h-100">

          <div className="navigation-sidebar-inner pt-lg-5">

            <div className="pt-lg-2 pe-0 pe-lg-5 pt-5">

              <div className="font-size-l">

                <SubscriptionButton/>

                <NavLink to="/" className="nav-item position-relative rounded">
                  <img className='ms-lg-1 icon icon-overview' />
                  <span className='ps-4 ms-2 pe-1 pe-lg-0'>Overview</span>
                </NavLink>

                <NavLink to="/payments" className="nav-item position-relative rounded">
                  <img className='ms-lg-1 icon icon-payments' />
                  <span className='ps-4 ms-2'>Payments</span>
                </NavLink>

                <NavLink to="/links" className="nav-item position-relative rounded w-100">
                  <span className="icon">
                    <i className="fas fa-directions"></i>
                  </span>
                  <span className='ps-4 ms-2'>Links</span>
                </NavLink>

                <NavLink to="/requests" className="nav-item position-relative rounded">
                  <img className='ms-lg-1 icon icon-requests' />
                  <span className='ps-4 ms-2'>Requests</span>
                </NavLink>

                <NavLink to="/plugins" className="nav-item dropdown-extension-target position-relative rounded w-100">
                  <span className="icon">
                    <i className="fas fa-plug"></i>
                  </span>
                  <span className='ps-4 ms-2'>Plugins</span>
                </NavLink>

                <div className={['dropdown-extension', (developerNavigationOpen ? 'open' : 'closed')].join(' ')}>

                  <button type="button" className="nav-item dropdown-button position-relative rounded"
                    onClick={(event)=>{ 
                      if(developerNavigationOpen) {
                        setDeveloperNavigationOpen(false)
                      } else {
                        setDeveloperNavigationOpen(true)
                      }
                      event.stopPropagation()
                      return false
                    }}
                  >
                    <span className="icon">
                      <i className="fas fa-solid fa-laptop-code"></i>
                    </span>
                    <span className='ps-4 ms-2'>Developers</span>
                    <i className="fas fa-chevron-down ms-2 pr-1"></i>
                  </button>

                  <NavLink to="/dev/integrations" className="nav-item dropdown-extension-target position-relative rounded">
                    <img className='ms-lg-1 icon icon-integrations' />
                    <span className='ps-4 ms-2'>Integrations</span>
                  </NavLink>

                  <NavLink to="/dev/api" className="nav-item dropdown-extension-target position-relative rounded">
                    <span className="icon">
                      <i className="fas fa-satellite-dish"></i>
                    </span>
                    <span className='ps-4 ms-2'>API</span>
                  </NavLink>
                  
                  <NavLink to="/dev/apps" className="nav-item dropdown-extension-target position-relative rounded">
                    <span className="icon">
                      <i className="fas fa-solid fa-dice-d6"></i>
                    </span>
                    <span className='ps-4 ms-2'>Apps</span>
                  </NavLink>

                  <a href="https://depay.com/docs/" target="_blank" rel="noopener noreferrer" className="nav-item dropdown-extension-target position-relative rounded font-size-s">
                    <span className="icon">
                      <i className="fas fa-book"></i>
                    </span>
                    <span className='ps-4 ms-2'>Documentation</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}
