import Dropdown from '../../components/Dropdown'
import React, { useEffect, useState } from "react"
import AccountSettingsSidebar from '../../components/AccountSettingsSidebar'
import { POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

export default function(props) {

  const account = useSelector(state => state.account)
  const navigate = useNavigate()
  const [ name, setName ] = useState()
  const [ email, setEmail ] = useState()
  const [ error, setError ] = useState()
  const [ role, setRole ] = useState('admin')

  const sendInvite = ()=>{
    setError()
    POST(`/api/accounts/${account.id}/account_users`,{
      name,
      role,
      invited_via: email
    }).then(()=>{
      navigate('/settings/team')
    }).catch((response)=>{
      let responseData
      try { responseData = JSON.parse(response) } catch {}
      if(responseData?.errors === 'Invited via is not well formated') {
        setError('Provided email is invalid!')
      } else {
        setError(responseData?.errors || 'An error occured!')
      }
    })
  }

  return(

    <div className="screen invite-teams-screen row">

      <div className="col-12 col-md-3 sticky-reset">
        <AccountSettingsSidebar/>
      </div>

      <div className="col-12 col-md-9 col-xl-6">

        <h5 className="ps-1 pb-1 mb-1">Invite team member</h5>

        <form onSubmit={(e)=>{ e.preventDefault(); sendInvite(); return false }} className="bg-grey shadow rounded d-block px-3 pb-3">
          <div className="row pt-3 pb-1 mb-1">
            <label>
              <span className='d-block pb-1 opacity-50'>
                Name
              </span>
              <input required placeholder="Sam Smith" type="text" className="row bg-white rounded-1 mb-2 p-2 px-2 form-control" value={ name || '' }
                onChange={(event)=>{setName(event.target.value)}}
              />
            </label>
          </div>
          <div className="row pt-1 pb-1">
            <label>
              <span className='d-block pb-1 opacity-50'>
                E-Mail
              </span>
              <input required placeholder="name@example.com" type="text" className="row bg-white rounded-1 mb-2 p-2 px-2 form-control" value={ email || '' }
                onChange={(event)=>{setEmail(event.target.value)}}
              />
            </label>
          </div>
          <div className="row pt-1 pb-1">
            <label>
              <span className='d-block pb-1 opacity-50'>
                Role
              </span>
              <select className="form-select" onChange={(e)=>{ setRole(e.target.value) }} defaultValue={'admin'}>
                <option value="admin">Admin</option>
              </select>
            </label>
          </div>
          <div className="row pt-3">
            {
              error &&
              <div className="alert alert-danger py-2">
                { error }
              </div>
            }
            <button className="btn btn-primary btn-lg rounded-2 w-100" type="submit">
              <i className="fa-solid fa-envelope me-2"></i>
              Send Invite
            </button>
          </div>
        </form>

      </div>
    </div>
  )
}
