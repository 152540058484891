import FileUploader from '../../components/FileUploader'
import React, { useState, useEffect } from "react"
import AccountSettingsSidebar from '../../components/AccountSettingsSidebar'
import { GET, POST } from '../../helpers/Queries'
import { Store } from '../../Store'
import { useDebounce } from 'use-debounce'
import { useSelector } from 'react-redux'

export default function(props) {

  const account = useSelector(state => state.account)
  const [ color, setColor ] = useState(account.color)
  const [ logo, setLogo ] = useState(account.logo)
  const [ wordmark, setWordmark ] = useState(account.wordmark)
  const [ logoUrl, setLogoUrl ] = useState()
  const [ wordmarkUrl, setWordmarkUrl ] = useState()

  const [debouncedSave] = useDebounce([logo, wordmark, color], 500);

  useEffect(()=>{ 
    if(
      account.logo != logo ||
      account.wordmark != wordmark ||
      account.color != color
    ) {
      Store.dispatch({
        type: 'account/change',
        account: { ...account, logo, wordmark, color },
      })
      POST(`/api/accounts/${account.id}`, { logo, wordmark, color })
    }
  }, [debouncedSave])

  useEffect(()=>{
    if(account && logo) {
      GET(`/api/accounts/${account.id}/uploads/preview?key=${logo}`).then((presigned_url)=>{
        setLogoUrl(presigned_url)
      })
    }
  }, [account, logo])

  useEffect(()=>{
    if(account && wordmark) {
      GET(`/api/accounts/${account.id}/uploads/preview?key=${wordmark}`).then((presigned_url)=>{
        setWordmarkUrl(presigned_url)
      })
    }
  }, [account, wordmark])

  return(
    <div className="screen account-settings-screen row">

      <div className="col-12 col-md-3 sticky-reset">
        <AccountSettingsSidebar/>
      </div>

      <div className="col-12 col-md-9">

        <div className="col-lg-7">

          <div className="form-group pb-2 mb-2">
            <label htmlFor="accountName">
              <h5>Logo</h5>
            </label>
            <div className="pt-1 pb-2">
              <div className='pt-2 px-1 text-center col-5 position-relative d-block' title="Click to change">
                {
                  logoUrl &&
                  <div className="ratio ratio-1x1">
                    <div className="rounded-circle d-inline-block" style={{ width: '100%', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.3)', backgroundImage: `url(${logoUrl})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}/>
                  </div>
                }
                <FileUploader 
                  className="ratio ratio-1x1"
                  label="Click or drop an image, max. 1 MB, square, min. 400x400px"
                  name="accountLogo"
                  types={["jpg", "png", "gif"]}
                  style={(logo || logoUrl) ? { opacity: 0, position: 'absolute', top: 0 } : {}}
                  onUploadStart={()=>{
                    setLogoUrl('')
                  }}
                  onChange={(key)=>{ 
                    setLogo(key)
                    GET(`/api/accounts/${account.id}/uploads/preview?key=${key}`).then((presigned_url)=>{
                      setLogoUrl(presigned_url)
                    })
                  }}
                />
              </div>
            </div>
            <div className="px-2 pt-1">
              <small className="text-muted">
                <i className="fas fa-info-circle me-2"></i>
                Upload a square logo of your brand, which might be used in circular shapes, too. Ensure it contains NO text elements.
              </small>
            </div>
          </div>

          <div className="form-group pb-2 pt-3 mb-2">
            <label htmlFor="accountName">
              <h5>Wordmark</h5>
            </label>
            <div className="pt-1 pb-2">
              <div className='pt-2 px-1 text-center position-relative d-block' title="Click to change">
                {
                  wordmarkUrl &&
                  <div className="ratio ratio-21x9">
                    <div className="d-inline-block" style={{ width: '100%', position: 'absolute', backgroundImage: `url(${wordmarkUrl})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}/>
                  </div>
                }
                <FileUploader 
                  className="ratio ratio-21x9"
                  label="Click or drop an image, max. 1 MB, rectangular, min. 1400x600px"
                  name="accountWordmark"
                  style={(wordmark || wordmarkUrl) ? { opacity: 0, position: 'absolute', top: 0 } : {}}
                  types={["jpg", "png", "gif"]}
                  onUploadStart={()=>{
                    setWordmarkUrl('')
                  }}
                  onChange={(key)=>{ 
                    setWordmark(key)
                    GET(`/api/accounts/${account.id}/uploads/preview?key=${key}`).then((presigned_url)=>{
                      setWordmarkUrl(presigned_url)
                    })
                  }}
                />
              </div>
            </div>
            <div className="px-2 pt-1">
              <small className="text-muted">
                <i className="fas fa-info-circle me-2"></i>
                Upload a rectangular wordmark of your brand, either containing just your wordmark or your logo + wordmark.
              </small>
            </div>
          </div>

          <div className="form-group pb-2 pt-3 mb-2">
            <label htmlFor="accountName" className="pb-1">
              <h5>Color</h5>
            </label>
            <div className="pt-2 pb-2">
              <div className="ratio ratio-21x9">
                <input 
                  name='primary'
                  className="d-inline-block me-2 rounded-2 overflow-hidden shadow"
                  type="color"
                  value={ color }
                  onChange={ (event)=>{
                    setColor(event.target.value)
                  }}
                />
              </div>
            </div>
            <div className="px-2 pt-1">
              <small className="text-muted">
                <i className="fas fa-info-circle me-2"></i>
                Choose your main brand color used as "primary" color all user experiences.
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
