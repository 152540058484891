import React, { useEffect, useRef } from 'react'
import { Tooltip } from 'bootstrap'

export default function(props) {

  const tooltipRef = useRef()

  useEffect(() => {
    const tooltipElement = tooltipRef.current
    const tooltip = new Tooltip(tooltipElement, {
      title: props.title,
      placement: props.placement || 'top',
    })

    return () => {
      tooltip.dispose()
    }
  }, [props.title, props.placement])

  return (
    <span ref={tooltipRef}>
      { props.children }
    </span>
  )
  
}
