import CopyButton from "../../../components/CopyButton"
import React, { useState } from "react"
import { GET } from '../../../helpers/Queries'
import { Store } from '../../../Store'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

export default (props)=>{

  const { id } = useParams()
  const account = useSelector(state => state.account)
  const apps = useSelector(state => state.apps)
  const app = apps.find((app)=>app.id==id)
  const [ loadingAppSecret, setLoadingAppSecret ] = useState()
  const [ appSecret, setAppSecret ] = useState()

  const loadApiSecret = ()=>{
    setLoadingAppSecret(true)
    GET(`/api/accounts/${account.id}/apps/${app.id}/secret`).then((secret)=>{
      setAppSecret(secret.replaceAll(/\n/g,"\\n"))
    })
  }

  if(loadingAppSecret) {
    if(appSecret) {
      return(
        <div className="d-block">
          <CopyButton value={ appSecret } />
        </div>
      )   
    } else {
      return(
        <div className="d-block">
          <button className='btn btn-action btn-sm loading' disabled>
            <i className="fa-solid fa-fingerprint me-2"></i>
            Retrieving App Secret
            <span>
              <span className='dot'>.</span>
              <span className='dot'>.</span>
              <span className='dot'>.</span>
            </span>
          </button>
        </div>
      )
    }
  } else {
    return(
      <div className="d-block w-100">
        <button onClick={ loadApiSecret } className="btn btn-action btn-sm me-3">
          <i className="fa-solid fa-fingerprint me-2"></i>
          Show App Secret
        </button>
      </div>
    )
  }
}
