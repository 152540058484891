import React, { useEffect, useState } from "react"

export default function(props) {

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if(show) {
      let handleClick = (event)=>{
        setShow(false)
      }
      setTimeout(()=>{ 
        document.body.addEventListener('click', handleClick) 
      }, 100)
      return ()=>document.body.removeEventListener('click', handleClick)
    }
  }, [show])

  return(
    <div className='dropdown' onClick={(e)=>{
      e.preventDefault()
      e.stopPropagation()
      document.body.dispatchEvent( new MouseEvent("click", { view: window }) )
      setShow(!show)
      return false
    }}>
      { props.toggle }
      <ul className={["dropdown-menu", show ? 'show' : '', props.direction == 'left' ? 'left' : 'right'].join(' ')}>
        { props.children.map((child, index)=>{
          return(
            <li key={index} title={ child[0] }>
              <button type="button" className='dropdown-item' onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); setShow(false); child[1](); return false }}>
                <span className="me-2">{ child[2] }</span>
                { child[0] }
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
