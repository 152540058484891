import Dropdown from '../components/Dropdown'
import AddressEllipsis from "../helpers/AddressEllipsis"
import React, { useEffect } from "react"
import { GET, POST, DELETE } from '../helpers/Queries'
import { NavLink, useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'
import { Store } from '../Store'

export default function(props) {

  const account = useSelector(state => state.account)
  const integrations = useSelector(state => state.integrations)
  const navigate = useNavigate()

  const create = ({ type, form })=>{
    POST(`/api/accounts/${account.id}/integrations`, { type, form }).then((integration)=>{
      Store.dispatch(({ type: 'integrations/add', integration }))
      navigate(`/dev/integrations/${integration.id}/edit`)
    })
  }

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/integrations`).then((integrations)=>{
      Store.dispatch({ type: 'integrations/set', integrations })
    })
  }, [])

  const deleteIntegration = (integration)=> {
    let confirmation = confirm(`Are you sure you want to delete ${integration.name || 'this integration'}?`)
    if(confirmation) {
      Store.dispatch({ type: 'integrations/delete', id: integration.id })
      DELETE(`/api/accounts/${account.id}/integrations/${integration.id}`)
    }
  }

  let integrationListElement
  if(integrations && integrations.length) {
    integrationListElement = integrations.map((integration, index)=>{
      return(
        <div key={integration.id}>
          <NavLink to={`/dev/integrations/${integration.id}/overview`} className="circular-card-interactive p-2 py-3 p-lg-3">
            <div className="row d-flex align-items-center">
              <div className="col-10 line-height-extra-small px-3">
                <div>
                  <span className="font-weight-medium">{ integration.name }</span>
                </div>
                <div className="opacity-50 pt-1">
                  <small className="font-weight-medium">{ integration.type.charAt(0).toUpperCase() + integration.type.slice(1) } { integration.form.charAt(0).toUpperCase() + integration.form.slice(1) } </small>
                </div>
              </div>
              <div className="col-2 text-end">
                <Dropdown
                  toggle={
                    <button className="btn btn-action-hover rounded-3" type="button">
                      <i className="fas fa-ellipsis-h text-black-50"></i>
                    </button>
                  }
                  children={[
                    ['Overview', ()=>{ navigate(`/dev/integrations/${integration.id}/overview`) }, <i className="fa-solid fa-chart-line me-2"></i>],
                    ['Edit', ()=>{ navigate(`/dev/integrations/${integration.id}/edit`) }, <i className="fa-solid fa-pen-to-square me-2"></i>],
                    ['Delete', ()=>{ deleteIntegration(integration) }, <i className="far fa-trash-alt me-2"></i>]
                  ]}
                />
              </div>
            </div>
          </NavLink>
        </div>
      )
    })
  } else {
    integrationListElement = (
      <div className="d-block w-100 text-center px-4 pb-4 pt-2">
        <div className="">
          <div className="pt-1 text-center text-sm-start">
            <div className="mascot mascot-integrate d-inline-block d-sm-block"></div>
          </div>
          <div className="font-size-l text-center text-sm-start text-lg-center">Create your first integration</div>
          <div className="font-size-m pt-1 text-center text-sm-start text-lg-center opacity-50">and embed payments into your website/webapp</div>
        </div>
      </div>
    )
  }

  return(

    <div className="screen integrations-screen">

      <div className="row">
        <div className="col-12 col-xl-6">
          
          <div>
            <div className="d-flex pb-2">
              <Dropdown
                direction='left'
                toggle={
                  <button type="button" className="btn btn-action btn-sm" title="Create a new integration">
                    <i className="fa-solid fa-plus me-2"></i>
                    New Integration
                  </button>
                }
                children={[
                  ['Payment Widget', ()=>{ create({ form: 'widget', type: 'payment' }) }],
                  ['Payment Button', ()=>{ create({ form: 'button', type: 'payment' }) }],
                  ['Donation Widget', ()=>{ create({ form: 'widget', type: 'donation' }) }],
                  ['Donation Button', ()=>{ create({ form: 'button', type: 'donation' }) }],
                  ['Tip Widget', ()=>{ create({ form: 'widget', type: 'tip' }) }],
                  ['Tip Button', ()=>{ create({ form: 'button', type: 'tip' }) }],
                ]}
              />
            </div>
            <div className="card d-block p-0 py-1">
              { integrationListElement }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
