const ADDRESS_FORMATS = {
  "AD": { // Andorra
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "AE": { // United Arab Emirates
    format: ['address_line_1', 'address_line_2', 'emirate'],
    required: ['address_line_1', 'emirate'],
    suggestions: {
      emirate: [
        { label: 'أبو ظبي — Abu Dhabi', value: 'أبو ظبي' },
        { label: 'الشارقة — Sharjah', value: 'الشارقة' },
        { label: 'الفجيرة — Fujairah', value: 'الفجيرة' },
        { label: 'ام القيوين — Umm Al Quwain', value: 'ام القيوين' },
        { label: 'دبي — Dubai', value: 'دبي' },
        { label: 'رأس الخيمة — Ras al Khaimah', value: 'رأس الخيمة' },
        { label: 'عجمان — Ajman', value: 'عجمان' },
      ]
    },
  },
  "AF": { // Afghanistan
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "AG": { // Antigua and Barbuda
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1'],
  },
  "AI": { // Anguilla
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "AL": { // Albania
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "AM": { // Armenia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'province'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        { label: 'Արագածոտն — Aragatsotn', value: 'Արագածոտն' },
        { label: 'Արարատ — Ararat', value: 'Արարատ' },
        { label: 'Արմավիր — Armavir', value: 'Արմավիր' },
        { label: 'Գեղարքունիք — Gegharkunik', value: 'Գեղարքունիք' },
        { label: 'Երևան — Yerevan', value: 'Երևան' },
        { label: 'Լոռի — Lori', value: 'Լոռի' },
        { label: 'Կոտայք — Kotayk', value: 'Կոտայք' },
        { label: 'Շիրակ — Shirak', value: 'Շիրակ' },
        { label: 'Սյունիք — Syunik', value: 'Սյունիք' },
        { label: 'Վայոց ձոր — Vayots Dzor', value: 'Վայոց ձոր' },
        { label: 'Տավուշ — Tavush', value: 'Տավուշ' },
      ]
    },
  },
  "AO": { // Angola
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "AQ": { // Antarctica
    format: ['address_line_1', 'address_line_2', 'postal_code'],
    required: ['address_line_1'],
  },
  "AR": { // Argentina
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'province'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        'Buenos Aires',
        'Catamarca',
        'Chaco',
        'Chubut',
        'Ciudad Autónoma de Buenos Aires',
        'Córdoba',
        'Corrientes',
        'Entre Ríos',
        'Formosa',
        'Jujuy',
        'La Pampa',
        'La Rioja',
        'Mendoza',
        'Misiones',
        'Neuquén',
        'Río Negro',
        'Salta',
        'San Juan',
        'San Luis',
        'Santa Cruz',
        'Santa Fe',
        'Santiago del Estero',
        'Tierra del Fuego',
        'Tucumán'
      ]
    },
  },
  "AS": { // American Samoa
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "AT": { // Austria
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "AU": { // Australia
    format: ['address_line_1', 'address_line_2', 'city', 'state', 'postal_code'],
    required: ['address_line_1', 'city', 'state', 'postal_code'],
    suggestions: {
      state: [
        'Australian Capital Territory',
        'New South Wales',
        'Northern Territory',
        'Queensland',
        'South Australia',
        'Tasmania',
        'Victoria',
        'Western Australia'
      ]
    },
    labels: {
      address_line_1: 'Address',
      city: 'Suburb or city'
    }
  },
  "AW": { // Aruba
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "AX": { // Åland Islands
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "AZ": { // Azerbaijan
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "BA": { // Bosnia and Herzegovina
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "BB": { // Barbados
    format: ['address_line_1', 'address_line_2', 'city', 'parish', 'postal_code'],
    required: ['address_line_1', 'city'],
    suggestions: {
      parish: [
        "Christ Church",
        "Saint Andrew",
        "Saint George",
        "Saint James",
        "Saint John",
        "Saint Joseph",
        "Saint Lucy",
        "Saint Michael",
        "Saint Peter",
        "Saint Philip",
        "Saint Thomas",
      ]
    },
  },
  "BD": { // Bangladesh
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "BE": { // Belgium
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
    labels: {
      address_line_1: 'Address',
    }
  },
  "BF": { // Burkina Faso
    format: ['address_line_1', 'address_line_2', 'city', 'cedex'],
    required: ['address_line_1', 'city'],
  },
  "BG": { // Bulgaria
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "BH": { // Bahrain
    format: ['address_line_1', 'address_line_2', 'city', 'postal_code'],
    required: ['address_line_1', 'city'],
  },
  "BI": { // Burundi
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "BJ": { // Benin
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "BL": { // Saint Barthélemy
    format: ['address_line_1', 'address_line_2', 'postal_code', 'city', 'cedex'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "BM": { // Bermuda
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "BN": {  // Brunei
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "BO": { // Bolivia
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "BQ": { // Bonaire, Sint Eustatius and Saba (Caribbean Netherlands)
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "BR": { // Brazil
    format: ['address_line_1', 'address_line_2', 'suburb', 'city', 'neighborhood', 'postal_code'],
    required: ['address_line_1', 'city', 'neighborhood', 'postal_code'],
    suggestions: {
      neighborhood: [
        'Acre',
        'Alagoas',
        'Amapá',
        'Amazonas',
        'Bahia',
        'Ceará',
        'Distrito Federal',
        'Espírito Santo',
        'Goiás',
        'Maranhão',
        'Mato Grosso',
        'Mato Grosso do Sul',
        'Minas Gerais',
        'Pará',
        'Paraíba',
        'Paraná',
        'Pernambuco',
        'Piauí',
        'Rio de Janeiro',
        'Rio Grande do Norte',
        'Rio Grande do Sul',
        'Rondônia',
        'Roraima',
        'Santa Catarina',
        'São Paulo',
        'Sergipe',
        'Tocantins',
      ]
    },
    labels: {
      address_line_1: 'Address',
    }
  },
  "BS": { // Bahamas
    format: ['address_line_1', 'address_line_2', 'city', 'island'],
    required: ['address_line_1', 'city'],
    suggestions: {
      island: [
        'Abaco Islands',
        'Acklins',
        'Andros Island',
        'Berry Islands',
        'Bimini',
        'Cat Island',
        'Crooked Island',
        'Eleuthera',
        'Exuma and Cays',
        'Grand Bahama',
        'Harbour Island',
        'Inagua',
        'Long Island',
        'Mayaguana',
        'New Providence',
        'Ragged Island',
        'Rum Cay',
        'San Salvador',
        'Spanish Wells',
      ]
    },
  },
  "BT": { // Bhutan
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  }, 
  "BV": { // Bouvet Island
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "BW": { // Botswana
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "BY": { // Belarus
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'oblast'],
    required: ['address_line_1', 'city'],
    suggestions: {
      oblast: [
        "Brestskaya oblast'",
        "Gomel'skaya oblast'",
        "Grodnenskaya oblast'",
        "Mogilevskaya oblast'",
        "Minskaya oblast'",
        "Vitebskaya oblast'",
        "Gorod Minsk"
      ]
    },
  },
  "BZ": { // Belize
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "CA": {  // Canada (GST)
    format: ['address_line_1', 'address_line_2', 'city', 'province', 'postal_code'],
    required: ['address_line_1', 'city', 'province', 'postal_code'],
    suggestions: {
      province: [
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland and Labrador",
        "Northwest Territories",
        "Nova Scotia",
        "Nunavut",
        "Ontario",
        "Prince Edward Island",
        "Quebec",
        "Saskatchewan",
        "Yukon",
      ]
    },
    labels: {
      address_line_1: 'Address',
    }
  },
  "CC": { // Cocos (Keeling) Islands
    format: ['address_line_1', 'address_line_2', 'island', 'postal_code'],
    required: ['address_line_1', 'island', 'postal_code'],
  },
  "CD": { // Democratic Republic of the Congo
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "CF": { // Central African Republic
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "CG": { // Republic of the Congo
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "CH": {  // Switzerland
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "CI": { // Ivory Coast (Côte d'Ivoire)
    format: ['cedex', 'address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "CK": { // Cook Islands
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "CL": { // Chile
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'province'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        "Antofagasta",
        "Araucanía",
        "Arica y Parinacota",
        "Atacama",
        "Aysén del General Carlos Ibáñez del Campo",
        "Biobío",
        "Coquimbo",
        "Libertador General Bernardo O'Higgins",
        "Los Lagos",
        "Los Ríos",
        "Magallanes y de la Antártica Chilena",
        "Maule",
        "Metropolitana de Santiago",
        "Ñuble",
        "Tarapacá",
        "Valparaíso",
      ]
    },
  },
  "CM": { // Cameroon
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "CN": { // China
    format: ['postal_code', 'province', 'city', 'district', 'address_line_1', 'address_line_2'],
    required: ['address_line_1', 'city', 'postal_code', 'province'],
    suggestions: {
      province: [
        { label: "安徽省 — Anhui Sheng", value: "安徽省" },
        { label: "澳门 — Macau", value:"澳门" },
        { label: "北京市 — Beijing Shi", value: "北京市" },
        { label: "重庆市 — Chongqing Shi", value: "重庆市" },
        { label: "福建省 — Fujian Sheng", value: "福建省" },
        { label: "甘肃省 — Gansu Sheng", value: "甘肃省" },
        { label: "广东省 — Guangdong Sheng", value: "广东省" },
        { label: "广西 — Guangxi Zhuangzuzizhiqu", value: "广西" },
        { label: "贵州省 — Guizhou Sheng", value: "贵州省" },
        { label: "海南省 — Hainan Sheng", value: "海南省" },
        { label: "河北省 — Hebei Sheng", value: "河北省" },
        { label: "河南省 — Henan Sheng", value: "河南省" },
        { label: "黑龙江省 — Heilongjiang Sheng", value: "黑龙江省" },
        { label: "湖北省 — Hubei Sheng", value: "湖北省" },
        { label: "湖南省 — Hunan Sheng", value: "湖南省" },
        { label: "吉林省 — Jilin Sheng", value: "吉林省" },
        { label: "江苏省 — Jiangsu Sheng", value: "江苏省" },
        { label: "江西省 — Jiangxi Sheng", value: "江西省" },
        { label: "辽宁省 — Liaoning Sheng", value: "辽宁省" },
        { label: "内蒙古 — Neimenggu Zizhiqu", value: "内蒙古" },
        { label: "宁夏 — Ningxia Huizuzizhiqu", value: "宁夏" },
        { label: "青海省 — Qinghai Sheng", value: "青海省" },
        { label: "山东省 — Shandong Sheng", value: "山东省" },
        { label: "山西省 — Shanxi Sheng", value: "山西省" },
        { label: "陕西省 — Shaanxi Sheng", value: "陕西省" },
        { label: "上海市 — Shanghai Shi", value: "上海市" },
        { label: "四川省 — Sichuan Sheng", value: "四川省" },
        { label: "台湾 — Taiwan", value: "台湾" },
        { label: "天津市 — Tianjin Shi", value: "天津市" },
        { label: "西藏 — Xizang Zizhiqu", value: "西藏" },
        { label: "香港 — Hong Kong", value: "香港" },
        { label: "新疆 — Xinjiang Weiwuerzizhiqu", value: "新疆" },
        { label: "云南省 — Yunnan Sheng", value: "云南省" },
        { label: "浙江省 — Zhejiang Sheng</option>", value: "浙江省" },
      ]
    }
  },
  "CO": { // Colombia
    format: ['address_line_1', 'address_line_2', 'suburb', 'city', 'department', 'postal_code'],
    required: ['address_line_1', 'department'],
    suggestions: {
      department: [
        "Amazonas",
        "Antioquia",
        "Arauca",
        "Atlántico",
        "Bolívar",
        "Boyacá",
        "Caldas",
        "Caquetá",
        "Casanare",
        "Cauca",
        "Cesar",
        "Córdoba",
        "Cundinamarca",
        "Chocó",
        "Guainía",
        "Guaviare",
        "Huila",
        "La Guajira",
        "Magdalena",
        "Meta",
        "Nariño",
        "Norte de Santander",
        "Putumayo",
        "Quindío",
        "Risaralda",
        "San Andrés, Providencia y Santa Catalina",
        "Santander",
        "Sucre",
        "Tolima",
        "Valle del Cauca",
        "Vaupés",
        "Vichada",
      ]
    }
  },
  "CR": { // Costa Rica
    format: ['address_line_1', 'address_line_2', 'province', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'province'],
    suggestions: {
      province: [
        "Alajuela",
        "Cartago",
        "Guanacaste",
        "Heredia",
        "Limón",
        "Puntarenas",
        "San José",
      ]
    }
  },
  "CU": { // Cuba
    format: ['address_line_1', 'address_line_2', 'postal_code', 'province'],
    required: ['address_line_1', 'postal_code', 'province'],
    suggestions: {
      province: [
        "Pinar del Río",
        "Artemisa",
        "La Habana",
        "Mayabeque",
        "Matanzas",
        "Cienfuegos",
        "Villa Clara",
        "Sancti Spíritus",
        "Ciego de Ávila",
        "Camagüey",
        "Las Tunas",
        "Holguín",
        "Granma",
        "Santiago de Cuba",
        "Guantánamo",
        "Isla de la Juventud",
      ]
    }
  },
  "CV": { // Cape Verde
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'island'],
    required: ['address_line_1', 'city'],
    suggestions: {
      island: [
        "Boa Vista",
        "Brava",
        "Fogo",
        "Maio",
        "Sal",
        "Santiago",
        "Santo Antão",
        "São Nicolau",
        "São Vicente",
      ]
    }
  },
  "CW": {  // Curaçao
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "CX": {  // Christmas Island
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "CY": {  // Cyprus
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "CZ": {  // Czech Republic
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "DE": {  // Germany
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
    labels: {
      address_line_1: 'Address',
    }
  },
  "DJ": {  // Djibouti
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "DK": {  // Denmark
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "DM": { // Dominica
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "DO": {  // Dominican Republic
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "DZ": {  // Algeria
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "EC": {  // Ecuador
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "EE": {  // Estonia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'province'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "EG": {  // Egypt
    format: ['address_line_1', 'address_line_2', 'city', 'province', 'postal_code'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        { label: "أسوان — Aswan Governorate", value: "أسوان" },
        { label: "أسيوط — Asyut Governorate", value: "أسيوط" },
        { label: "الإسكندرية — Alexandria Governorate", value: "الإسكندرية" },
        { label: "الإسماعيلية — Ismailia Governorate", value: "الإسماعيلية" },
        { label: "الأقصر — Luxor Governorate", value: "الأقصر" },
        { label: "البحر الأحمر — Red Sea Governorate", value: "البحر الأحمر" },
        { label: "البحيرة — El Beheira Governorate", value: "البحيرة" },
        { label: "الجيزة — Giza Governorate", value: "الجيزة" },
        { label: "الدقهلية — Dakahlia Governorate", value: "الدقهلية" },
        { label: "السويس — Suez Governorate", value: "السويس" },
        { label: "الشرقية — Ash Sharqia Governorate", value: "الشرقية" },
        { label: "الغربية — Gharbia Governorate", value: "الغربية" },
        { label: "الفيوم — Faiyum Governorate", value: "الفيوم" },
        { label: "القاهرة — Cairo Governorate", value: "القاهرة" },
        { label: "القليوبية — Qalyubia Governorate", value: "القليوبية" },
        { label: "المنوفية — Menofia Governorate", value: "المنوفية" },
        { label: "المنيا — Menia Governorate", value: "المنيا" },
        { label: "الوادي الجديد — New Valley Governorate", value: "الوادي الجديد" },
        { label: "بني سويف — Beni Suef Governorate", value: "بني سويف" },
        { label: "بورسعيد — Port Said Governorate", value: "بورسعيد" },
        { label: "جنوب سيناء — South Sinai Governorate", value: "جنوب سيناء" },
        { label: "دمياط — Damietta Governorate", value: "دمياط" },
        { label: "سوهاج — Sohag Governorate", value: "سوهاج" },
        { label: "شمال سيناء — North Sinai Governorate", value: "شمال سيناء" },
        { label: "قنا — Qena Governorate", value: "قنا" },
        { label: "كفر الشيخ — Kafr El Sheikh Governorate", value: "كفر الشيخ" },
        { label: "مطروح — Matrouh Governorate", value: "مطروح" },
      ]
    }
  },
  "EH": {  // Western Sahara
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "ER": {  // Eritrea
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "ES": {  // Spain
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'province'],
    required: ['address_line_1', 'city', 'postal_code', 'province'],
    suggestions: {
      province: [
        "Álava",
        "Albacete",
        "Alicante",
        "Almería",
        "Asturias",
        "Ávila",
        "Badajoz",
        "Barcelona",
        "Burgos",
        "Cáceres",
        "Cádiz",
        "Cantabria",
        "Castellón",
        "Ceuta",
        "Ciudad Real",
        "Córdoba",
        "Cuenca",
        "Girona",
        "Granada",
        "Guadalajara",
        "Guipúzcoa",
        "Huelva",
        "Huesca",
        "Islas Baleares",
        "Jaén",
        "La Coruña",
        "La Rioja",
        "Las Palmas",
        "León",
        "Lérida",
        "Lugo",
        "Madrid",
        "Málaga",
        "Melilla",
        "Murcia",
        "Navarra",
        "Ourense",
        "Palencia",
        "Pontevedra",
        "Salamanca",
        "Santa Cruz de Tenerife",
        "Segovia",
        "Sevilla",
        "Soria",
        "Tarragona",
        "Teruel",
        "Toledo",
        "Valencia",
        "Valladolid",
        "Vizcaya",
        "Zamora",
        "Zaragoza",
      ]
    }
  },
  "ET": {  // Ethiopia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "FI": {  // Finland
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'postal_code', 'city'],
  },
  "FJ": {   // Fiji
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "FK": {  // Falkland Islands
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "FM": {  // Micronesia
    format: ['address_line_1', 'address_line_2', 'postal_code'],
    required: ['address_line_1', 'postal_code'],
  },
  "FO": { // Faroe Islands
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "FR": {  // France
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'postal_code', 'city'],
    labels: {
      address_line_1: 'Address',
    }
  },
  "GA": {  // Gabon
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "GB": {  // United Kingdom
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
    labels: {
      city: 'Town or city',
    }
  },
  "GD": { // Grenada
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "GE": {  // Georgia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "GF": {  // French Guiana
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'cedex'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "GG": {  // Guernsey
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "GH": {  // Ghana
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "GI": {  // Gibraltar
    format: ['address_line_1', 'address_line_2', 'postal_code'],
    required: ['address_line_1'],
  },
  "GL": {  // Greenland
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "GM": {  // Gambia
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "GN": {  // Guinea
    format: ['postal_code', 'address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "GP": {  // Guadeloupe
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'cedex'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "GQ": {  // Equatorial Guinea
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "GR": {  // Greece
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "GS": {  // South Georgia and the South Sandwich Islands
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "GT": {  // Guatemala
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "GU": {  // Guam
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
    labels: {
      postal_code: 'ZIP'
    }
  },
  "GW": {  // Guinea-Bissau
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "GY": {  // Guyana
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "HK": {   // Hong Kong (SAR China)
    format: ['area', 'district', 'address_line_1', 'address_line_2'],
    required: ['address_line_1', 'city', 'area'],
    suggestions: {
      area: [
        "九龍 — Kowloon",
        "香港島 — Hong Kong",
        "新界 — New Territories",
      ]
    }
  },
  "HM": {  // Heard Island and McDonald Islands
    format: ['address_line_1', 'address_line_2', 'postal_code'],
    required: ['address_line_1', 'postal_code'],
  },
  "HN": {  // Honduras
    format: ['address_line_1', 'address_line_2', 'city', 'department', 'postal_code'],
    required: ['address_line_1', 'city', 'department'],
    suggestions: {
      department: [
        "Atlántida",
        "Choluteca",
        "Colón",
        "Comayagua",
        "Copán",
        "Cortés",
        "El Paraíso",
        "Francisco Morazán",
        "Gracias a Dios",
        "Intibucá",
        "Islas de la Bahía",
        "La Paz",
        "Lempira",
        "Ocotepeque",
        "Olancho",
        "Santa Bárbara",
        "Valle",
        "Yoro",
      ]
    }
  },
  "HR": {  // Croatia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "HT": { // Haiti
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "HU": {  // Hungary
    format: ['city', 'address_line_1', 'address_line_2', 'postal_code'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "ID": {  // Indonesia
    format: ['address_line_1', 'address_line_2', 'city', 'province', 'postal_code'],
    required: ['address_line_1', 'province'],
    suggestions: {
      province: [
        { label: "Aceh", value: "Aceh" },
        { label: "Bali", value: "Bali" },
        { label: "Banten", value: "Banten" },
        { label: "Bengkulu", value: "Bengkulu" },
        { label: "Daerah Istimewa Yogyakarta — Special Region of Yogyakarta", value: "Daerah Istimewa Yogyakarta" },
        { label: "DKI Jakarta", value: "DKI Jakarta" },
        { label: "Jambi", value: "Jambi" },
        { label: "Jawa Barat — West Java", value: "Jawa Barat" },
        { label: "Jawa Tengah — Central Java", value: "Jawa Tengah" },
        { label: "Jawa Timur — East Java", value: "Jawa Timur" },
        { label: "Kalimantan Barat — West Kalimantan", value: "Kalimantan Barat" },
        { label: "Kalimantan Selatan — South Kalimantan", value: "Kalimantan Selatan" },
        { label: "Kalimantan Tengah — Central Kalimantan", value: "Kalimantan Tengah" },
        { label: "Kalimantan Timur — East Kalimantan", value: "Kalimantan Timur" },
        { label: "Kalimantan Utara — North Kalimantan", value: "Kalimantan Utara" },
        { label: "Kepulauan Bangka Belitung — Bangka Belitung Islands", value: "Kepulauan Bangka Belitung" },
        { label: "Kepulauan Riau — Riau Islands", value: "Kepulauan Riau" },
        { label: "Lampung", value: "Lampung" },
        { label: "Maluku", value: "Maluku" },
        { label: "Maluku Utara — North Maluku", value: "Maluku Utara" },
        { label: "Nusa Tenggara Barat — West Nusa Tenggara", value: "Nusa Tenggara Barat" },
        { label: "Nusa Tenggara Timur — East Nusa Tenggara", value: "Nusa Tenggara Timur" },
        { label: "Papua Barat — West Papua", value: "Papua Barat" },
        { label: "Riau", value: "Riau" },
        { label: "Sulawesi Barat — West Sulawesi", value: "Sulawesi Barat" },
        { label: "Sulawesi Selatan — South Sulawesi", value: "Sulawesi Selatan" },
        { label: "Sulawesi Tengah — Central Sulawesi", value: "Sulawesi Tengah" },
        { label: "Sulawesi Tenggara — South East Sulawesi", value: "Sulawesi Tenggara" },
        { label: "Sulawesi Utara — North Sulawesi", value: "Sulawesi Utara" },
        { label: "Sumatera Barat — West Sumatra", value: "Sumatera Barat" },
        { label: "Sumatera Selatan — South Sumatra", value: "Sumatera Selatan" },
        { label: "Sumatera Utara — North Sumatra", value: "Sumatera Utara" },
      ]
    }
  },
  "IE": {  // Ireland
    format: ['address_line_1', 'address_line_2', 'townland', 'city', 'county', 'postal_code'],
    required: ['address_line_1', 'city'],
    suggestions: {
      county: [
        "Carlow",
        "Cavan",
        "Clare",
        "Cork",
        "Donegal",
        "Dublin",
        "Galway",
        "Kerry",
        "Kildare",
        "Kilkenny",
        "Laois",
        "Leitrim",
        "Limerick",
        "Longford",
        "Louth",
        "Mayo",
        "Meath",
        "Monaghan",
        "Offaly",
        "Roscommon",
        "Sligo",
        "Tipperary",
        "Waterford",
        "Westmeath",
        "Wexford",
        "Wicklow",
      ]
    },
    labels: {
      postal_code: 'Eircode'
    }
  },
  "IL": {  // Israel
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "IM": { // Isle of Man
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "IN": {  // India (GST)
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code'], 'state'],
    required: ['address_line_1', 'city', 'postal_code', 'state'],
    suggestions: {
      state: [
        "Andaman & Nicobar",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra & Nagar Haveli & Daman & Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu & Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
      ]
    },
    labels: {
      postal_code: 'PIN'
    }
  },
  "IO": {  // British Indian Ocean Territory
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "IQ": {  // Iraq
    format: ['address_line_1', 'address_line_2', 'city', 'province', 'postal_code'],
    required: ['address_line_1', 'city', 'province'],
    suggestions: {
      province: [
        "Al Anbar Governorate",
        "Basra Governorate",
        "Muthanna Governorate",
        "Al-Qadisiyah Governorate",
        "Najaf Governorate",
        "Erbil Governorate",
        "Sulaymaniyah Governorate",
        "Babil Governorate",
        "Baghdad Governorate",
        "Dohuk Governorate",
        "Dhi Qar Governorate",
        "Diyala Governorate",
        "Karbala Governorate",
        "Kirkuk Governorate",
        "Maysan Governorate",
        "Nineveh Governorate",
        "Saladin Governorate",
        "Wasit Governorate",
      ]
    }
  },
  "IR": {   // Iran
    format: ['address_line_1', 'address_line_2', 'city', 'province', 'postal_code'],
    required: ['address_line_1', 'province', 'postal_code'],
    suggestions: {
      province: [
        "Alborz",
        "Ardabil",
        "Bushehr",
        "Chaharmahal and Bakhtiari",
        "East Azerbaijan",
        "Esfahan",
        "Fars",
        "Gilan",
        "Golestan",
        "Hamadan",
        "Hormozgan",
        "Ilam",
        "Kerman",
        "Kermanshah",
        "Khuzestan",
        "Kohgiluyeh and Boyer-Ahmad",
        "Kurdistan",
        "Lorestan",
        "Markazi",
        "Mazandaran",
        "North Khorasan",
        "Qazvin",
        "Qom",
        "Razavi Khorasan",
        "Semnan",
        "Sistan and Baluchestan",
        "South Khorasan",
        "Tehran",
        "West Azerbaijan",
        "Yazd",
      ]
    }
  },
  "IS": {  // Iceland
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "IT": {  // Italy
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'province'],
    required: ['address_line_1', 'city', 'postal_code', 'province'],
    labels: {
      address_line_1: 'Address'
    },
    suggestions: {
      province: [
        "Agrigento",
        "Alessandria",
        "Ancona",
        "Aosta",
        "Arezzo",
        "Ascoli Piceno",
        "Asti",
        "Avellino",
        "Bari",
        "Barletta-Andria-Trani",
        "Belluno",
        "Benevento",
        "Bergamo",
        "Biella",
        "Bologna",
        "Bolzano",
        "Brescia",
        "Brindisi",
        "Cagliari",
        "Caltanissetta",
        "Campobasso",
        "Caserta",
        "Catania",
        "Catanzaro",
        "Chieti",
        "Como",
        "Cosenza",
        "Cremona",
        "Crotone",
        "Cuneo",
        "Enna",
        "Fermo",
        "Ferrara",
        "Firenze",
        "Foggia",
        "Forlì-Cesena",
        "Frosinone",
        "Genova",
        "Gorizia",
        "Grosseto",
        "Imperia",
        "Isernia",
        "L'Aquila",
        "La Spezia",
        "Latina",
        "Lecce",
        "Lecco",
        "Livorno",
        "Lodi",
        "Lucca",
        "Macerata",
        "Mantova",
        "Massa-Carrara",
        "Matera",
        "Messina",
        "Milano",
        "Modena",
        "Monza e Brianza",
        "Napoli",
        "Novara",
        "Nuoro",
        "Oristano",
        "Padova",
        "Palermo",
        "Parma",
        "Pavia",
        "Perugia",
        "Pesaro e Urbino",
        "Pescara",
        "Piacenza",
        "Pisa",
        "Pistoia",
        "Pordenone",
        "Potenza",
        "Prato",
        "Ragusa",
        "Ravenna",
        "Reggio Calabria",
        "Reggio Emilia",
        "Rieti",
        "Rimini",
        "Roma",
        "Rovigo",
        "Salerno",
        "Sassari",
        "Savona",
        "Siena",
        "Siracusa",
        "Sondrio",
        "Sud Sardegna",
        "Taranto",
        "Teramo",
        "Terni",
        "Torino",
        "Trapani",
        "Trento",
        "Treviso",
        "Trieste",
        "Udine",
        "Varese",
        "Venezia",
        "Verbano-Cusio-Ossola",
        "Vercelli",
        "Verona",
        "Vibo Valentia",
        "Vicenza",
        "Viterbo",
      ]
    }
  },
  "JE": {  // Jersey
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "JM": {  // Jamaica
    format: ['address_line_1', 'address_line_2', 'city', 'parish', 'cedex'],
    required: ['address_line_1', 'city', 'parish'],
    suggestions: {
      parish: [
        "Clarendon",
        "Hanover",
        "Kingston",
        "Manchester",
        "Portland",
        "St. Andrew",
        "St. Ann",
        "St. Catherine",
        "St. Elizabeth",
        "St. James",
        "St. Mary",
        "St. Thomas",
        "Trelawny",
        "Westmoreland",
      ]
    },
  },
  "JO": {  // Jordan
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "JP": {  // Japan
    format: ['postal_code', 'prefecture', 'address_line_1', 'address_line_2'],
    required: ['address_line_1', 'city', 'prefecture'],
    suggestions: {
      prefecture: [
        { label: "北海道 — Hokkaido", value: "北海道" },
        { label: "青森県 — Aomori", value: "青森県" },
        { label: "岩手県 — Iwate", value: "岩手県" },
        { label: "宮城県 — Miyagi", value: "宮城県" },
        { label: "秋田県 — Akita", value: "秋田県" },
        { label: "山形県 — Yamagata", value: "山形県" },
        { label: "福島県 — Fukushima", value: "福島県" },
        { label: "茨城県 — Ibaraki", value: "茨城県" },
        { label: "栃木県 — Tochigi", value: "栃木県" },
        { label: "群馬県 — Gunma", value: "群馬県" },
        { label: "埼玉県 — Saitama", value: "埼玉県" },
        { label: "千葉県 — Chiba", value: "千葉県" },
        { label: "東京都 — Tokyo", value: "東京都" },
        { label: "神奈川県 — Kanagawa", value: "神奈川県" },
        { label: "新潟県 — Niigata", value: "新潟県" },
        { label: "富山県 — Toyama", value: "富山県" },
        { label: "石川県 — Ishikawa", value: "石川県" },
        { label: "福井県 — Fukui", value: "福井県" },
        { label: "山梨県 — Yamanashi", value: "山梨県" },
        { label: "長野県 — Nagano", value: "長野県" },
        { label: "岐阜県 — Gifu", value: "岐阜県" },
        { label: "静岡県 — Shizuoka", value: "静岡県" },
        { label: "愛知県 — Aichi", value: "愛知県" },
        { label: "三重県 — Mie", value: "三重県" },
        { label: "滋賀県 — Shiga", value: "滋賀県" },
        { label: "京都府 — Kyoto", value: "京都府" },
        { label: "大阪府 — Osaka", value: "大阪府" },
        { label: "兵庫県 — Hyogo", value: "兵庫県" },
        { label: "奈良県 — Nara", value: "奈良県" },
        { label: "和歌山県 — Wakayama", value: "和歌山県" },
        { label: "鳥取県 — Tottori", value: "鳥取県" },
        { label: "島根県 — Shimane", value: "島根県" },
        { label: "岡山県 — Okayama", value: "岡山県" },
        { label: "広島県 — Hiroshima", value: "広島県" },
        { label: "山口県 — Yamaguchi", value: "山口県" },
        { label: "徳島県 — Tokushima", value: "徳島県" },
        { label: "香川県 — Kagawa", value: "香川県" },
        { label: "愛媛県 — Ehime", value: "愛媛県" },
        { label: "高知県 — Kochi", value: "高知県" },
        { label: "福岡県 — Fukuoka", value: "福岡県" },
        { label: "佐賀県 — Saga", value: "佐賀県" },
        { label: "熊本県 — Kumamoto", value: "熊本県" },
        { label: "大分県 — Oita", value: "大分県" },
        { label: "宮崎県 — Miyazaki", value: "宮崎県" },
        { label: "鹿児島県 — Kagoshima", value: "鹿児島県" },
        { label: "沖縄県 — Okinawa", value: "沖縄県" },
      ]
    }
  },
  "KE": {  // Kenya
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "KG": {  // Kyrgyzstan
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "KH": {  // Cambodia
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "KI": { // Kiribati
    format: ['address_line_1', 'address_line_2', 'island', 'city'],
    required: ['address_line_1', 'city'],
    suggestions: {
      island: [
        "Gilbert Islands",
        "Line Islands",
        "Phoenix Islands",
      ]
    }
  },
  "KM": {  // Comoros
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "KN": { // Saint Kitts and Nevis
    format: ['address_line_1', 'address_line_2', 'city', 'island'],
    required: ['address_line_1', 'city', 'island'],
    suggestions: {
      island: [
        "Nevis",
        "St. Kitts",
      ]
    }
  },
  "KP": {  // North Korea
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "KR": {  // South Korea (Korea)
    format: ['province', 'city', 'district', 'address_line_1', 'address_line_2', 'postal_code'],
    required: ['address_line_1', 'city', 'postal_code', 'province'],
    suggestions: {
      province: [
        { label: "강원 — Gangwon-do", value: "강원" },
        { label: "경기 — Gyeonggi-do", value: "경기" },
        { label: "경남 — Gyeongsangnam-do", value: "경남" },
        { label: "경북 — Gyeongsangbuk-do", value: "경북" },
        { label: "광주 — Gwangju", value: "광주" },
        { label: "대구 — Daegu", value: "대구" },
        { label: "대전 — Daejeon", value: "대전" },
        { label: "부산 — Busan", value: "부산" },
        { label: "서울 — Seoul", value: "서울" },
        { label: "세종 — Sejong", value: "세종" },
        { label: "울산 — Ulsan", value: "울산" },
        { label: "인천 — Incheon", value: "인천" },
        { label: "전남 — Jeollanam-do", value: "전남" },
        { label: "전북 — Jeollabuk-do", value: "전북" },
        { label: "제주 — Jeju-do", value: "제주" },
        { label: "충남 — Chungcheongnam-do", value: "충남" },
        { label: "충북 — Chungcheongbuk-do", value: "충북" },
      ]
    },
    labels: {
      province: 'Do Si'
    }
  },
  "KW": {   // Kuwait
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "KY": {  // Cayman Islands
    format: ['address_line_1', 'address_line_2', 'postal_code', 'island', 'postal_code'],
    required: ['address_line_1', 'island'],
    suggestions: {
      island: [
        "Cayman Brac",
        "Grand Cayman",
        "Little Cayman",
      ]
    }
  },
  "KZ": {  // Kazakhstan
    format: ['postal_code', 'province', 'city', 'address_line_1', 'address_line_2'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        "Almaty",
        "Astana",
        "Almatinskaya oblast'",
        "Akmolinskaya oblast'",
        "Aktyubinskaya oblast'",
        "Atyrauskaya oblast'",
        "Baykonyr",
        "Zapadno-Kazakhstanskaya oblast'",
        "Mangistauskaya oblast'",
        "Yuzhno-Kazakhstankaya oblast'",
        "Pavlodarskaya oblast'",
        "Karagandinskaya oblast'",
        "Kostanayskaya oblast'",
        "Kyzylordinskaya oblast'",
        "Vostochno-Kazakhstanskaya oblast'",
        "Severo-Kazakhstanskaya oblast'",
        "Zhambylskaya oblast'",
      ]
    }
  },
   "XK": {  // Kosovo
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city']
  },
  "LA": {  // Laos
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "LB": {  // Lebanon
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "LC": { // Saint Lucia
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "LI": {   // Liechtenstein
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "LK": {   // Sri Lanka
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "LR": {  // Liberia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "LS": {  // Lesotho
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "LT": {  // Lithuania
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'province'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "LU": {  // Luxembourg
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "LV": {  // Latvia
    format: ['address_line_1', 'address_line_2', 'province', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "LY": {  // Libya
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "MA": {  // Morocco
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "MC": { // Monaco
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'cedex'],
    required: ['address_line_1', 'city'],
  },
  "MD": {  // Moldova
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "ME": {  // Montenegro
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "MF": {  // Saint Martin (French part)
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'cedex'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "MG": {  // Madagascar
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "MH": {  // Marshall Islands
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "MK": { // North Macedonia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "ML": {  // Mali
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "MM": {   // Myanmar
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "MN": {  // Mongolia
    format: ['address_line_1', 'address_line_2', 'city', 'province', 'postal_code'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        "Ulaanbaatar",
        "Arkhangai Province",
        "Bayankhongor Province",
        "Bayan-Ölgii Province",
        "Bulgan Province",
        "Darkhan-Uul Province",
        "Dornod Province",
        "Dornogovi Province",
        "Dundgovi Province",
        "Zavkhan Province",
        "Govi-Altai Province",
        "Govisümber Province",
        "Khentii Province",
        "Khovd Province",
        "Khövsgöl Province",
        "Ömnögovi Province",
        "Orkhon Province",
        "Övörkhangai Province",
        "Selenge Province",
        "Sükhbaatar Province",
        "Töv Province",
        "Uvs Province",
      ]
    }
  },
  "MO": {   // Macao (SAR China)
    format: ['address_line_1', 'address_line_2'],
    required: ['address_line_1'],
  },
  "MP": {  // Northern Mariana Islands
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "MQ": {  // Martinique
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'cedex'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "MR": {  // Mauritania
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "MS": {  // Montserrat
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "MT": {  // Malta
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "MU": {  // Mauritius
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "MV": {   // Maldives
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "MW": {  // Malawi
    format: ['address_line_1', 'address_line_2', 'city', 'cedex'],
    required: ['address_line_1', 'city'],
  },
  "MX": {  // Mexico
    format: ['address_line_1', 'address_line_2', 'neighborhood', ['postal_code', 'city'], 'state'],
    required: ['address_line_1', 'city', 'postal_code', 'state'],
    suggestions: {
      state: [
        "Aguascalientes",
        "Baja California",
        "Baja California Sur",
        "Campeche",
        "Chiapas",
        "Chihuahua",
        "Ciudad de México",
        "Coahuila de Zaragoza",
        "Colima",
        "Durango",
        "Estado de México",
        "Guanajuato",
        "Guerrero",
        "Hidalgo",
        "Jalisco",
        "Michoacán",
        "Morelos",
        "Nayarit",
        "Nuevo León",
        "Oaxaca",
        "Puebla",
        "Querétaro",
        "Quintana Roo",
        "San Luis Potosí",
        "Sinaloa",
        "Sonora",
        "Tabasco",
        "Tamaulipas",
        "Tlaxcala",
        "Tlaxcala",
        "Veracruz",
        "Yucatán",
        "Zacatecas",
      ]
    }
  },
  "MY": {   // Malaysia
    format: ['address_line_1', 'address_line_2', 'suburb', ['postal_code', 'city'], 'state'],
    required: ['address_line_1', 'city', 'postal_code'],
    suggestions: {
      state: [
        "Johor",
        "Kedah",
        "Kelantan",
        "Kuala Lumpur",
        "Labuan",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Perak",
        "Perlis",
        "Pulau Pinang",
        "Putrajaya",
        "Sabah",
        "Sarawak",
        "Selangor",
        "Terengganu",
      ]
    },
    labels: {
      suburb: 'Village or township'
    }
  },
  "MZ": {  // Mozambique
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'province'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        "Cabo Delgado",
        "Cidade de Maputo",
        "Gaza",
        "Inhambane",
        "Manica",
        "Maputo",
        "Nampula",
        "Niassa",
        "Sofala",
        "Tete",
        "Zambezia",
      ]
    }
  },
  "NA": {  // Namibia
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "NC": { // New Caledonia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'cedex'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "NE": {  // Niger
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "NF": {  // Norfolk Island
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "NG": {   // Nigeria
    format: ['address_line_1', 'address_line_2', 'suburb', ['city', 'postal_code'], 'state'],
    required: ['address_line_1', 'city'],
    suggestions: {
      state: [
        "Abia",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "Federal Capital Territory",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nasarawa",
        "Niger",
        "Ogun State",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara",
      ]
    }
  },
  "NI": {  // Nicaragua
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'department'],
    required: ['address_line_1', 'city'],
    suggestions: {
      department: [
        "Boaco",
        "Carazo",
        "Chinandega",
        "Chontales",
        "Estelí",
        "Granada",
        "Jinotega",
        "León",
        "Madriz",
        "Managua",
        "Masaya",
        "Matagalpa",
        "Nueva Segovia",
        "Región Autónoma de la Costa Caribe Norte",
        "Región Autónoma de la Costa Caribe Sur",
        "Río San Juan",
        "Rivas",
      ]
    }
  },
  "NL": {  // Netherlands
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "NO": {  // Norway
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
    labels: {
      city: 'Town or city',
    }
  },
  "NP": {  // Nepal
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "NR": {  // Nauru
    format: ['address_line_1', 'address_line_2', 'district'],
    required: ['address_line_1', 'district'],
    suggestions: {
      district: [
        "Aiwo District",
        "Anabar District",
        "Anetan District",
        "Anibare District",
        "Baiti District",
        "Boe District",
        "Buada District",
        "Denigomodu District",
        "Ewa District",
        "Ijuw District",
        "Meneng District",
        "Nibok District",
        "Uaboe District",
        "Yaren District",
      ]
    }
  },
  "NU": { // Niue
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "NZ": {  // New Zealand
    format: ['address_line_1', 'address_line_2', 'suburb', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "OM": {   // Oman
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "PA": {   // Panama
    format: ['address_line_1', 'address_line_2', 'city', 'province'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        "Bocas del Toro",
        "Chiriquí",
        "Coclé",
        "Colón",
        "Darién",
        "Herrera",
        "Los Santos",
        "Panamá",
        "Veraguas",
        "Panamá Oeste",
        "Emberá",
        "Guna Yala",
        "Ngäbe-Buglé",
      ]
    }
  },
  "PE": {  // Peru
    format: ['address_line_1', 'address_line_2', ['district', 'postal_code'], 'province'],
    required: ['address_line_1', 'district'],
    suggestions: {
      province: [
        "Municipalidad Metropolitana de Lima",
        "Amazonas",
        "Ancash",
        "Apurímac",
        "Arequipa",
        "Ayacucho",
        "Cajamarca",
        "Cusco",
        "El Callao",
        "Huancavelica",
        "Huánuco",
        "Ica",
        "Junín",
        "La Libertad",
        "Lambayeque",
        "Lima",
        "Loreto",
        "Madre de Dios",
        "Moquegua",
        "Pasco",
        "Piura",
        "Puno",
        "San Martín",
        "Tacna",
        "Tumbes",
        "Ucayali",
      ]
    }
  },
  "PF": { // French Polynesia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'island'],
    required: ['address_line_1', 'city', 'postal_code', 'island'],
    suggestions: {
      island: [
        "Windward Islands",
        "Austral Islands",
        "Tuamotu-Gambier",
        "Leeward Islands",
        "Marquesas Islands"
      ]
    }
  },
  "PG": {  // Papua New Guinea
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code'], 'province'],
    required: ['address_line_1', 'city', 'province'],
    suggestions: {
      province: [
        "National Capital District",
        "Central",
        "Chimbu",
        "Eastern Highlands",
        "East New Britain",
        "East Sepik",
        "Enga",
        "Gulf",
        "Hela",
        "Jiwaka",
        "Madang",
        "Manus",
        "Milne Bay",
        "Morobe",
        "New Ireland",
        "Northern",
        "West Sepik",
        "Southern Highlands",
        "Western",
        "Western Highlands",
        "West New Britain",
        "Bougainville",
      ]
    }
  },
  "PH": {  // Philippines
    format: ['address_line_1', 'address_line_2', 'suburb', ['city', 'postal_code'], 'province'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        "Abra",
        "Agusan del Norte",
        "Agusan del Sur",
        "Aklan",
        "Albay",
        "Antique",
        "Apayao",
        "Aurora",
        "Basilan",
        "Bataan",
        "Batanes",
        "Batangas",
        "Benguet",
        "Biliran",
        "Bohol",
        "Bukidnon",
        "Bulacan",
        "Cagayan",
        "Camarines Norte",
        "Camarines Sur",
        "Camiguin",
        "Capiz",
        "Catanduanes",
        "Cavite",
        "Cebu",
        "Compostela Valley",
        "Cotabato",
        "Davao del Norte",
        "Davao del Sur",
        "Davao Occidental",
        "Davao Oriental",
        "Dinagat Islands",
        "Eastern Samar",
        "Guimaras",
        "Ifugao",
        "Ilocos Norte",
        "Ilocos Sur",
        "Iloilo",
        "Isabela",
        "Kalinga",
        "La Union",
        "Laguna",
        "Lanao del Norte",
        "Lanao del Sur",
        "Leyte",
        "Maguindanao",
        "Marinduque",
        "Masbate",
        "Metro Manila",
        "Mindoro Occidental",
        "Mindoro Oriental",
        "Misamis Occidental",
        "Misamis Oriental",
        "Mountain Province",
        "Negros Occidental",
        "Negros Oriental",
        "Northern Samar",
        "Nueva Ecija",
        "Nueva Vizcaya",
        "Palawan",
        "Pampanga",
        "Pangasinan",
        "Quezon Province",
        "Quirino",
        "Rizal",
        "Romblon",
        "Samar",
        "Sarangani",
        "Siquijor",
        "Sorsogon",
        "South Cotabato",
        "Southern Leyte",
        "Sultan Kudarat",
        "Sulu",
        "Surigao del Norte",
        "Surigao del Sur",
        "Tarlac",
        "Tawi-Tawi",
        "Zambales",
        "Zamboanga del Norte",
        "Zamboanga del Sur",
        "Zamboanga Sibuguey",
      ]
    }
  },
  "PK": {  // Pakistan
    format: ['address_line_1', 'address_line_2', 'suburb', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "PL": {  // Poland
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "PM": {  // Saint Pierre and Miquelon
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'cedex'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "PN": {  // Pitcairn Islands
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "PR": { // Puerto Rico
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
    labels: {
      postal_code: 'ZIP'
    }
  },
  "PS": { // Palestine
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "PT": {  // Portugal
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "PW": {  // Palau
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "PY": {  // Paraguay
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "QA": {   // Qatar
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "RE": {  // Réunion
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'cedex'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "RO": {  // Romania
    format: ['address_line_1', 'address_line_2', 'postal_code', 'province', 'city'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "RS": {  // Serbia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "RU": {  // Russia
    format: ['address_line_1', 'address_line_2', 'city', 'oblast', 'postal_code'],
    required: ['address_line_1', 'city', 'postal_code', 'oblast'],
    suggestions: {
      oblast: [
        { label: "Алтайский край — Altayskiy kray", value: "Алтайский край" },
        { label: "Амурская область — Amurskaya oblast'", value: "Амурская область" },
        { label: "Архангельская область — Arkhangelskaya oblast'", value: "Архангельская область" },
        { label: "Астраханская область — Astrakhanskaya oblast'", value: "Астраханская область" },
        { label: "Белгородская область — Belgorodskaya oblast'", value: "Белгородская область" },
        { label: "Брянская область — Bryanskaya oblast'", value: "Брянская область" },
        { label: "Владимирская область — Vladimirskaya oblast'", value: "Владимирская область" },
        { label: "Волгоградская область — Volgogradskaya oblast'", value: "Волгоградская область" },
        { label: "Вологодская область — Vologodskaya oblast'", value: "Вологодская область" },
        { label: "Воронежская область — Voronezhskaya oblast'", value: "Воронежская область" },
        { label: "Еврейская автономная область — Evreyskaya avtonomnaya oblast'", value: "Еврейская автономная область" },
        { label: "Забайкальский край — Zabaykalskiy kray", value: "Забайкальский край" },
        { label: "Ивановская область — Ivanovskaya oblast'", value: "Ивановская область" },
        { label: "Иркутская область — Irkutskaya oblast'", value: "Иркутская область" },
        { label: "Кабардино-Балкарская Республика — Kabardino-Balkarskaya Republits", value: "Кабардино-Балкарская Республика" },
        { label: "Калининградская область — Kaliningradskaya oblast'", value: "Калининградская область" },
        { label: "Калужская область — Kaluzhskaya oblast'", value: "Калужская область" },
        { label: "Камчатский край — Kamchatskiy kray", value: "Камчатский край" },
        { label: "Карачаево-Черкесская Республика — Karachaevo-Cherkesskaya Republits", value: "Карачаево-Черкесская Республика" },
        { label: "Кемеровская область — Kemerovskaya oblast'", value: "Кемеровская область" },
        { label: "Кировская область — Kirovskaya oblast'", value: "Кировская область" },
        { label: "Костромская область — Kostromskaya oblast'", value: "Костромская область" },
        { label: "Краснодарский край — Krasnodarskiy kray", value: "Краснодарский край" },
        { label: "Красноярский край — Krasnoyarskiy kray", value: "Красноярский край" },
        { label: "Курганская область — Kurganskaya oblast'", value: "Курганская область" },
        { label: "Курская область — Kurskaya oblast'", value: "Курская область" },
        { label: "Ленинградская область — Leningradskaya oblast'", value: "Ленинградская область" },
        { label: "Липецкая область — Lipetskaya oblast'", value: "Липецкая область" },
        { label: "Магаданская область — Magadanskaya oblast'", value: "Магаданская область" },
        { label: "Москва — Moskva", value: "Москва" },
        { label: "Московская область — Moskovskaya oblast'", value: "Московская область" },
        { label: "Мурманская область — Murmanskaya oblast'", value: "Мурманская область" },
        { label: "Ненецкий автономный округ — Nenetskiy", value: "Ненецкий автономный округ" },
        { label: "Нижегородская область — Nizhegorodskaya oblast'", value: "Нижегородская область" },
        { label: "Новгородская область — Novgorodskaya oblast'", value: "Новгородская область" },
        { label: "Новосибирская область — Novosibirskaya oblast'", value: "Новосибирская область" },
        { label: "Омская область — Omskaya oblast'", value: "Омская область" },
        { label: "Оренбургская область — Orenburgskaya oblast'", value: "Оренбургская область" },
        { label: "Орловская область — Orlovskaya oblast'", value: "Орловская область" },
        { label: "Пензенская область — Penzenskaya oblast'", value: "Пензенская область" },
        { label: "Пермский край — Permskiy kray", value: "Пермский край" },
        { label: "Приморский край — Primorskiy kray", value: "Приморский край" },
        { label: "Псковская область — Pskovskaya oblast'", value: "Псковская область" },
        { label: "Республика Адыгея — Respublika Adygeya", value: "Республика Адыгея" },
        { label: "Республика Алтай — Altay Republits", value: "Республика Алтай" },
        { label: "Республика Башкортостан — Bashkortostan Republits", value: "Республика Башкортостан" },
        { label: "Республика Бурятия — Buryatiya Republits", value: "Республика Бурятия" },
        { label: "Республика Дагестан — Dagestan Republits", value: "Республика Дагестан" },
        { label: "Республика Ингушетия — Ingushetiya Republits", value: "Республика Ингушетия" },
        { label: "Республика Калмыкия — Respublika Kalmykiya", value: "Республика Калмыкия" },
        { label: "Республика Карелия — Kareliya Republits", value: "Республика Карелия" },
        { label: "Республика Коми — Komi Republits", value: "Республика Коми" },
        { label: "Республика Марий Эл — Respublika Mariy El", value: "Республика Марий Эл" },
        { label: "Республика Мордовия — Respublika Mordoviya", value: "Республика Мордовия" },
        { label: "Республика Саха (Якутия) — Sakha (Yakutiya) Republits", value: "Республика Саха (Якутия)" },
        { label: "Республика Северная Осетия-Алания — Respublika Severnaya Osetiya-Alaniya", value: "Республика Северная Осетия-Алания" },
        { label: "Республика Татарстан — Respublika Tatarstan", value: "Республика Татарстан" },
        { label: "Республика Тыва — Tyva Republits", value: "Республика Тыва" },
        { label: "Республика Удмуртия — Udmurtskaja Respublika", value: "Республика Удмуртия" },
        { label: "Республика Хакасия — Khakasiya Republits", value: "Республика Хакасия" },
        { label: "Ростовская область — Rostovskaya oblast'", value: "Ростовская область" },
        { label: "Рязанская область — Ryazanskaya oblast'", value: "Рязанская область" },
        { label: "Самарская область — Samarskaya oblast'", value: "Самарская область" },
        { label: "Санкт-Петербург — Sankt-Peterburg", value: "Санкт-Петербург" },
        { label: "Саратовская область — Saratovskaya oblast'", value: "Саратовская область" },
        { label: "Сахалинская область — Sakhalinskaya oblast'", value: "Сахалинская область" },
        { label: "Свердловская область — Sverdlovskaya oblast'", value: "Свердловская область" },
        { label: "Смоленская область — Smolenskaya oblast'", value: "Смоленская область" },
        { label: "Ставропольский край — Stavropolskiy kray", value: "Ставропольский край" },
        { label: "Тамбовская область — Tambovskaya oblast'", value: "Тамбовская область" },
        { label: "Тверская область — Tverskaya oblast'", value: "Тверская область" },
        { label: "Томская область — Tomskaya oblast'", value: "Томская область" },
        { label: "Тульская область — Tulskaya oblast'", value: "Тульская область" },
        { label: "Тюменская область — Tyumenskaya oblast'", value: "Тюменская область" },
        { label: "Ульяновская область — Ulyanovskaya oblast'", value: "Ульяновская область" },
        { label: "Хабаровский край — Khabarovskiy kray", value: "Хабаровский край" },
        { label: "Ханты-Мансийский автономный округ — Khanty-Mansiyskiy avtonomnyy okrug", value: "Ханты-Мансийский автономный округ" },
        { label: "Челябинская область — Chelyabinskaya oblast'", value: "Челябинская область" },
        { label: "Чеченская Республика — Chechenskaya Republits", value: "Чеченская Республика" },
        { label: "Чувашская Республика — Chuvashia", value: "Чувашская Республика" },
        { label: "Чукотский автономный округ — Chukotskiy", value: "Чукотский автономный округ" },
        { label: "Ямало-Ненецкий автономный округ — Yamalo-Nenetskiy", value: "Ямало-Ненецкий автономный округ" },
        { label: "Ярославская область — Yaroslavskaya oblast'", value: "Ярославская область" },
      ]
    }
  },
  "RW": {  // Rwanda
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "SA": {  // Saudi Arabia
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "SB": { // Solomon Islands
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "SC": {  // Seychelles
    format: ['address_line_1', 'address_line_2', 'city', 'island'],
    required: ['address_line_1', 'city'],
  },
  "SD": {  // Sudan
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "SE": {  // Sweden
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
    labels: {
      city: 'Town or city',
    }
  },
  "SG": {   // Singapore (GST)
    format: ['address_line_1', 'address_line_2', 'postal_code'],
    required: ['address_line_1', 'postal_code'],
  },
  "SH": { // Saint Helena
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "SI": {  // Slovenia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "SJ": { // Svalbard and Jan Mayen
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
    labels: {
      city: 'Town or city',
    }
  },
  "SK": {  // Slovakia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "SL": {  // Sierra Leone
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "SM": {  // San Marino
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'postal_code'],
  },
  "SN": {  // Senegal
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "SO": {  // Somalia
    format: ['address_line_1', 'address_line_2', 'city', 'province', 'postal_code'],
    required: ['address_line_1', 'city', 'province'],
    suggestions: {
      province: [
        "Awdal",
        "Bakool",
        "Banaadir",
        "Bari",
        "Bay",
        "Galguduud",
        "Gedo",
        "Hiiraan",
        "Jubbada Dhexe",
        "Jubbada Hoose",
        "Mudug",
        "Nugaal",
        "Sanaag",
        "Shabeellaha Dhexe",
        "Shabeellaha Hoose",
        "Sool",
        "Togdheer",
        "Woqooyi Galbeed",
      ]
    }
  },
  "SR": {  // Suriname
    format: ['address_line_1', 'address_line_2', 'city', 'province'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        "Province",
        "Brokopondo",
        "Commewijne",
        "Coronie",
        "Marowijne",
        "Nickerie",
        "Para",
        "Paramaribo",
        "Saramacca",
        "Sipaliwini",
        "Wanica"
      ]
    }
  },
  "SS": {  // South Sudan
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "ST": { // Sao Tome and Principe
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "SV": {  // El Salvador
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'province'],
    required: ['address_line_1', 'city', 'province'],
    suggestions: {
      province: [
        "Ahuachapán",
        "Cabañas",
        "Chalatenango",
        "Cuscatlán",
        "La Libertad",
        "La Paz",
        "La Unión",
        "Morazán",
        "San Miguel",
        "San Salvador",
        "San Vicente",
        "Santa Ana",
        "Sonsonate",
        "Usulután"
      ]
    }
  },
  "SX": {  // Sint Maarten (Dutch part)
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "SY": {  // Syria
    format: ['address_line_1', 'address_line_2', 'suburb', 'city', 'province', 'postal_code'],
    required: ['address_line_1', 'city', 'province', 'postal_code'],
    suggestions: {
      province: [
        "Aleppo",
        "Damascus",
        "Rif Dimashq",
        "Latakia",
        "Homs",
        "Hama",
        "Idlib",
        "Deir ez-Zor",
        "Hasakah",
        "Raqqa",
        "Daraa",
        "As-Suwayda",
        "Quneitra",
        "Tartus",
      ]
    },
    labels: {
      city: 'City or town',
    }
  },
  "SZ": {  // Eswatini
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "TC": {  // Turks and Caicos Islands
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "TD": {  // Chad
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "TF": {  // French Southern Territories
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "TG": {  // Togo
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "TH": {   // Thailand
    format: ['address_line_1', 'address_line_2', 'suburb', 'city', 'province', 'postal_code'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        { label: "กระบี่ — Krabi", value: "กระบี่" },
        { label: "กรุงเทพมหานคร — Bangkok", value: "กรุงเทพมหานคร" },
        { label: "กาญจนบุรี — Kanchanaburi", value: "กาญจนบุรี" },
        { label: "กาฬสินธุ์ — Kalasin", value: "กาฬสินธุ์" },
        { label: "กำแพงเพชร — Kamphaeng Phet", value: "กำแพงเพชร" },
        { label: "ขอนแก่น — Khon Kaen", value: "ขอนแก่น" },
        { label: "บึงกาฬ — Bueng Kan", value: "บึงกาฬ" },
        { label: "จันทบุรี — Chanthaburi", value: "จันทบุรี" },
        { label: "ฉะเชิงเทรา — Chachoengsao", value: "ฉะเชิงเทรา" },
        { label: "ชลบุรี — Chon Buri", value: "ชลบุรี" },
        { label: "ชัยนาท — Chai Nat", value: "ชัยนาท" },
        { label: "ชัยภูมิ — Chaiyaphum", value: "ชัยภูมิ" },
        { label: "ชุมพร — Chumphon", value: "ชุมพร" },
        { label: "เชียงราย — Chiang Rai", value: "เชียงราย" },
        { label: "เชียงใหม่ — Chiang Mai", value: "เชียงใหม่" },
        { label: "ตรัง — Trang", value: "ตรัง" },
        { label: "ตราด — Trat", value: "ตราด" },
        { label: "ตาก — Tak", value: "ตาก" },
        { label: "นครนายก — Nakhon Nayok", value: "นครนายก" },
        { label: "นครปฐม — Nakhon Pathom", value: "นครปฐม" },
        { label: "นครพนม — Nakhon Phanom", value: "นครพนม" },
        { label: "นครราชสีมา — Nakhon Ratchasima", value: "นครราชสีมา" },
        { label: "นครศรีธรรมราช — Nakhon Si Thammarat", value: "นครศรีธรรมราช" },
        { label: "นครสวรรค์ — Nakhon Sawan", value: "นครสวรรค์" },
        { label: "นนทบุรี — Nonthaburi", value: "นนทบุรี" },
        { label: "นราธิวาส — Narathiwat", value: "นราธิวาส" },
        { label: "น่าน — Nan", value: "น่าน" },
        { label: "บุรีรัมย์ — Buri Ram", value: "บุรีรัมย์" },
        { label: "ปทุมธานี — Pathum Thani", value: "ปทุมธานี" },
        { label: "ประจวบคีรีขันธ์ — Prachuap Khiri Khan", value: "ประจวบคีรีขันธ์" },
        { label: "ปราจีนบุรี — Prachin Buri", value: "ปราจีนบุรี" },
        { label: "ปัตตานี — Pattani", value: "ปัตตานี" },
        { label: "พระนครศรีอยุธยา — Phra Nakhon Si Ayutthaya", value: "พระนครศรีอยุธยา" },
        { label: "พะเยา — Phayao", value: "พะเยา" },
        { label: "พังงา — Phangnga", value: "พังงา" },
        { label: "พัทลุง — Phattalung", value: "พัทลุง" },
        { label: "พิจิตร — Phichit", value: "พิจิตร" },
        { label: "พิษณุโลก — Phitsanulok", value: "พิษณุโลก" },
        { label: "เพชรบุรี — Phetchaburi", value: "เพชรบุรี" },
        { label: "เพชรบูรณ์ — Phetchabun", value: "เพชรบูรณ์" },
        { label: "แพร่ — Phrae", value: "แพร่" },
        { label: "ภูเก็ต — Phuket", value: "ภูเก็ต" },
        { label: "มหาสารคาม — Maha Sarakham", value: "มหาสารคาม" },
        { label: "มุกดาหาร — Mukdahan", value: "มุกดาหาร" },
        { label: "แม่ฮ่องสอน — Mae Hong Son", value: "แม่ฮ่องสอน" },
        { label: "ยโสธร — Yasothon", value: "ยโสธร" },
        { label: "ยะลา — Yala", value: "ยะลา" },
        { label: "ร้อยเอ็ด — Roi Et", value: "ร้อยเอ็ด" },
        { label: "ระนอง — Ranong", value: "ระนอง" },
        { label: "ระยอง — Rayong", value: "ระยอง" },
        { label: "ราชบุรี — Ratchaburi", value: "ราชบุรี" },
        { label: "ลพบุรี — Lop Buri", value: "ลพบุรี" },
        { label: "ลำปาง — Lampang", value: "ลำปาง" },
        { label: "ลำพูน — Lamphun", value: "ลำพูน" },
        { label: "เลย — Loei", value: "เลย" },
        { label: "ศรีสะเกษ — Si Sa Ket", value: "ศรีสะเกษ" },
        { label: "สกลนคร — Sakon Nakhon", value: "สกลนคร" },
        { label: "สงขลา — Songkhla", value: "สงขลา" },
        { label: "สตูล — Satun", value: "สตูล" },
        { label: "สมุทรปราการ — Samut Prakan", value: "สมุทรปราการ" },
        { label: "สมุทรสงคราม — Samut Songkhram", value: "สมุทรสงคราม" },
        { label: "สมุทรสาคร — Samut Sakhon", value: "สมุทรสาคร" },
        { label: "สระแก้ว — Sa Kaeo", value: "สระแก้ว" },
        { label: "สระบุรี — Saraburi", value: "สระบุรี" },
        { label: "สิงห์บุรี — Sing Buri", value: "สิงห์บุรี" },
        { label: "สุโขทัย — Sukhothai", value: "สุโขทัย" },
        { label: "สุพรรณบุรี — Suphan Buri", value: "สุพรรณบุรี" },
        { label: "สุราษฎร์ธานี — Surat Thani", value: "สุราษฎร์ธานี" },
        { label: "สุรินทร์ — Surin", value: "สุรินทร์" },
        { label: "หนองคาย — Nong Khai", value: "หนองคาย" },
        { label: "หนองบัวลำภู — Nong Bua Lam Phu", value: "หนองบัวลำภู" },
        { label: "อ่างทอง — Ang Thong", value: "อ่างทอง" },
        { label: "อำนาจเจริญ — Amnat Charoen", value: "อำนาจเจริญ" },
        { label: "อุดรธานี — Udon Thani", value: "อุดรธานี" },
        { label: "อุตรดิตถ์ — Uttaradit", value: "อุตรดิตถ์" },
        { label: "อุทัยธานี — Uthai Thani", value: "อุทัยธานี" },
        { label: "อุบลราชธานี — Ubon Ratchathani", value: "อุบลราชธานี" },
      ]
    }
  },
  "TJ": {  // Tajikistan
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "TK": {  // Tokelau
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "TL": { // Timor-Leste
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "TM": {  // Turkmenistan
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "TN": {  // Tunisia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "TO": {  // Tonga
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "TR": {  // Turkey
    format: ['address_line_1', 'address_line_2', ['postal_code', 'district'], 'province'],
    required: ['address_line_1', 'district', 'postal_code'],
    suggestions: {
      province: [
        "Adana",
        "Adıyaman",
        "Afyon",
        "Ağrı",
        "Aksaray",
        "Amasya",
        "Ankara",
        "Antalya",
        "Ardahan",
        "Artvin",
        "Aydın",
        "Balıkesir",
        "Bartın",
        "Batman",
        "Bayburt",
        "Bilecik",
        "Bingöl",
        "Bitlis",
        "Bolu",
        "Burdur",
        "Bursa",
        "Çanakkale",
        "Çankırı",
        "Çorum",
        "Denizli",
        "Diyarbakır",
        "Düzce",
        "Edirne",
        "Elazığ",
        "Erzincan",
        "Erzurum",
        "Eskişehir",
        "Gaziantep",
        "Giresun",
        "Gümüşhane",
        "Hakkari",
        "Hatay",
        "Iğdır",
        "Isparta",
        "İstanbul",
        "İzmir",
        "Kahramanmaraş",
        "Karabük",
        "Karaman",
        "Kars",
        "Kastamonu",
        "Kayseri",
        "Kırıkkale",
        "Kırklareli",
        "Kırşehir",
        "Kilis",
        "Kocaeli",
        "Konya",
        "Kütahya",
        "Malatya",
        "Manisa",
        "Mardin",
        "Mersin",
        "Muğla",
        "Muş",
        "Nevşehir",
        "Niğde",
        "Ordu",
        "Osmaniye",
        "Rize",
        "Sakarya",
        "Samsun",
        "Siirt",
        "Sinop",
        "Sivas",
        "Şanlıurfa",
        "Şırnak",
        "Tekirdağ",
        "Tokat",
        "Trabzon",
        "Tunceli",
        "Uşak",
        "Van",
        "Yalova",
        "Yozgat",
        "Zonguldak",
      ]
    }
  },
  "TT": {  // Trinidad and Tobago
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "TV": {  // Tuvalu
    format: ['address_line_1', 'address_line_2', 'city', 'island'],
    required: ['address_line_1', 'city'],
    suggestions: {
      island: [
        "Funafuti",
        "Nanumanga",
        "Nanumea",
        "Niulakita",
        "Niutao",
        "Nui",
        "Nukufetau",
        "Nukulaelae",
        "Vaitupu",
      ]
    }
  },
  "TW": {   // Taiwan
    format: ['postal_code', 'county', 'district', 'address_line_1', 'address_line_2'],
    required: ['address_line_1', 'district', 'postal_code', 'county'],
    suggestions: {
      county: [
        { label: "台中市 — Taichung City", value: "台中市" },
        { label: "台北市 — Taipei City", value: "台北市" },
        { label: "台東縣 — Taitung County", value: "台東縣" },
        { label: "台南市 — Tainan City", value: "台南市" },
        { label: "宜蘭縣 — Yilan County", value: "宜蘭縣" },
        { label: "花蓮縣 — Hualien County", value: "花蓮縣" },
        { label: "金門縣 — Kinmen County", value: "金門縣" },
        { label: "南投縣 — Nantou County", value: "南投縣" },
        { label: "屏東縣 — Pingtung County", value: "屏東縣" },
        { label: "苗栗縣 — Miaoli County", value: "苗栗縣" },
        { label: "桃園市 — Taoyuan City", value: "桃園市" },
        { label: "高雄市 — Kaohsiung City", value: "高雄市" },
        { label: "基隆市 — Keelung City", value: "基隆市" },
        { label: "連江縣 — Lienchiang County", value: "連江縣" },
        { label: "雲林縣 — Yunlin County", value: "雲林縣" },
        { label: "新北市 — New Taipei City", value: "新北市" },
        { label: "新竹市 — Hsinchu City", value: "新竹市" },
        { label: "新竹縣 — Hsinchu County", value: "新竹縣" },
        { label: "嘉義市 — Chiayi City", value: "嘉義市" },
        { label: "嘉義縣 — Chiayi County", value: "嘉義縣" },
        { label: "彰化縣 — Changhua County", value: "彰化縣" },
        { label: "澎湖縣 — Penghu County", value: "澎湖縣" },

      ]
    }
  },
  "TZ": {  // Tanzania
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "UA": {  // Ukraine
    format: ['address_line_1', 'address_line_2', 'city', 'oblast', 'postal_code'],
    required: ['address_line_1', 'city', 'postal_code'],
    suggestions: {
      oblast: [
        { label: "Вінницька область — Vinnyts'ka oblast", value: "Вінницька область" },
        { label: "Волинська область — Volyns'ka oblast", value: "Волинська область" },
        { label: "Дніпропетровська область — Dnipropetrovsk oblast", value: "Дніпропетровська область" },
        { label: "Житомирська область — Zhytomyrs'ka oblast", value: "Житомирська область" },
        { label: "Закарпатська область — Zakarpats'ka oblast", value: "Закарпатська область" },
        { label: "Запорізька область — Zaporiz'ka oblast", value: "Запорізька область" },
        { label: "Івано-Франківська область — Ivano-Frankivs'ka oblast", value: "Івано-Франківська область" },
        { label: "Київ — Kyiv city", value: "Київ" },
        { label: "Київська область — Kiev oblast", value: "Київська область" },
        { label: "Кіровоградська область — Kirovohrads'ka oblast", value: "Кіровоградська область" },
        { label: "Львівська область — Lviv oblast", value: "Львівська область" },
        { label: "Миколаївська область — Mykolaivs'ka oblast", value: "Миколаївська область" },
        { label: "Одеська область — Odessa oblast", value: "Одеська область" },
        { label: "Полтавська область — Poltavs'ka oblast", value: "Полтавська область" },
        { label: "Рівненська область — Rivnens'ka oblast", value: "Рівненська область" },
        { label: "Сумська область — Sums'ka oblast", value: "Сумська область" },
        { label: "Тернопільська область — Ternopil's'ka oblast", value: "Тернопільська область" },
        { label: "Харківська область — Kharkiv oblast", value: "Харківська область" },
        { label: "Херсонська область — Khersons'ka oblast", value: "Херсонська область" },
        { label: "Хмельницька область — Khmel'nyts'ka oblast", value: "Хмельницька область" },
        { label: "Черкаська область — Cherkas'ka oblast", value: "Черкаська область" },
        { label: "Чернівецька область — Chernivets'ka oblast", value: "Чернівецька область" },
        { label: "Чернігівська область — Chernihivs'ka oblast", value: "Чернігівська область" },
      ]
    }
  },
  "UG": {  // Uganda
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "UM": {  // United States Minor Outlying Islands
    format: ['address_line_1', 'address_line_2'],
    required: ['address_line_1'],
  },
  "US": {   // United States
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code'], 'state'],
    required: ['address_line_1', 'city', 'postal_code', 'state'],
    suggestions: {
      state: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "Armed Forces (AA)",
        "Armed Forces (AE)",
        "Armed Forces (AP)",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Micronesia",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Islands",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ]
    },
    labels: {
      postal_code: 'ZIP'
    }
  },
  "UY": {  // Uruguay
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'province'],
    required: ['address_line_1', 'city'],
    suggestions: [
      "Artigas",
      "Canelones",
      "Cerro Largo",
      "Colonia",
      "Durazno",
      "Flores",
      "Florida",
      "Lavalleja",
      "Maldonado",
      "Montevideo",
      "Paysandú",
      "Río Negro",
      "Rivera",
      "Rocha",
      "Salto",
      "San José",
      "Soriano",
      "Tacuarembó",
      "Treinta y Tres",
    ]
  },
  "UZ": {  // Uzbekistan
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'province'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        "Tashkent",
        "Andijan Region",
        "Bukhara Region",
        "Fergana Region",
        "Jizzakh Region",
        "Namangan Region",
        "Navoiy Region",
        "Qashqadaryo Region",
        "Samarqand Region",
        "Sirdaryo Region",
        "Surxondaryo Region",
        "Tashkent Region",
        "Xorazm Region",
        "Karakalpakstan",
      ]
    }
  },
  "VA": {   // Vatican City
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "VC": { // Saint Vincent and the Grenadines
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city'],
  },
  "VE": {  // Venezuela
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code'], 'state'],
    required: ['address_line_1', 'city', 'state'],
    suggestions: {
      state: [
        "Amazonas",
        "Anzoátegui",
        "Apure",
        "Aragua",
        "Barinas",
        "Bolívar",
        "Carabobo",
        "Cojedes",
        "Delta Amacuro",
        "Dependencias Federales",
        "Distrito Federal",
        "Falcón",
        "Guárico",
        "Lara",
        "Mérida",
        "Miranda",
        "Monagas",
        "Nueva Esparta",
        "Portuguesa",
        "Sucre",
        "Táchira",
        "Trujillo",
        "Vargas",
        "Yaracuy",
        "Zulia",
      ]
    }
  },
  "VG": {  // British Virgin Islands
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1'],
  },
  "VI": {  // United States Virgin Islands
    format: ['address_line_1', 'address_line_2', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
    labels: {
      postal_code: 'ZIP'
    }
  },
  "VN": {  // Vietnam
    format: ['address_line_1', 'address_line_2', 'city', 'province', 'postal_code'],
    required: ['address_line_1', 'city'],
    suggestions: {
      province: [
        { label: "An Giang — An Giang Province", value: "An Giang" },
        { label: "Bà Rịa–Vũng Tàu — Ba Ria-Vung Tau Province", value: "Bà Rịa–Vũng Tàu" },
        { label: "Bạc Liêu — Bac Lieu Province", value: "Bạc Liêu" },
        { label: "Bắc Giang — Bac Giang Province", value: "Bắc Giang" },
        { label: "Bắc Kạn — Bac Kan Province", value: "Bắc Kạn" },
        { label: "Bắc Ninh — Bac Ninh Province", value: "Bắc Ninh" },
        { label: "Bến Tre — Ben Tre Province", value: "Bến Tre" },
        { label: "Bình Dương — Binh Duong Province", value: "Bình Dương" },
        { label: "Bình Định — Binh Dinh Province", value: "Bình Định" },
        { label: "Bình Phước — Binh Phuoc Province", value: "Bình Phước" },
        { label: "Bình Thuận — Binh Thuan Province", value: "Bình Thuận" },
        { label: "Cà Mau — Ca Mau Province", value: "Cà Mau" },
        { label: "Cao Bằng — Cao Bang Province", value: "Cao Bằng" },
        { label: "Cần Thơ — Can Tho City", value: "Cần Thơ" },
        { label: "Đà Nẵng — Da Nang City", value: "Đà Nẵng" },
        { label: "Đắk Lắk — Dak Lak Province", value: "Đắk Lắk" },
        { label: "Đăk Nông — Dak Nong Province", value: "Đăk Nông" },
        { label: "Điện Biên — Dien Bien Province", value: "Điện Biên" },
        { label: "Đồng Nai — Dong Nai Province", value: "Đồng Nai" },
        { label: "Đồng Tháp — Dong Thap Province", value: "Đồng Tháp" },
        { label: "Gia Lai — Gia Lai Province", value: "Gia Lai" },
        { label: "Hà Giang — Ha Giang Province", value: "Hà Giang" },
        { label: "Hà Nam — Ha Nam Province", value: "Hà Nam" },
        { label: "Hà Nội — Hanoi City", value: "Hà Nội" },
        { label: "Hà Tĩnh — Ha Tinh Province", value: "Hà Tĩnh" },
        { label: "Hải Dương — Hai Duong Province", value: "Hải Dương" },
        { label: "Hải Phòng — Haiphong City", value: "Hải Phòng" },
        { label: "Hậu Giang — Hau Giang Province", value: "Hậu Giang" },
        { label: "Hòa Bình — Hoa Binh Province", value: "Hòa Bình" },
        { label: "Hưng Yên — Hung Yen Province", value: "Hưng Yên" },
        { label: "Khánh Hòa — Khanh Hoa Province", value: "Khánh Hòa" },
        { label: "Kiên Giang — Kien Giang Province", value: "Kiên Giang" },
        { label: "Kon Tum — Kon Tum Province", value: "Kon Tum" },
        { label: "Lai Châu — Lai Chau Province", value: "Lai Châu" },
        { label: "Lạng Sơn — Lang Song Province", value: "Lạng Sơn" },
        { label: "Lào Cai — Lao Cai Province", value: "Lào Cai" },
        { label: "Lâm Đồng — Lam Dong Province", value: "Lâm Đồng" },
        { label: "Long An — Long An Province", value: "Long An" },
        { label: "Nam Định — Nam Dinh Province", value: "Nam Định" },
        { label: "Nghệ An — Nghe An Province", value: "Nghệ An" },
        { label: "Ninh Bình — Ninh Binh Province", value: "Ninh Bình" },
        { label: "Ninh Thuận — Ninh Thuan Province", value: "Ninh Thuận" },
        { label: "Phú Thọ — Phu Tho Province", value: "Phú Thọ" },
        { label: "Phú Yên — Phu Yen Province", value: "Phú Yên" },
        { label: "Quảng Bình — Quang Binh Province", value: "Quảng Bình" },
        { label: "Quảng Nam — Quang Nam Province", value: "Quảng Nam" },
        { label: "Quảng Ngãi — Quang Ngai Province", value: "Quảng Ngãi" },
        { label: "Quảng Ninh — Quang Ninh Province", value: "Quảng Ninh" },
        { label: "Quảng Trị — Quang Tri Province", value: "Quảng Trị" },
        { label: "Sóc Trăng — Soc Trang Province", value: "Sóc Trăng" },
        { label: "Sơn La — Son La Province", value: "Sơn La" },
        { label: "Tây Ninh — Tay Ninh Province", value: "Tây Ninh" },
        { label: "Thái Bình — Thai Binh Province", value: "Thái Bình" },
        { label: "Thái Nguyên — Thai Nguyen Province", value: "Thái Nguyên" },
        { label: "Thanh Hóa — Thanh Hoa Province", value: "Thanh Hóa" },
        { label: "Thành phố Hồ Chí Minh — Ho Chi Minh City", value: "Thành phố Hồ Chí Minh" },
        { label: "Thừa Thiên–Huế — Thua Thien-Hue Province", value: "Thừa Thiên–Huế" },
        { label: "Tiền Giang — Tien Giang Province", value: "Tiền Giang" },
        { label: "Trà Vinh — Tra Vinh Province", value: "Trà Vinh" },
        { label: "Tuyên Quang — Tuyen Quang Province", value: "Tuyên Quang" },
        { label: "Vĩnh Long — Vinh Long Province", value: "Vĩnh Long" },
        { label: "Vĩnh Phúc — Vinh Phuc Province", value: "Vĩnh Phúc" },
        { label: "Yên Bái — Yen Bai Province", value: "Yên Bái" },

      ]
    }
  },
  "VU": {  // Vanuatu
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "WF": {  // Wallis and Futuna
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'cedex'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "WS": { // Samoa
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "YE": {   // Yemen
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
  "YT": {  // Mayotte
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city'], 'cedex'],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "ZA": {  // South Africa
    format: ['address_line_1', 'address_line_2', 'suburb', ['city', 'postal_code']],
    required: ['address_line_1', 'city', 'postal_code'],
  },
  "ZM": {  // Zambia
    format: ['address_line_1', 'address_line_2', ['postal_code', 'city']],
    required: ['address_line_1', 'city'],
  },
  "ZW": {  // Zimbabwe
    format: ['address_line_1', 'address_line_2', 'city'],
    required: ['address_line_1', 'city'],
  },
}

export default ADDRESS_FORMATS
