export default function WalletReducer(state = null, action) {
  switch (action.type) {
    case 'user/set':
      return {...action.user }
    case 'user/change':
      if(state) {
        return Object.assign({}, state, action.user)
      } else {
        return action.user
      }
    default:
      return state
  }
}
