import React from "react"
import { NavLink } from "react-router-dom"
import Onboarding from '../../components/Onboarding'

export default function(props) {

  return(
    <div className="onboarding-screen px-3 pt-5">

      <div className="px-2 px-lg-0">

        <div className="row pt-4 pt-lg-3">
          <div className="col">
            <h2 className="pb-2">👋 Welcome</h2>
            <div className="font-size-xl">Let's get started with Web3 Payments</div>
          </div>
        </div>

        <Onboarding/>
        
      </div>
    </div>
  )
}
