import ManageAccountsButton from "./ManageAccountsButton"
import ManageUserButton from "../components/header/ManageUserButton"
import React, { useEffect, useState } from "react"

export default function(props) {

  const [ scrollTop, setScrollTop ] = useState(document.scrollTop);
  const [ scrolled, setScrolled ] = useState(document.scrollTop > 40);

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolled(e.target.documentElement.scrollTop > 40);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(()=> {
    setScrolled(document.scrollTop > 40);
  }, [])

  return(

    <header className={["header", (scrolled ? 'scrolled' : '')].join(' ')}>

      <div className="container position-relative d-flex">

        <div className="w-100 ps-0">

          <ManageAccountsButton className="d-inline-block"/>

        </div>

        <div className="ms-auto d-flex">
      
          <div className="ps-2 ps-lg-0">
            <ManageUserButton className="ms-1"/>
          </div>

          <div className="header-navigation d-inline-block d-lg-none">
            <button onClick={ props.toggleNavigation } aria-expanded="false" className="mobile-navigation-button btn position-relative" type="button">
              <div className={`mobile-navigation-icon ${props.openNavigation ? "open" : ""}`}>
                <span className="mobile-navigation-icon-stripe"></span>
                <span className="mobile-navigation-icon-stripe"></span>
                <span className="mobile-navigation-icon-stripe"></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}
