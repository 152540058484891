export default function IntegrationLogsReducer(state = {}, action) {
  let newLogs
  switch (action.type) {
    case 'integration_logs/add':
      newLogs = state[action.integration_id] || []
      newLogs = [action.log, ...newLogs]
      return {...state, [action.integration_id]: newLogs }
    case 'integration_logs/update':
      newLogs = state[action.integration_id] ? [...state[action.integration_id]] : []
      let index = newLogs.findIndex((log)=>log.id === action.log.id)
      if(index > -1) {
        newLogs[index] = action.log
      }
      return {...state, [action.integration_id]: newLogs }
    case 'integration_logs/set':
      return {...state, [action.integration_id]: action.logs }
    default:
      return state
  }
}
