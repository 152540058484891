import Blockchains from '@depay/web3-blockchains'

export default function(blockchains){

  blockchains = [...new Set(blockchains)]

  function decode64(string) {
    return decodeURIComponent(escape(window.atob(string)));
  }

  function encode64(string) {
    return window.btoa(unescape(encodeURIComponent(string)));
  }

  const logosPerRow = 5
  const rows = Math.ceil(blockchains.length/logosPerRow)

  const width = 400 * (rows > 1 ? logosPerRow : blockchains.length)
  const height = 400 * (rows) + (20*rows-1)

  let svg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="${200*(rows > 1 ? logosPerRow-1 : blockchains.length-1)} ${200*(rows-1)} ${width} ${height}" xml:space="preserve">`
  blockchains.forEach((name, index)=>{
    const rowIndex = Math.floor(index/logosPerRow)
    const indexWithinRow = index % logosPerRow

    let xml = decode64(Blockchains.findByName(name).logoWhiteBackground.split(';base64,')[1])
    var doc = new DOMParser().parseFromString(xml, "text/xml")
    var blockchainSvg = doc.getElementsByTagName('svg')[0]
    svg += `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="${400*indexWithinRow}px" y="${rowIndex*(400+20)}px" viewBox="0 0 400 ${height}" xml:space="preserve">`
    svg += blockchainSvg.outerHTML
    svg += `</svg>`
  })
  svg += `</svg>`

  return `data:image/svg+xml;base64,${encode64(svg)}`
}
