import AddressEllipsis from "../../helpers/AddressEllipsis"
import Dropdown from '../../components/Dropdown'
import React, { useState, useEffect } from "react"
import UserSettingsSidebar from '../../components/UserSettingsSidebar'
import WalletIcon from "../../components/WalletIcon"
import { GET, POST, DELETE } from '../../helpers/Queries'
import { NavLink, useNavigate } from "react-router-dom"
import { Store } from '../../Store'
import { useSelector } from 'react-redux'

export default function(props) {

  const user = useSelector(state => state.user)
  const userWallets = useSelector(state => state.user_wallets)
  const navigate = useNavigate()

  useEffect(()=>{
    GET(`/api/user`).then((user)=>{
      Store.dispatch({ type: 'user/set', user })
    })
    GET(`/api/user/wallets`).then((userWallets)=>{
      Store.dispatch({ type: 'userWallets/set', userWallets })
    })
  }, [])

  const deleteWallet = (userWallet)=>{
    let confirmation = confirm(`Are you sure you want to delete ${userWallet.name || 'this wallet'}? You will not be able to login with this wallet anymore!`)
    if(confirmation) {
      Store.dispatch({ type: 'userWallets/delete', id: userWallet.id })
      DELETE(`/api/user/wallets/${userWallet.id}`)
    }
  }

  return(
    <div className="screen user-settings-screen row">

      <div className="col-12 col-md-3 order-md-1 sticky-reset">
        <UserSettingsSidebar/>
      </div>

      <div className="d-block col-12 col-md-9 d-md-flex justify-content-end pe-md-4">

        <div className="col-lg-8">

          <div className="form-group pb-2 pt-3 mb-2">
            <label className="pb-2 ps-1">
              <h5>Two-Factor Authentication (2FA)</h5>
            </label>
            <div className="rounded-2 bg-white mb-2 p-3">
              { !user.otp && 
                <div className="d-flex d-flex align-items-center">
                  <span className="d-inline-block text-center me-2" style={{width: '33px'}}><i className="fa-solid fa-lock-open"></i></span>
                  <div className="flex-grow-1">
                    Insecure
                  </div>
                  <NavLink className="btn btn-action" to="/user/authentication/2fa">Setup now</NavLink>
                </div>
              }
              { user.otp && 
                <div className="d-flex d-flex align-items-center">
                  <span className="d-inline-block text-center me-2" style={{width: '33px'}}><i className="fa-solid fa-lock"></i></span>
                  <div className="flex-grow-1">
                    Secured
                  </div>
                </div>
              }
            </div>
            { !user.otp &&
              <div className="px-2 pt-1">
                <small className="text-muted ps-1">
                  <i className="fas fa-info-circle me-2"></i>
                  Recommended to improve security.
                </small>
              </div>
            }
          </div>

          <div className="form-group pb-2 pt-3 mb-2">
            <label className="pb-2 ps-1">
              <h5>E-Mail</h5>
            </label>
            <div className="rounded-2 bg-white mb-2 p-3">
              { !user.email && 
                <div className="d-flex d-flex align-items-center">
                  <span className="d-inline-block text-center me-2" style={{width: '33px'}}><i className="fa-regular fa-envelope"></i></span>
                  <div className="flex-grow-1">
                    None
                  </div>
                  <NavLink className="btn btn-action" to="/user/settings">Setup now</NavLink>
                </div>
              }
              { user.email && 
                <div className="d-flex d-flex align-items-center">
                  <span className="d-inline-block text-center me-2" style={{width: '33px'}}><i className="fa-solid fa-envelope"></i></span>
                  <div className="flex-grow-1">
                    { user.email }
                  </div>
                  <NavLink className="btn btn-action" to="/user/settings">Change</NavLink>
                </div>
              }
            </div>
            <div className="px-2 pt-1">
              <small className="text-muted ps-1">
                <i className="fas fa-info-circle me-2"></i>
                Your email address can be used to log-in.
              </small>
            </div>
          </div>

          <div className="form-group pb-2 pt-3 mb-2">
            <label htmlFor="userName" className="pb-2 ps-1">
              <h5>Wallets</h5>
            </label>
            <div className="d-block">
              <Dropdown
                toggle={
                  <button type="button" className="btn btn-action btn-sm" title="Add additional wallet">
                    <i className="fa-solid fa-plus me-2"></i>
                    Add Wallet
                  </button>
                }
                children={[
                  ['Connect wallet', ()=>{ navigate(`/user/authentication/wallets/new?connect=1`) }, <i className="fas fa-wallet me-2"></i>],
                  ['Enter manually', ()=>{ navigate(`/user/authentication/wallets/new`) }, <i className="far fa-pen-to-square me-2"></i>],
                ]}
              />
            </div>
            <div className="pt-2">
              <div className="card d-block p-0">
                { userWallets.map((userWallet, index)=>{
                  return(
                    <NavLink to={`/user/authentication/wallets/${userWallet.id}`} key={userWallet.id} className="circular-card-interactive p-2 py-3 p-lg-3">
                      <div className="d-flex align-items-center">
                        <div className="ps-2">
                          <WalletIcon wallet={ userWallet } size={ 34 }/>
                        </div>
                        <div className="flex-grow-1 line-height-extra-small px-3">
                          <div>
                            <span className="font-weight-medium">{ userWallet.name || "Unnamed Wallet" }</span>
                          </div>
                          <div>
                            <small className="font-weight-medium opacity-50" title={ userWallet.external_id }>{ AddressEllipsis(userWallet.external_id) }</small>
                          </div>
                        </div>
                        <div className="text-end">
                          <Dropdown
                            toggle={
                              <button className="btn btn-action-hover" type="button">
                                <i className="fas fa-ellipsis-h text-black-50"></i>
                              </button>
                            }
                            children={[
                              ['Edit', ()=>{ navigate(`/user/authentication/wallets/${userWallet.id}`) }, <i className="fa-solid fa-pen-to-square me-2"></i>],
                              (userWallets.length != 1 || user?.email?.length) ? ['Delete', ()=>{ deleteWallet(userWallet) }, <i className="far fa-trash-alt me-2"></i>] : undefined,
                            ].filter(Boolean)}
                          />
                        </div>
                      </div>
                    </NavLink>
                  )
                }) }
              </div>
            </div>
            <div className="px-2 pt-1">
              <small className="text-muted ps-1">
                <i className="fas fa-info-circle me-2"></i>
                All your wallets can be used to log-in.
              </small>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}
