import { GET } from "./Queries"
import { Store } from '../Store'

export default (payments)=>{
  let requiredTokenData = {}
  payments.forEach((payment)=>{
    requiredTokenData[`${payment.blockchain}-${payment.sender_token_id}`] = true
    requiredTokenData[`${payment.blockchain}-${payment.receiver_token_id}`] = true
  })
  return GET(`/api/tokens?tokens=${Object.keys(requiredTokenData).join(',')}`).then((tokenData)=>{
    Store.dispatch(({ type: 'tokens/add', tokens: tokenData }))
  })
}
