import AcceptedPayments from '../../components/integrations/edit/AcceptedPayments'
import AddressCompletion from '../../components/AddressCompletion'
import Blockchains from '@depay/web3-blockchains' 
import ChangeQuantityDialog from '../../dialogs/ChangeQuantityDialog'
import copy from 'copy-to-clipboard'
import CopyButton from "../../components/CopyButton"
import CountryNames from '../../helpers/CountryNames'
import CountryTypeahead from '../../components/CountryTypeahead'
import Decimal from 'decimal.js'
import Dialog from '../../components/Dialog'
import Dropdown from '../../components/Dropdown'
import FileUploader from '../../components/FileUploader'
import FlipClock from '../../components/FlipClock'
import formatQuantityNumber from '../../helpers/formatQuantityNumber'
import isValidAddress from '../../helpers/isValidAddress'
import LightOrDark from '../../helpers/LightOrDark'
import moment from 'moment'
import qrCodeImageFor from '../../helpers/QrCodeImageFor'
import QRCodeStyling from "qr-code-styling"
import React, { useState, useEffect, useRef } from "react"
import TextareaAutosize from 'react-textarea-autosize'
import TooltipDrawer from '../../components/TooltipDrawer'
import VatRow from "../../components/VatRow"
import Web3Exchanges from '@depay/web3-exchanges'
import { all } from '../../helpers/Currencies'
import { Currency } from '@depay/local-currency'
import { DEFAULT_RATES } from '../../helpers/Vat'
import { ethers } from 'ethers'
import { GET, POST } from '../../helpers/Queries'
import { NavLink } from "react-router-dom"
import { Store } from '../../Store'
import { TokenImage } from '@depay/react-token-image'
import { useDebounce } from 'use-debounce'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'

export default function(props) {

  const { id } = useParams()
  const account = useSelector(state => state.account)
  const accountWallets = useSelector(state => state.account_wallets)
  const links = useSelector(state => state.links)
  const [ displayCurrencyRate, setDisplayCurrencyRate ] = useState()
  const [ showTooltip, setShowTooltip ] = useState()
  const [ updateInterval, setUpdateInterval ] = useState(1)
  const [ link, setLink ] = useState()
  const [ displayedTotal, setDisplayedTotal ] = useState()
  const [ displayedVat, setDisplayedVat ] = useState()
  const [ showQuantityDialog, setShowQuantityDialog ] = useState(false)
  const [ displayedEach, setDisplayedEach ] = useState()
  const [ textColor, setTextColor ] = useState('#FFFFFF')
  const [ backgroundColor, setBackgroundColor ] = useState('#ea357a')
  const [ suggestedAmount1, setSuggestedAmount1 ] = useState()
  const [ displayedSuggestedAmount1, setDisplayedSuggestedAmount1 ] = useState()
  const [ suggestedAmount2, setSuggestedAmount2 ] = useState()
  const [ displayedSuggestedAmount2, setDisplayedSuggestedAmount2 ] = useState()
  const [ suggestedAmount3, setSuggestedAmount3 ] = useState()
  const [ displayedSuggestedAmount3, setDisplayedSuggestedAmount3 ] = useState()
  const [ startAmount, setStartAmount ] = useState()
  const [ quantitySelection, setQuantitySelection ] = useState()
  const [ quantity, setQuantity ] = useState(1)
  const [ fullName, setFullName ] = useState()
  const [ shippingAddress, setShippingAddress ] = useState()
  const [ billingAddress, setBillingAddress ] = useState()
  const [ country, setCountry ] = useState()
  const [ acceptedBlockchains, setAcceptedBlockchains ] = useState()
  const [ showDiscountsDialog, setShowDiscountsDialog ] = useState(false)
  const [ qrCode, setQrCode ] = useState()
  const [ receiverImage, setReceiverImage ] = useState()
  const [ qrCodeExpanded, setQrCodeExpanded ] = useState(false)
  const [ invalidPriceConfiguration, setInvalidPriceConfiguration ] = useState()
  const [ invalidTokensConfiguration, setInvalidTokensConfiguration ] = useState()
  const [ invalidAmountConfiguration, setInvalidAmountConfiguration ] = useState()
  const [ invalidReceiverConfiguration, setInvalidReceiverConfiguration ] = useState()
  const [ invalidConfiguration, setInvalidConfiguration ] = useState()
  const [ debouncedLink ] = useDebounce(link, 500)
  const redirectRef = useRef()
  const qrCodeRef = useRef()
  const tokensHeadlineElement = useRef()
  const priceInputElement = useRef()

  const updateLink = (data) => {
    if(data.displayed_currency && data.displayed_currency === 'local'){ data.displayed_currency = undefined }
    setLink(Object.assign({}, link, data))
  }

  const addCustomField = ()=> {
    updateLink({...link, custom_fields: [...(link.custom_fields||[]), { name: '', type: 'string' }]})
  }

  const removeCustomField = (index)=>{
    let custom_fields = link.custom_fields.slice()
    custom_fields.splice(index, 1)
    updateLink({...link, custom_fields })
  }

  const updateCustomField = (index, attribute, value)=>{
    let custom_fields = link.custom_fields.slice()
    custom_fields[index] = JSON.parse(JSON.stringify(custom_fields[index]))
    custom_fields[index][attribute] = value
    updateLink({...link, custom_fields })
  }

  const addDiscount = ()=> {
    updateLink({...link, discounts: [...(link.discounts||[]), { percent: '10', tokens: [], codes: [] }]})
  }

  const removeDiscount = (index)=>{
    let discounts = link.discounts.slice()
    discounts.splice(index, 1)
    updateLink({...link, discounts })
  }

  const updateDiscount = (index, value)=>{
    value = parseInt(value, 10)
    value = Math.min(value, 100)
    value = Math.max(value, 1)
    let discounts = link.discounts.slice()
    discounts[index] = JSON.parse(JSON.stringify(discounts[index]))
    discounts[index].percent = value
    updateLink({...link, discounts })
  }

  const addDiscountToken = (index, token)=>{
    let discounts = link.discounts.slice()
    discounts[index] = JSON.parse(JSON.stringify(discounts[index]))
    if(discounts[index].tokens === undefined) { discounts[index].tokens = [] }
    discounts[index].tokens.push(token)
    updateLink({...link, discounts })
  }

  const removeDiscountToken = (discountIndex, tokenIndex)=>{
    let discounts = JSON.parse(JSON.stringify(link.discounts))
    let discount = discounts[discountIndex]
    discount.tokens.splice(tokenIndex, 1)
    updateLink({...link, discounts })
  }

  const addDiscountCode = (index)=>{
    let discounts = link.discounts.slice()
    discounts[index] = JSON.parse(JSON.stringify(discounts[index]))
    if(discounts[index].codes === undefined) { discounts[index].codes = [] }
    discounts[index].codes.push("")
    updateLink({...link, discounts })
  }

  const updateDiscountCode = (discountIndex, codeIndex, value)=>{
    let discounts = link.discounts.slice()
    discounts[discountIndex] = JSON.parse(JSON.stringify(discounts[discountIndex]))
    discounts[discountIndex].codes[codeIndex] = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
    updateLink({...link, discounts })
  }

  const removeDiscountCode = (discountIndex, codeIndex)=>{
    let discounts = JSON.parse(JSON.stringify(link.discounts))
    let discount = discounts[discountIndex]
    discount.codes.splice(codeIndex, 1)
    updateLink({...link, discounts })
  }

  const updatePrice = async(link)=> {
    let acceptedPayments = link?.configuration?.acceptedPayments
    if(link && link.currency) {
      if(link.price && link.price.length) {
        let displayCurrency = link.displayed_currency === 'local' ? undefined : link.displayed_currency
        let rate = await Currency.rate({ from: link.currency, to: displayCurrency })
        let price = Decimal.div(link.price, rate).mul(quantity).toNumber()
        setDisplayedEach((new Currency({ amount: Decimal.div(link.price, rate).toNumber(), code: displayCurrency })).toString())
        setDisplayedVat(link.included_vat ? (new Currency({ amount: price/100*parseFloat(link.included_vat), code: displayCurrency })).toString() : null)
        setDisplayedTotal((new Currency({ amount: price, code: displayCurrency })).toString())
      } else {
        setDisplayedEach()
        setDisplayedVat()
        setDisplayedTotal()
      }
    } else if(acceptedPayments && acceptedPayments.length){
      if(acceptedPayments.length == 1 && acceptedPayments[0].amount) {
        let price = Decimal.mul(acceptedPayments[0].amount, quantity).toNumber()
        setDisplayedEach(`${acceptedPayments[0].token.symbol} ${ acceptedPayments[0].amount }`)
        setDisplayedVat(link.included_vat ? `${acceptedPayments[0].token.symbol} ${ price/100*parseFloat(link.included_vat) }` : null)
        setDisplayedTotal(`${acceptedPayments[0].token.symbol} ${ price }`)
      } else if(acceptedPayments.every((payment)=>{ return !!payment.amount })) {
        let allPaymentsWithAmount = acceptedPayments.filter((payment)=>{ return !!payment.amount })
        Promise.all(allPaymentsWithAmount.map((payment)=>{
          let USD = Blockchains[payment.token.blockchain].stables.usd.find((stable)=>stable===payment.token.address)
          if(USD) { USD = Blockchains[payment.token.blockchain].tokens.find((token)=>token.address===USD) }
          if(USD){
            return Promise.resolve([{
              amountOut: ethers.utils.parseUnits(payment.amount, USD.decimals),
              exchange: {
                blockchain: payment.token.blockchain
              },
              decimals: USD.decimals,
            }])
          } else {
            return Web3Exchanges.route({
              blockchain: payment.token.blockchain,
              tokenIn: payment.token.address,
              tokenOut: Blockchains[payment.token.blockchain].stables.usd[0],
              amountIn: parseFloat(payment.amount)
            })
          }
        })).then(async (routes)=>{
          let usdAmounts = routes.map((routes)=>{
            if(!routes[0]) { return }
            return ethers.utils.formatUnits(
              routes[0].amountOut,
              routes[0].decimals || Blockchains[routes[0].blockchain].tokens.find((token)=>token.address===Blockchains[routes[0].blockchain].stables.usd[0]).decimals,
            )
          })
          let usdMaxAmount = Math.max(...usdAmounts)
          let displayCurrency = link.displayed_currency === 'local' ? undefined : link.displayed_currency
          let rate = await Currency.rate({ from: 'USD', to: displayCurrency })
          let price = Decimal.div(usdMaxAmount, rate).mul(quantity).toNumber()
          setDisplayedEach((new Currency({ amount: Decimal.div(usdMaxAmount, rate).toNumber(), code: displayCurrency })).toString())
          setDisplayedVat(link.included_vat ? (new Currency({ amount: price/100*parseFloat(link.included_vat), code: displayCurrency })).toString() : null)
          setDisplayedTotal((new Currency({ amount: price, code: displayCurrency })).toString())
        })
      }
    } else if (link?.amounts && link.amounts.length && !link.price) {
      Promise.all(account.accepted.map((payment)=>{
        let USD = Blockchains[payment.token.blockchain].stables.usd.find((stable)=>stable===payment.token.address)
        if(USD) { USD = Blockchains[payment.token.blockchain].tokens.find((token)=>token.address===USD) }
        let amount = link.amounts.find((amount)=>{ return amount.token.blockchain === payment.token.blockchain && amount.token.address === payment.token.address  })?.amount
        if(!amount || amount === 0) { return Promise.resolve([]) }
        if(USD){
          return Promise.resolve([{
            amountOut: ethers.utils.parseUnits(amount, USD.decimals),
            exchange: {
              blockchain: payment.token.blockchain
            },
            decimals: USD.decimals,
          }])
        } else {
          return Web3Exchanges.route({
            blockchain: payment.token.blockchain,
            tokenIn: payment.token.address,
            tokenOut: Blockchains[payment.token.blockchain].stables.usd[0],
            amountIn: parseFloat(amount)
          })
        }
      })).then(async (routes)=>{
        if(routes && routes.length) {
          let usdAmounts = routes.map((routes)=>{
            return ethers.utils.formatUnits(
              routes[0].amountOut,
              routes[0].decimals || Blockchains[routes[0].blockchain].tokens.find((token)=>token.address===Blockchains[routes[0].blockchain].stables.usd[0]).decimals,
            )
          })
          let usdMaxAmount = Math.max(...usdAmounts)
          let displayCurrency = link.displayed_currency === 'local' ? undefined : link.displayed_currency
          let rate = await Currency.rate({ from: 'USD', to: displayCurrency })
          let price = (usdMaxAmount/rate)*quantity
          setDisplayedEach((new Currency({ amount: (usdMaxAmount/rate), code: displayCurrency })).toString())
          setDisplayedVat(link.included_vat ? (new Currency({ amount: price/100*parseFloat(link.included_vat), code: displayCurrency })).toString() : null)
          setDisplayedTotal((new Currency({ amount: price, code: displayCurrency })).toString())
        }
      })
    } else {
      setDisplayedTotal()
    }
  }

  useEffect(()=>{
    updatePrice(link)
  }, [quantity])

  useEffect(()=>{
    if(link){
      setBackgroundColor(link.configuration?.style?.colors?.primary || account.color || '#ea357a')
      setTextColor(link.configuration?.style?.colors?.buttonText || (account.color ? (LightOrDark(account.color) == 'light' ? '#000000' : '#FFFFFF') : undefined) || '#FFFFFF')
    }
  }, [link])

  useEffect(()=>{
    if(link && link.currency) {
      let displayCurrency = link.displayed_currency === 'local' ? undefined : link.displayed_currency
      Currency.rate({ from: link.currency, to: displayCurrency }).then((_displayCurrencyRate)=>{
        setDisplayCurrencyRate(_displayCurrencyRate)
      })
    }
    if(link && link.requires_quantity) {
      let selection = []
      let start = link.requires_quantity.min
      let next = start
      setQuantitySelection(link.requires_quantity)
      setQuantity(link.requires_quantity.min)
    }
  }, [debouncedLink])

  useEffect(()=>{
    if(!link) { return }
    let displayCurrency = link.displayed_currency === 'local' ? undefined : link.displayed_currency
    if(link?.configuration?.suggestion1 && displayCurrencyRate) {
      let amount = parseFloat(link.configuration.suggestion1)
      amount /= displayCurrencyRate
      amount = Math.ceil(amount)
      setSuggestedAmount1(amount)
      setDisplayedSuggestedAmount1(new Currency({ amount, code: displayCurrency }).toString({ minimumFractionDigits: 0 }))
    } else {
      setSuggestedAmount1()
      setDisplayedSuggestedAmount1()
    }
    if(link?.configuration?.suggestion2 && displayCurrencyRate) {
      let amount = parseFloat(link.configuration.suggestion2)
      amount /= displayCurrencyRate
      amount = Math.ceil(amount)
      setSuggestedAmount2(amount)
      setDisplayedSuggestedAmount2(new Currency({ amount, code: displayCurrency }).toString({ minimumFractionDigits: 0 }))
    } else {
      setSuggestedAmount2()
      setDisplayedSuggestedAmount2()
    }
    if(link?.configuration?.suggestion3 && displayCurrencyRate) {
      let amount = parseFloat(link.configuration.suggestion3)
      amount /= displayCurrencyRate
      amount = Math.ceil(amount)
      setSuggestedAmount3(amount)
      setDisplayedSuggestedAmount3(new Currency({ amount, code: displayCurrency }).toString({ minimumFractionDigits: 0 }))
    } else {
      setSuggestedAmount3()
      setDisplayedSuggestedAmount3()
    }
  }, [link, displayCurrencyRate])

  useEffect(()=>{
    if(debouncedLink != undefined) {
      Store.dispatch({ type: 'links/update', link: debouncedLink })
      POST(`/api/accounts/${account.id}/links/${link.id}`, debouncedLink)
    }
  }, [ debouncedLink ])

  useEffect(()=>{
    GET(`/api/accounts/${account.id}`).then((account)=>{
      Store.dispatch(({ type: 'account/change', account }))
    })
  }, [])

  useEffect(()=>{
    if(link && link.external_id) {
      let newQrCode = new QRCodeStyling({
        width: 600,
        height: 600,
        image: qrCodeImageFor(acceptedBlockchains),
        dotsOptions: {
          color: link.configuration.style.colors.qrCode,
          type: "extra-rounded"
        },
        imageOptions: {
          margin: 10
        },
        cornersSquareOptions: {
          type: 'rounded'
        }
      })
      newQrCode.update({ data: `https://link.depay.com/${link.external_id}` })
      setQrCode(newQrCode)
    }
  }, [debouncedLink, acceptedBlockchains])

  useEffect(()=>{
    if(qrCode && qrCodeRef && qrCodeRef.current) {
      qrCodeRef.current.innerHTML = ""
      qrCode.append(qrCodeRef.current)
    }
  }, [qrCode])

  useEffect(()=>{
    if(!link){ return }

    let invalidTokensConfiguration = false
    let invalidPriceConfiguration = false
    let invalidAmountConfiguration = false
    let invalidReceiverConfiguration = false

    if(link?.configuration?.acceptedPayments) {
      invalidTokensConfiguration = !link?.configuration?.acceptedPayments.length
    } else {
      invalidTokensConfiguration = !account?.accepted?.length
    }

    if(!invalidTokensConfiguration) {

      if(link.type === 'payment') {
        if(link.currency) {
          invalidPriceConfiguration = (!link.price || link.price <= 0)
        } else { // denominated in token
          if(link?.configuration?.acceptedPayments) {
            invalidAmountConfiguration = !link?.configuration?.acceptedPayments || link?.configuration?.acceptedPayments.length <= 0 || !!link.configuration.acceptedPayments.find((accepted)=>(!accepted.amount || parseFloat(accepted.amount) <= 0))
          } else {
            invalidAmountConfiguration = link.amounts && (!account.accepted || account.accepted.length <= 0 || !!link.amounts.find((accepted)=>(!accepted.amount || parseFloat(accepted.amount) <= 0))) || !link.amounts
          }
        }
      } else { // user can choose amount
      }

      const acceptedConfiguration = link?.configuration?.acceptedPayments || account?.accepted
      invalidReceiverConfiguration = !!(acceptedConfiguration || []).find((configuration)=>{
        return !configuration.receiver || !isValidAddress(configuration?.token?.blockchain, configuration.receiver)
      })
    }

    setInvalidTokensConfiguration(invalidTokensConfiguration)
    setInvalidPriceConfiguration(invalidPriceConfiguration)
    setInvalidAmountConfiguration(invalidAmountConfiguration)
    setInvalidReceiverConfiguration(invalidReceiverConfiguration)

    setInvalidConfiguration(
      invalidTokensConfiguration ||
      invalidPriceConfiguration ||
      invalidAmountConfiguration ||
      invalidReceiverConfiguration
    )
  }, [link])

  useEffect(()=>{ updatePrice(debouncedLink) }, [debouncedLink])

  useEffect(()=>{
    GET(`/api/accounts/${account.id}/links`).then((links)=>{
      Store.dispatch({ type: 'links/set', links })
      const link = Object.assign({}, links.find((link)=>link.id == id))
      if(link.configuration?.style == undefined) {
        if(!link.configuration) { link.configuration = {} }
        link.configuration.style = {
          colors: {
            qrCode: '#000000'
          }
        }
      }
      if(link.requires_name == undefined) {
        link.requires_name = false
      }
      if(link.requires_email == undefined) {
        link.requires_email = false
      }
      if(link.confirmation_email_account == undefined) {
        link.confirmation_email_account = false
      }
      if(link.confirmation_email_users == undefined) {
        link.confirmation_email_users = false
      }
      let promises = []
      if(link.purchase_image) {
        promises.push(
          GET(`/api/accounts/${account.id}/uploads/preview?key=${link.purchase_image}`).then((presigned_url)=>{
            link.purchase_image_url = presigned_url
          })
        )
      }
      if(link.receiver_image) {
        promises.push(
          GET(`/api/accounts/${account.id}/uploads/preview?key=${link.receiver_image}`).then((presigned_url)=>{
            link.receiver_image_url = presigned_url
          })
        )
      }
      if(promises.length) {
        Promise.all(promises).then(()=> {
          setLink(link)
        })
      } else {
        setLink(link)
      }
      setAcceptedBlockchains([...new Set((link.configuration?.acceptedPayments || account?.accepted || []).map((payment)=>payment?.token?.blockchain))])
    })
  }, [])



  if(link == null) { return null }

  return(
    <div className="screen links-screen">

      { link.requires_quantity && 
        <ChangeQuantityDialog
          open={ showQuantityDialog }
          close={ ()=> setShowQuantityDialog(false) }
          primaryColor={ backgroundColor }
          textColor={ textColor }
          min={ link?.requires_quantity?.min }
          max={ link?.requires_quantity?.max }
          limit={ link?.limited_quantity }
          quantity={ quantity }
          update={ (value)=>setQuantity(value) }
        />
      }

      <Dialog
        open={ showDiscountsDialog }
        close={ ()=> setShowDiscountsDialog(false) }
        header={ 'Receive a Discount' }
        body={
          <div>
            <div>
              <div>
                { link && link.discounts && link.discounts.length &&
                  <div className="mb-1">
                    { link.discounts.some((discount)=>discount.codes.length > 0) && 
                      <div className="card mb-1 p-1 flex-column">
                        <div className="px-2 py-2 d-flex w-100">
                          <div className="text-black-50 px-1">
                            By entering a valid discount code
                          </div>
                        </div>
                      </div>
                    }
                    { link.discounts.some((discount)=>discount.tokens.length > 0) && 
                      <div className="card mb-1 p-1 flex-column">
                        <div className="px-2 py-2 d-flex w-100">
                          <div className="text-black-50 px-1">
                            By owning one of the following tokens
                          </div>
                        </div>
                        { link.discounts.reduce((accumulator, currentValue)=>[...accumulator, ...currentValue.tokens], []).map((token, index)=>{
                          let blockchain = Blockchains.findByName(token.blockchain)
                          return (
                            <a href={ token.link || blockchain.explorerUrlFor({ token: token.address }) } key={ index } className="link-dark card-hover rounded-2 px-1 py-2 d-flex w-100" target="_blank">
                              <div className="pe-1 ps-1 d-flex align-items-center position-relative">
                                { token.image && 
                                  <img className="rounded-circle token-image-medium" src={ token.image } />
                                }
                                { !token.image && 
                                  <TokenImage className="rounded-circle token-image-medium" blockchain={ token.blockchain } address={ token.address } id={ token.id } />
                                }
                                <img title={ `on ${blockchain.label}` } className="position-absolute rounded-1 shadow-sm" style={{ backgroundColor: blockchain.logoBackgroundColor, height: '1.3rem', width: '1.3rem', padding: '0.1rem', bottom: 0, right: 0 }} src={ blockchain.logo } />
                              </div>
                              <div className="d-flex align-items-center ps-3">
                                <div className="lh-sm">
                                  { !token.symbol &&
                                    <div>
                                      <div><strong>{ token.name }</strong></div>
                                    </div>
                                  }
                                  { token.symbol &&
                                    <div>
                                      <div><strong>{ token.symbol }</strong></div>
                                      <div>{ token.name }</div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </a>
                          )
                        } ) }
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        }
        footer={
          <div className="p-2 text-center">
            {
              link.discounts && link.discounts.filter((discount)=>discount.codes && discount.codes.length).length > 0 &&
              <div className="mb-1">
                <div><input className="form-control font-size-m w-100 text-center mb-2" placeholder="Enter discount code"/></div>
                <div>
                  <button type="button" onClick={ ()=>{alert('You can only test applying discount codes visiting the actual payment link.')} } className="btn rounded-2 btn-lg py-2 mb-2 btn-primary w-100" style={{ background: link?.configuration?.style?.colors?.primary, borderColor: link?.configuration?.style?.colors?.primary }}>
                    <div className="py-1 w-100" style={{ color: link?.configuration?.style?.colors?.buttonText  || (account.color ? (LightOrDark(account.color) == 'light' ? '#000000' : '#FFFFFF') : undefined) || "#FFFFFF" }}>
                      Apply discount code
                    </div>
                  </button>
                </div>
              </div>
            }
          </div>
        }
      />

      <div className="row">

        <div className="col-lg-5">

          <form className="pb-lg-5" onSubmit={(event)=>{ event.preventDefault() }}>

            { (link.receiver_name?.length > 0 || link.receiver_image?.length > 0) &&
              <div className="form-group pb-3">
                <div>
                  <label htmlFor="sellerName" className="pb-1 user-select-none">
                    <h5 className="m-0 p-0 line-height-small">Who</h5>
                    <div>
                      { link.type == 'payment' && <small className="text-muted">sells the good or provides the service?</small> }
                      { link.type == 'donation' && <small className="text-muted">is raising a donation?</small> }
                    </div>
                  </label>
                </div>
                <div className="pt-1 pb-1">
                  <input 
                    required
                    autoFocus={ typeof link.receiver_name == 'undefined' }
                    type="text"
                    aria-describedby="sellerNameHelp"
                    placeholder={
                      (
                        link.type == 'payment' ? 
                        "Name of the seller or provider"
                        : (link.type == 'donation') ? 
                        "Name of the charity or foundation"
                        : null
                      )
                    }
                    id="sellerName"
                    name="sellerName"
                    className="form-control font-size-s"
                    value={ link.receiver_name || '' } 
                    onChange={ (event)=>{
                      updateLink({ receiver_name: event.target.value })
                    }}
                  />
                </div>
                <div className='pt-2 px-1 text-center col-5'>
                  <FileUploader
                    className="ratio ratio-1x1 pb-2"
                    label="Click or drop an image, max. 1 MB, square, min. 200x200px"
                    name="receiverImage"
                    types={["jpg", "png", "gif"]}
                    onUploadStart={()=>{
                      updateLink({ receiver_image_url: '' })
                    }}
                    onChange={(key)=>{ 
                      updateLink({ receiver_image: key }) 
                      GET(`/api/accounts/${account.id}/uploads/preview?key=${key}`).then((presigned_url)=>{
                        updateLink({ receiver_image: key, receiver_image_url: presigned_url })
                      })
                    }}
                  />
                </div>
              </div>
            }
            
            <div className="form-group pb-2">
              <div>
                <label htmlFor="purchaseName" className="pb-1 user-select-none">
                  <h5>What</h5>
                  <div>
                    { link.type == 'payment' && <small className="text-muted">What are people paying for?</small> }
                    { link.type == 'donation' && <small className="text-muted">What are people donating for?</small> }
                    { link.type == 'tip' && <small className="text-muted">What are people tipping for?</small> }
                  </div>
                </label>
              </div>
              <div className="pb-1">
                <input 
                  required
                  type="text"
                  placeholder={
                    (
                      link.type == 'payment' ? 
                      "Name of the product or service"
                      : (link.type == 'donation') ? 
                      "Name of the cause or project"
                      : (link.type == 'tip') ? 
                      "Name of the cause or receiver"
                      : null
                    )
                  }
                  id="purchaseName"
                  name="purchaseName"
                  className="form-control font-size-s"
                  value={ link.purchase_name || '' } 
                  onChange={ (event)=>{
                    updateLink({ purchase_name: event.target.value })
                  }} 
                />
              </div>
              <div className='pt-2 px-1 text-center col-5'>
                <FileUploader 
                  className="ratio ratio-1x1 pb-2"
                  label="Click or drop an image, max. 1 MB, square, min. 500x500px"
                  name="purchaseImage"
                  types={["jpg", "png", "gif"]} 
                  onUploadStart={()=>{
                    updateLink({ purchase_image_url: '' })
                  }}
                  onChange={(key)=>{ 
                    updateLink({ purchase_image: key })
                    GET(`/api/accounts/${account.id}/uploads/preview?key=${key}`).then((presigned_url)=>{
                      updateLink({ purchase_image: key, purchase_image_url: presigned_url })
                    })
                  }}
                />
              </div>
            </div>

            { link.type == 'payment' &&
              <div className="form-group pb-2">
                <div className="pt-3">
                  <label htmlFor="purchaseDescription" className="user-select-none">
                    <h5>Description</h5>
                    <div>
                      <small className="text-muted">More detailed information for customers (optional):</small>
                    </div>
                  </label>
                </div>
                <div className="pt-1 pb-1">
                  <textarea
                    style={{ maxHeight: '170px' }}
                    id="purchaseDescription"
                    name="purchaseDescription"
                    className="form-control font-size-s"
                    value={ link.purchase_description || '' } 
                    onChange={ (event)=>{
                      updateLink({ purchase_description: event.target.value })
                    }} 
                  />
                </div>
              </div>
            }

            { link.type == 'payment' &&
              <div className="form-group pb-2 pt-1">
                <div className="pt-3">
                  <label className="pb-1 user-select-none">
                    <h5>Price</h5>
                  </label>
                </div>
                <div className="pt-1 pb-1">
                  <div className="form-check pb-1">
                    <label className="form-check-label user-select-none">
                      <input className="form-check-input" type="radio" name="priceDenominatedIn" checked={ !!link.currency } onChange={ ()=>{ 
                        let newLink = JSON.parse(JSON.stringify(link))
                        newLink.configuration.acceptedPayments = newLink.configuration?.acceptedPayments?.map((payment)=>{ return { token: payment.token, receiver: payment.receiver }})
                        updateLink({...newLink, currency: link.currency || 'USD', price: '' })
                        updatePrice({...newLink, currency: link.currency || 'USD', price: '' })
                      }}/>
                      Denominated in a currency
                    </label>
                  </div>
                  <div className="form-check pb-1">
                    <label className="form-check-label user-select-none">
                      <input className="form-check-input" type="radio" name="priceDenominatedIn" checked={ !link.currency } onChange={ ()=>{ 
                        updateLink({ currency: undefined, price: undefined })
                        updatePrice({ currency: undefined, price: undefined })
                      }}/>
                      Denominated per token
                    </label>
                  </div>
                </div>
                {
                  link.currency &&
                  <div className="pt-1 pb-1 row">
                    <div className="col-4">
                      <select className="form-select" value={ link.currency } onChange={ (event)=>{ 
                        updateLink({ currency: event.target.value }) 
                      }}>
                        { all.map((currency)=>{
                            return(
                              <option key={ currency } value={ currency }>{ currency }</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="col-8 ps-2">
                      <input
                        required
                        className="form-control w-100"
                        placeholder="Enter price"
                        ref={priceInputElement}
                        onChange={ (event)=>{ 
                          updateLink({ price: event.target.value }) 
                        }}
                        onBlur={ (event)=>{ 
                          let value = event.target.value
                          if(value && value.length) {
                            updateLink({ price: parseFloat(value).toFixed(2) }) 
                          }
                        }}
                        type="number"
                        step="1"
                        value={ link.price || '' }
                      />
                    </div>
                  </div>
                }

                <div className="pb-1 pt-3">
                  <label className="form-check-label user-select-none w-100">
                    Displayed currency
                    <select className="form-select mt-1 w-100" value={ link.displayed_currency || 'local' } name="displayedPrice" onChange={ (event)=>{ 
                      updateLink({ displayed_currency: event.target.value }) 
                    }}>
                      <option key="local" value="local">User's local currency (recommended)</option>
                      { all.map((currency)=>{
                          return(
                            <option key={ currency } value={ currency }>{ currency }</option>
                          )
                        })
                      }
                    </select>
                  </label>
                </div>

                <div className="pt-3">

                  { link.type == 'payment' &&
                    <div className="form-check">
                      <label className="form-check-label user-select-none">
                        <input className="form-check-input" type="checkbox" defaultChecked={link.requires_quantity} onChange={ ()=>{ 
                          if(link.requires_quantity) {
                            updateLink({ requires_quantity: null })
                            setQuantity(1)
                          } else {
                            updateLink({ requires_quantity: { min: 1, max: 10, step: 1 } })
                          }
                        }}/> Let customers adjust quantity
                      </label>
                      { link.requires_quantity &&
                        <div className="pl-2 pt-1">
                          <span className="font-size-s pe-1">Between</span>
                          <input onChange={(event)=>{
                            updateLink({ requires_quantity: {...link.requires_quantity, min: parseInt(event.target.value, 10), max: Math.max(parseInt(event.target.value, 10), link.requires_quantity?.max)} })
                          }}
                          required style={{ width: "60px" }} className="d-inline text-center form-control form-control-sm mb-1 py-1 px-2 rounded-1" placeholder="min." type="number" min="1" value={link.requires_quantity.min}/>
                          <span className="font-size-s px-2">and</span>
                          <input onChange={(event)=>{
                            updateLink({ requires_quantity: {...link.requires_quantity, max: parseInt(event.target.value, 10), min: Math.min(parseInt(event.target.value, 10), link.requires_quantity?.min)} })
                          }}
                          required style={{ width: link.requires_quantity?.max >= 10000000 ? "120px" : "100px" }} className="d-inline text-center form-control form-control-sm mb-1 py-1 px-2 rounded-1" placeholder="max." type="number" value={link.requires_quantity.max}/>
                        </div>
                      }
                    </div>
                  }

                  <div className="form-check pb-1 pt-1">
                    <label className={`form-check-label user-select-none ${(account.billing_address_country==undefined) ? 'text-black-50' : ''}`}>
                      <input className="form-check-input" disabled={ account.billing_address_country==undefined } type="checkbox" defaultChecked={link.included_vat} onChange={ ()=>{
                        updateLink({ included_vat: link.included_vat ? undefined : DEFAULT_RATES[account.billing_address_country] })
                      }}/> Include VAT
                    </label>
                    { link.included_vat != undefined &&
                      <label className="pt-1 pb-1 d-flex align-items-center font-size-s">
                        <input
                          required
                          className="form-control form-control-sm"
                          placeholder="Enter VAT %"
                          onChange={ (event)=>{ 
                            updateLink({ included_vat: event.target.value }) 
                          }}
                          onBlur={ (event)=>{ 
                            let value = event.target.value
                            if(value && value.length && value.split('.')[1] && value.split('.')[1].length > 2) {
                              updateLink({ included_vat: parseFloat(value).toFixed(2) }) 
                            }
                          }}
                          style={{ width: '80px' }}
                          type="number"
                          step="0.1"
                          value={ link.included_vat || '' }
                        />
                        <span className="ps-1">%</span>
                      </label>
                    }
                    { (account.billing_address_country != undefined && link.included_vat != undefined) &&
                      <div>
                        <div className="alert alert-light p-2 px-3 line-height-extra-small mb-0">
                          <small className="">
                            <i className="fas fa-exclamation-circle me-2 opacity-50"></i>
                            { `DePay will display VAT to customers located in ${CountryNames[account.billing_address_country]}.` }
                          </small>
                        </div>
                      </div>
                    }
                  </div>


                  { (account.billing_address_country == undefined) &&
                    <div>
                      <div className="alert alert-info p-2 px-3 line-height-extra-small mb-0">
                        <small className="">
                          <i className="fas fa-exclamation-circle me-2"></i>
                          You need to set your account billing country before you can display VAT:
                          <NavLink className="link-blue ms-1" to="/settings/billing">
                            Set billing country
                          </NavLink>
                        </small>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }

            <div className="form-group pb-2">
              <div className="pt-3">
                <label className="pb-1 user-select-none position-relative">
                  <a name="tokens" className="anchor"/>
                  <h5 ref={tokensHeadlineElement}>Tokens</h5>
                </label>
              </div>
              <div className='line-height-extra-small pt-1'>
                <small className="form-text text-muted">
                  { link.type == 'payment' && "Please select the tokens that you would like to receive as means of payment:" }
                  { link.type == 'donation' && "Please select the tokens that you would like to receive as donation:" }
                  { link.type == 'tip' && "Please select the tokens that you would like to receive:" }
                </small>
              </div>

              <div className="pt-3 pb-2">
                <AcceptedPayments
                  acceptedPayments={ link.configuration?.acceptedPayments }
                  amounts={ link.amounts }
                  withAmounts={ !link.currency }
                  onChange={ (accepted)=>{
                    if(link.configuration?.acceptedPayments || accepted?.length === 0 || !account?.accepted || account.accepted.length == 0) {
                      let newLink = JSON.parse(JSON.stringify(link))
                      if(!newLink.configuration) {
                        newLink.configuration = {}
                      }
                      newLink.configuration.acceptedPayments = accepted
                      newLink.amounts = undefined
                      setLink(newLink)
                    } else {
                      let newLink = JSON.parse(JSON.stringify(link))
                      if(!newLink.configuration) {
                        newLink.configuration = {}
                      }
                      newLink.configuration.acceptedPayments = undefined
                      newLink.amounts = accepted.map((configuration)=>{
                        return({
                          amount: configuration.amount,
                          token: configuration.token
                        })
                      })
                      setLink(newLink)
                    }
                  }}
                />
              </div>
            </div>

            { (link.type == 'donation' || link.type == 'tip') &&
              <div className="form-group pb-2 pt-3">
                <div>
                  <label className="pb-1 pt-2 user-select-none">
                    <h5>Amounts</h5>
                  </label>
                </div>
                <div className="pt-1 pb-1">
                  <div>
                    Currency
                  </div>
                  <div className="col-4">
                    <select className="form-select" value={ link.currency } onChange={ (event)=>{ 
                      updateLink({ currency: event.target.value }) 
                    }}>
                      { all.map((currency)=>{
                          return(
                            <option key={ currency } value={ currency }>{ currency }</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
                <div className="pt-2">
                  <label htmlFor="donationSuggestion1" className="pb-1 user-select-none">
                    1st suggestion 
                  </label>
                </div>
                <div className="pb-1">
                  <input
                    type="number"
                    placeholder="5.00"
                    step="1"
                    pattern="\d*"
                    id="donationSuggestion1"
                    className="form-control font-size-s"
                    value={ link?.configuration?.suggestion1 || '' }
                    onChange={ (event)=>{
                      updateLink({ configuration: Object.assign({}, link.configuration, { suggestion1: event.target.value })})
                    }}
                    onBlur={ (event)=>{
                      updateLink({ configuration: Object.assign({}, link.configuration, { suggestion1: Math.ceil(parseFloat(event.target.value)) })})
                    }}
                  />
                </div>
                <div className="pt-2">
                  <label htmlFor="donationSuggestion2" className="pb-1 user-select-none">
                    2nd suggestion 
                  </label>
                </div>
                <div className="pb-1">
                  <input
                    type="number"
                    placeholder="10.00"
                    step="1"
                    pattern="\d*"
                    id="donationSuggestion2"
                    className="form-control font-size-s"
                    value={ link?.configuration?.suggestion2 || '' }
                    onChange={ (event)=>{
                      updateLink({ configuration: Object.assign({}, link.configuration, { suggestion2: event.target.value })})
                    }}
                    onBlur={ (event)=>{
                      updateLink({ configuration: Object.assign({}, link.configuration, { suggestion2: Math.ceil(parseFloat(event.target.value)) })})
                    }}
                  />
                </div>
                <div className="pt-2">
                  <label htmlFor="donationSuggestion3" className="pb-1 user-select-none">
                    3rd suggestion 
                  </label>
                </div>
                <div className="pb-1">
                  <input
                    type="number"
                    placeholder="50.00"
                    step="1"
                    pattern="\d*"
                    id="donationSuggestion3"
                    className="form-control font-size-s"
                    value={ link?.configuration?.suggestion3 || '' }
                    onChange={ (event)=>{
                      updateLink({ configuration: Object.assign({}, link.configuration, { suggestion3: event.target.value })})
                    }}
                    onBlur={ (event)=>{
                      updateLink({ configuration: Object.assign({}, link.configuration, { suggestion3: Math.ceil(parseFloat(event.target.value)) })})
                    }}
                  />
                </div>

                <div className="pb-1 pt-3">
                  <label className="form-check-label user-select-none w-100">
                    Displayed currency
                    <select className="form-select mt-1 w-100" value={ link.displayed_currency || 'local' } name="displayedPrice" onChange={ (event)=>{ 
                      updateLink({ displayed_currency: event.target.value })
                    }}>
                      <option key="local" value="local">User's local currency (recommended)</option>
                      { all.map((currency)=>{
                          return(
                            <option key={ currency } value={ currency }>{ currency }</option>
                          )
                        })
                      }
                    </select>
                  </label>
                </div>
              </div>
            }

            <div className="form-group pb-2 pt-3">
              <div>
                <label className="pb-1 pt-2 user-select-none">
                  <h5>Colors</h5>
                </label>
              </div>
              <div className="pt-1 pb-1">
                <label className="d-inline-flex align-items-center user-select-none">
                  <input name='primary' className="d-inline-block me-2 shadow overflow-hidden" type="color" value={ link?.configuration?.style?.colors?.primary || account.color || '#ea357a' }
                    onChange={ (event)=>{
                      let primary = event.target.value
                      let buttonText = LightOrDark(primary) == 'light' ? '#000000' : '#FFFFFF'
                      setLink({...link, configuration: {...link.configuration, style: {...link.configuration.style, colors: {...link.configuration.style.colors, primary, buttonText}}}})
                    }}
                  />
                  <span className="d-inline-block ms-1">Primary</span>
                </label>
              </div>
              <div className="pt-1 pb-1">
                <label className="d-inline-flex align-items-center user-select-none">
                  <input name='qrcode' className="d-inline-block me-2 shadow overflow-hidden" type="color" value={ link.configuration.style.colors.qrCode } 
                    onChange={ (event)=>{
                      setLink({...link, configuration: {...link.configuration, style: {...link.configuration.style, colors: {...link.configuration.style.colors, qrCode: event.target.value}}}})
                    }}
                  />
                  <span className="d-inline-block ms-1">QR Code</span>
                </label>
              </div>
            </div>

            <div className="form-group pb-2 pt-2">
              <div className="pt-3">
                <label className="pb-1 user-select-none">
                  <h5>Collect Data</h5>
                </label>
              </div>
              <div className="pt-1 pb-1">
                <div className="form-check pb-1">
                  <label className="form-check-label user-select-none">
                    <input className="form-check-input" type="checkbox" defaultChecked={link.requires_name} onChange={ ()=>{
                      updateLink({ requires_name: !link.requires_name })
                    }}/>
                    Name
                  </label>
                </div>
                <div className="form-check pb-1">
                  <label className="form-check-label user-select-none">
                    <input className="form-check-input" type="checkbox" defaultChecked={link.requires_email} onChange={ ()=>{ 
                      updateLink({ requires_email: !link.requires_email, confirmation_email_users: false })
                    }}/>
                    E-Mail
                  </label>
                </div>
                <div className="form-check pb-1">
                  <label className="form-check-label user-select-none">
                    <input className="form-check-input" type="checkbox" checked={link.requires_billing_address || link.requires_country} onChange={ (event)=>{
                      if(link.requires_country) {
                        updateLink({ requires_billing_address: false, requires_shipping_address: false, requires_country: false })
                      } else {
                        updateLink({ requires_billing_address: !link.requires_billing_address, requires_shipping_address: false, requires_country: false })
                      }
                    }}/>
                    Address
                  </label>
                  {
                    (link.requires_billing_address || link.requires_country) &&
                    <div className="pt-1 font-size-s">
                      <div className="form-check">
                        <label className="form-check-label user-select-none">
                          <input className="form-check-input" type="radio" name="address" checked={link.requires_billing_address  && !link.requires_shipping_address} onChange={ ()=>{ 
                            updateLink({ requires_billing_address: true, requires_shipping_address: false, requires_country: false })
                          }}/>
                          Billing address only
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label user-select-none">
                          <input className="form-check-input" type="radio" name="address" checked={link.requires_billing_address && link.requires_shipping_address} onChange={ ()=>{ 
                            updateLink({ requires_billing_address: true, requires_shipping_address: true, requires_country: false })
                          }}/>
                          Shipping and billing address
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label user-select-none">
                          <input className="form-check-input" type="radio" name="address" checked={link.requires_country && !link.requires_billing_address  && !link.requires_shipping_address} onChange={ ()=>{ 
                            updateLink({ requires_billing_address: false, requires_shipping_address: false, requires_country: true })
                          }}/>
                          Country only
                        </label>
                      </div>
                    </div>
                  }
                </div>
                { link && link.custom_fields && link.custom_fields.length > 0 && link.custom_fields.map((customField, index)=>(
                  <div key={ index }>
                    <div className="row mt-1 mb-2 custom-field-group">
                      <div className="col-10">
                        <div className="input-group">
                          <select value={ customField.type } className="form-select form-select-md col-2" onChange={ (event)=>updateCustomField(index, 'type', event.target.value) }>
                            <option value="string">Text</option>
                            <option value="number">Number</option>
                          </select>
                          <input style={{ flex: '40%' }} value={ customField.name } className="form-control form-control-md  col-10" type="text" placeholder="Field name" onChange={ (event)=>updateCustomField(index, 'name', event.target.value) } />
                        </div>
                        <div className="input-group">
                          <TextareaAutosize className="form-control font-size-s" value={ customField.caption } rows="1" placeholder="Caption (optional)" onChange={ (event)=>updateCustomField(index, 'caption', event.target.value) } />
                        </div>
                      </div>
                      <div className="col-2">
                        <button title="Remove" type="button" className="btn btn-sm btn-grey font-size-xs mt-1" onClick={ ()=> {
                          event.preventDefault
                          removeCustomField(index)
                          return false
                        } }>
                          <i className="far fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
                <button type="button" className="btn btn-action btn-sm me-3 mt-2" onClick={ addCustomField }>
                  <i className="fas fa-plus me-2"></i>
                  Add Custom Field
                </button>
              </div>
            </div>

            <div className="form-group pb-2 pt-2">
              <div className="pt-3">
                <label className="pb-1 user-select-none">
                  <h5>Unlock</h5>
                </label>
              </div>
              <div className="pt-1 pb-1">
                <div className="form-check pb-1">
                  <label className="form-check-label user-select-none">
                    <input className="form-check-input" type="checkbox" defaultChecked={link.unlock_url != null} onChange={ ()=>{ 
                      if(link.unlock_url != null) {
                        updateLink({ unlock_url: null })
                      } else {
                        updateLink({ unlock_url: '', redirect: null })
                      }
                    }}/>
                    Download Link or URL
                  </label>
                </div>
                { link.unlock_url != null &&
                  <div>
                    <div className='pt-2 pb-1'>
                      <input type="url" id="unlockUrl" required className="d-block form-control" placeholder="Enter Link or URL" type="text" value={ link.unlock_url }
                        onChange={ (event)=>{
                          updateLink({ unlock_url: event.target.value.trim() })
                        }} 
                      />
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="form-group pb-2 pt-2">
              <div className="pt-3">
                <label className="pb-1 user-select-none">
                  <h5>Afterwards</h5>
                </label>
              </div>
              <div className="pt-1 pb-1">
                <div className="form-check pb-1">
                  <label className="form-check-label user-select-none">
                    <input className="form-check-input" checked={ link.redirect == undefined } type="radio" name="redirectAfterwards" onChange={ ()=>{ 
                      updateLink({ redirect: undefined })
                    }}/>
                    Show a confirmation
                  </label>
                </div>
                <div className="form-check pb-1">
                  <label className="form-check-label user-select-none">
                    <input disabled={ link.unlock_url != null } className="form-check-input" checked={ link.redirect != undefined } type="radio" name="redirectAfterwards" onChange={ ()=>{ 
                      updateLink({ redirect: '' })
                      setTimeout(()=>{
                        if(redirectRef.current) { redirectRef.current.focus() }
                      }, 50)
                    }}/>
                    Redirect users to another website
                  </label>
                </div>
                { link.redirect != undefined &&
                  <div className="pb-1">
                    <input 
                      ref={ redirectRef }
                      required
                      type="text"
                      placeholder="URL to redirect users to after payment"
                      className="form-control font-size-s"
                      value={ link.redirect || '' } 
                      onChange={ (event)=>{
                        updateLink({ redirect: event.target.value.trim() })
                      }} 
                    />
                  </div>
                }
              </div>
            </div>

            <div className="form-group pb-2 pt-2">
              <div className="pt-3">
                <label className="pb-1 user-select-none">
                  <h5>E-Mail Confirmations</h5>
                </label>
              </div>
              <div className="pt-1 pb-1">
                { link.requires_email &&
                  <div>
                    <div className="form-check pb-1">
                      <label className="form-check-label user-select-none">
                        <input className="form-check-input" type="checkbox" defaultChecked={link.confirmation_email_users} onChange={ ()=>{
                          updateLink({ confirmation_email_users: !link.confirmation_email_users })
                        }}/>
                        Send to users
                      </label>
                    </div>
                    {
                      link.unlock_url != null &&
                      link.confirmation_email_users &&
                      <div className="alert alert-light p-2 px-2 lh-sm mb-2 font-size-s">
                        <i className="fas fa-exclamation-circle me-2"></i>
                        <span>includes Download Link or URL</span>
                      </div>
                    }
                  </div>
                }
                <div className="form-check pb-1">
                  <label className={`form-check-label user-select-none ${(account.email==undefined) ? 'text-black-50' : ''}`}>
                    <input className="form-check-input" disabled={ account.email==undefined } type="checkbox" defaultChecked={link.confirmation_email_account} onChange={ ()=>{
                      updateLink({ confirmation_email_account: !link.confirmation_email_account })
                    }}/>
                    Send to my account
                  </label>
                </div>
                { (account.email == undefined) &&
                  <div>
                    <div className="alert alert-info p-2 px-3 line-height-extra-small mb-0">
                      <small className="">
                        <i className="fas fa-exclamation-circle me-2"></i>
                        You need to set your account email in order to receive confirmations:
                        <NavLink className="link-blue ms-1" to="/settings">
                          Set email now
                        </NavLink>
                      </small>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="form-group pb-2 pt-2">
              <div className="pt-3">
                <label className="pb-1 user-select-none">
                  <h5>Automation</h5>
                </label>
              </div>
              <div className="pt-1 pb-1">
                <div className="form-check pb-1">
                  <label className={`form-check-label user-select-none`}>
                    <input className="form-check-input" type="checkbox" defaultChecked={ link.callback != null } onChange={ (event)=>{
                      updateLink({ callback: link.callback == null ? '' : null })
                    }}/>
                    Send callback
                  </label>
                </div>
                { link.callback != null &&
                  <div>
                    <div className='pt-2 pb-1'>
                      <input type="url" id="callback" required className="d-block form-control" placeholder="Enter callback endpoint" type="text" value={ link.callback }
                        onChange={ (event)=>{
                          updateLink({ callback: event.target.value.trim() })
                        }} 
                      />
                    </div>
                    { link.public_key && 
                      <div className="position-relative pt-1">
                        <CopyButton value={ link.public_key }>
                          Public Key
                        </CopyButton>
                      </div>
                    }
                  </div>
                }
              </div>
            </div>

            { link.type === 'payment' &&
              <div className="form-group pb-2 pt-2">
                <div className="pt-3">
                  <label className="pb-1 user-select-none">
                    <h5>Discounts</h5>
                  </label>
                </div>
                <div className="pt-1 pb-1">
                  
                  <div className="pb-1">
                    { (link.discounts||[]).map((discount, discountIndex)=>{
                      return(
                        <div key={ discountIndex } className="mb-2 row">
                          <label className="d-flex flex-row align-items-center col-6">
                            <i className="fas fa-percent me-2" style={{ position: 'relative', top: '2px' }}></i>
                            <input value={ discount.percent } onChange={ (event)=>updateDiscount(discountIndex, event.target.value) } type='number' min="1" max="100" step="1" className="form-control fw-bold fs-5 p-1 rounded" style={{ border: 'none', background: 'transparent', width: '4.6rem', position: 'relative' }}/>
                          </label>
                          <div className="col-6 text-end">
                            <button title="Remove" type="button" className="btn btn-sm btn-grey font-size-xs" onClick={ (event)=> {
                              event.preventDefault
                              removeDiscount(discountIndex)
                              return false
                            } }>
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </div>
                          <div className="card mt-1 pb-2 pt-0 flex-column">
                            { discount.codes && discount.codes.map((code, codeIndex)=>{
                              return (
                                <div className="position-relative w-100" key={ codeIndex }>
                                  <div className="link-dark card-hover rounded-2 mt-2 px-1 py-1 d-flex w-100" target="_blank">
                                    <div className="d-flex align-items-center ps-1 col-8">
                                      <div className="lh-sm">
                                        <input className="form-control form-control-sm rounded-2" type="text" placeholder="Enter discount code" value={ code } onChange={ (event)=>updateDiscountCode(discountIndex, codeIndex, event.target.value) } required/>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="position-absolute" style={{ right: 0, top: 0, height: '100%' }}>
                                    <div className="d-flex align-items-center pe-2" style={{ height: '100%' }}>
                                      <button title="Remove" type="button" className="btn btn-sm btn-grey font-size-xs" onClick={ ()=> removeDiscountCode(discountIndex, codeIndex) } style={{ top: '2px', position: 'relative' }}>
                                        <i className="far fa-trash-alt small"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            }) }
                            { discount.tokens && discount.tokens.map((token, tokenIndex)=>{
                              let blockchain = Blockchains.findByName(token.blockchain)
                              return (
                                <div className="position-relative w-100" key={ tokenIndex }>
                                  <a href={ token.link || blockchain.explorerUrlFor({ token: token.address }) } className="link-dark card-hover rounded-2 mt-2 px-1 py-2 d-flex w-100" target="_blank">
                                    <div className="pe-1 ps-1 d-flex align-items-center position-relative">
                                      { token.image &&
                                        <img className="rounded-circle token-image-medium" src={ token.image } />
                                      }
                                      { !token.image &&
                                        <TokenImage className="rounded-circle token-image-medium" blockchain={ token.blockchain } address={ token.address } id={ token.id } />
                                      }
                                      <img title={ `on ${blockchain.label}` } className="position-absolute rounded-1 shadow-sm" style={{ backgroundColor: blockchain.logoBackgroundColor, height: '1.3rem', width: '1.3rem', padding: '0.1rem', bottom: 0, right: 0 }} src={ blockchain.logo } />
                                    </div>
                                    <div className="d-flex align-items-center ps-3 col-8">
                                      <div className="lh-sm">
                                        { token.symbol &&
                                          <div>
                                            <div><strong>{ token.symbol }</strong></div>
                                            <div>{ token.name }</div>
                                          </div>
                                        }
                                        { !token.symbol &&
                                          <div>
                                            <div><strong>{ token.name }</strong></div>
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  </a>
                                  <div className="position-absolute" style={{ right: 0, top: 0, height: '100%' }}>
                                    <div className="d-flex align-items-center pe-2" style={{ height: '100%' }}>
                                      <button title="Remove" type="button" className="btn btn-sm btn-grey font-size-xs" onClick={ ()=> removeDiscountToken(discountIndex, tokenIndex) } style={{ top: '2px', position: 'relative' }}>
                                        <i className="far fa-trash-alt small"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            }) }
                            <div className="d-block pt-2 w-100">
                              <Dropdown
                                direction='left'
                                toggle={
                                  <button title="Add a condition that applies a discount" type="button" className="btn btn-sm btn-action-hover me-1 text-black-50">
                                    <i className="fas fa-plus me-2"></i>
                                    Add Condition
                                  </button>
                                }
                                children={[
                                  ['Enters Code', ()=>{ addDiscountCode(discountIndex) }, <i className="fa-solid fa-font me-2"></i>],
                                  ['Owns Token', ()=>{ DePayWidgets.Select({ what: 'token' }).then((token)=>addDiscountToken(discountIndex, {...token })) }, <i className="fa-solid fa-circle me-2"></i>],
                                  ['Owns NFT', ()=>{ DePayWidgets.Select({ what: 'nft' }).then((token)=>addDiscountToken(discountIndex, {...token })) }, <i className="fa-solid fa-image me-2"></i>],
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <button type="button" className="btn btn-action btn-sm me-3" onClick={ addDiscount }>
                    <i className="fas fa-percent me-2"></i>
                    Add Discount
                  </button>
                </div>
              </div>
            }

            { link.type === 'payment' &&
              <div className="form-group pb-2 pt-2">
                <div className="pt-3">
                  <label className="pb-1 user-select-none">
                    <h5>Limits</h5>
                  </label>
                </div>
                <div className="pt-1 pb-1">
                  
                  <div className="form-check pb-1">
                    <label className="form-check-label user-select-none">
                      <input className="form-check-input" type="checkbox" defaultChecked={link.start_date} onChange={ ()=>{
                        updateLink({ start_date: link.start_date ? undefined : moment().add(1, 'days').toISOString() })
                      }}/>
                      Set Start Date
                    </label>
                    { link.start_date &&
                      <div className="pl-2 pt-1">
                        <input
                          onChange={(event)=>{
                            updateLink({ start_date: moment(event.target.value).toISOString() })
                          }}
                          required
                          name="start_date"
                          className="d-inline text-center form-control mb-1 py-1 px-2 rounded-1" placeholder="Start Date" type="datetime-local" value={moment(link.start_date).format('YYYY-MM-DDTHH:mm')}/>
                      </div>
                    }
                  </div>

                  <div className="form-check pb-1">
                    <label className="form-check-label user-select-none">
                      <input className="form-check-input" type="checkbox" defaultChecked={link.end_date} onChange={ ()=>{
                        updateLink({ end_date: link.end_date ? undefined : moment().add(2, 'days').toISOString() })
                      }}/>
                      Set End Date
                    </label>
                    { link.end_date &&
                      <div className="pl-2 pt-1">
                        <input
                          onChange={(event)=>{
                            updateLink({ end_date: moment(event.target.value).toISOString() })
                          }}
                          required
                          name="end_date"
                          className="d-inline text-center form-control mb-1 py-1 px-2 rounded-1" placeholder="End Date" type="datetime-local" value={moment(link.end_date).format('YYYY-MM-DDTHH:mm')}/>
                      </div>
                    }
                  </div>

                  <div className="form-check pb-1">
                    <label className="form-check-label user-select-none">
                      <input className="form-check-input" type="checkbox" defaultChecked={ link.limited_quantity != undefined } onChange={ ()=>{
                        const newLink = { limited_quantity: link.limited_quantity != undefined ? undefined : 100 }
                        if(newLink.limited_quantity === undefined) {
                          newLink.quantity_sold = undefined
                        }
                        updateLink(newLink)
                      }}/>
                      Limit quantity
                    </label>
                    { link.limited_quantity != undefined &&
                      <div className="pl-2 pt-1">
                        <input
                          type="number"
                          onChange={(event)=>{
                            let number = event.target.value.length > 0 ? parseInt(event.target.value, 10) : 0
                            if(number > 100000000000000) {
                              number = 100000000000000
                            }
                            updateLink({ limited_quantity: number })
                          }}
                          required
                          min="0"
                          max="100000000000000"
                          step="1"
                          className="d-inline text-center form-control mb-1 py-1 px-2 rounded-1"
                          placeholder="Limited quantity" 
                          name="limited_quantity"
                          value={link.limited_quantity || ''}
                        />
                      </div>
                    }
                  </div>

                </div>
              </div>
            }

          </form>
        </div>

        <div className="col-lg-7 sticky-wrapper sticky-reset">
          <div className="sticky-item">
            <div className="sticky-reset-padding">
              <div className="ps-0 ps-lg-5 pb-6">
                <div className="pt-2">

                  { invalidConfiguration === true &&
                    <div className="border-danger alert alert-info mb-2 rounded-1 px-2 py-1 d-inline-block w-100">
                      <div className="px-1 pt-1">
                        <h5 className="text-danger ms-1 flex-grow-1">Invalid configuration</h5>
                      </div>
                      <div>
                        <div className="mb-2 pt-1 w-100">
                          { invalidTokensConfiguration && <button onClick={()=>{ window.scrollTo({top: tokensHeadlineElement?.current?.getBoundingClientRect()?.top + document.documentElement.scrollTop - 70, behavior: 'instant' }) }} type="button" className="btn-action-hover px-2 ms-1 text-start line-height-extra-small py-1">Please add tokens you accept as payment</button> }
                          { invalidPriceConfiguration && <button onClick={()=>priceInputElement.current.focus()} type="button" className="btn-action-hover px-2 ms-1 text-start line-height-extra-small py-1">Please set a valid price</button> }
                          { invalidAmountConfiguration && <button onClick={()=>{ window.scrollTo({top: tokensHeadlineElement?.current?.getBoundingClientRect()?.top + document.documentElement.scrollTop - 70, behavior: 'instant' }) }} type="button" className="btn-action-hover px-2 ms-1 text-start line-height-extra-small py-1">Please set valid amounts for all tokens</button> }
                          { invalidReceiverConfiguration && <button onClick={()=>{ window.scrollTo({top: tokensHeadlineElement?.current?.getBoundingClientRect()?.top + document.documentElement.scrollTop - 70, behavior: 'instant' }) }} type="button" className="btn-action-hover px-2 ms-1 text-start line-height-extra-small py-1">Please set a valid receiver for each token</button> }
                        </div>
                      </div>
                    </div>
                  }

                  { invalidConfiguration === false &&
                    <div>
                      <h2 className="h5 pt-1 text-black opacity-50 font-size-s">
                        { link.type == 'payment' && <span>Payment </span> }
                        { link.type == 'donation' && <span>Donation </span> }
                        { link.type == 'tip' && <span>Tip </span> }
                        Link
                      </h2>

                      <div>
                        <div className="row w-100">
                          <div className="position-relative">
                            <div className="d-flex position-relative">
                              <div role="button" onClick={()=>{ 
                                var range = document.createRange();
                                range.selectNode(document.getElementById('link'))
                                window.getSelection().removeAllRanges()
                                window.getSelection().addRange(range)
                                setShowTooltip(true)
                                copy(`https://link.depay.com/${link.external_id}`)
                              }} id="link" className="border rounded-1 px-2 py-1 btn-action white">
                                <div className="fs-4 px-1 w-100 text-truncate link-button-text">
                                  { `link.depay.com/${link.external_id}` }
                                </div>
                              </div>
                              <button className='border col-2 btn btn-sm px-1 rounded-1 ms-1 btn-action white d-none d-md-inline-block' style={{ position: 'relative', height: '3.1rem', top: 0 }} 
                                onClick={()=>{
                                  setShowTooltip(true)
                                  copy(`https://link.depay.com/${link.external_id}`)
                                }} 
                                title="Click to copy"
                              >
                                <i className="far fa-copy fs-5"></i>
                              </button>
                            </div>
                            <TooltipDrawer show={showTooltip} set={setShowTooltip} text={ "Copied to clipboard" }/>
                          </div>
                        </div>
                      </div>

                      <div className="pt-2 pb-3">

                        <div className="row pt-2 pb-1">
                          <div className="col-6">
                            <h2 className="h5 pt-2 text-black opacity-50 font-size-s">
                              Preview
                            </h2>
                          </div>
                        </div>

                        <div className="rounded-2 border shadow w-100">

                          <div className="w-100">
                            <form onSubmit={(event)=>{
                              event.preventDefault()
                              alert("You can't perform the payment as part of the preview. Use the link to test the payment.")
                            }}>
                              <div className="d-flex justify-content-end h-100 px-4 pb-4 pt-2" style={{ borderTopLeftRadius: '0.8rem', borderTopRightRadius: '0.8rem', color: textColor, backgroundColor: backgroundColor }}>

                                <div className="container row pe-0 justify-content-end pt-4">

                                  <div className="position-relative pb-3 d-flex align-items-center">

                                    { (link.receiver_image || link?.receiver_name?.length > 0) &&
                                      <>
                                        { link.receiver_image_url && link.receiver_image_url != '' &&
                                          <div className="rounded-circle d-inline-block me-2" style={{ position: 'relative', top: '-2px', width: "30px", height: "30px", backgroundColor: 'rgba(0,0,0,0.3)', backgroundImage: `url(${link.receiver_image_url})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}/>
                                        }
                                        <span>{ link.receiver_name && link?.receiver_name?.length > 0 ? `${link.receiver_name}` : null }</span>
                                      </>
                                    }

                                    { !(link.receiver_image || link?.receiver_name?.length > 0) && (account.logo || account?.name?.length > 0) &&
                                      <>
                                        { account.logo && account.logo != '' &&
                                          <div className="rounded-circle d-inline-block me-2" style={{ position: 'relative', top: '-2px', width: "30px", height: "30px", backgroundColor: 'rgba(0,0,0,0.3)', backgroundImage: `url(https://uploads.depay.com/${account.logo})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}/>
                                        }
                                        <span>{ account.name && account?.name?.length > 0 ? `${account.name}` : null }</span>
                                      </>
                                    }

                                  </div>

                                  <div className="d-flex pt-2 align-items-center pb-2">

                                    { link.type === 'payment' &&
                                      <div className="pe-3">
                                        <h4 className="fs-2 mb-0 fw-bold" style={{ color: textColor }}>{ displayedTotal }</h4>
                                      </div>
                                    }

                                    { (link.type === 'donation' || link.type === 'tip') &&
                                      <div className="pe-3">
                                        <h6 className="fs-6 m-0" style={{ color: textColor }}>
                                          { link.purchase_name }
                                        </h6>
                                      </div>
                                    }

                                    { link.purchase_image_url && link.purchase_image_url != '' &&
                                      <div className="flex-grow-1 d-block text-end m-auto align-items-center pt-1">
                                        <div className="rounded-circle d-inline-block" style={{ position: 'relative', width: "68px", height: "68px", backgroundColor: 'rgba(0,0,0,0.3)', backgroundImage: `url(${link.purchase_image_url})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}/>
                                      </div>
                                    }

                                  </div>

                                  <div className="flex-grow-1">

                                    { link.type == 'payment' && 
                                      <div>
                                        <div className="pt-3">

                                          {
                                            (link.purchase_description && link.purchase_description.length > 0) &&
                                            <div className="d-flex align-items-center pb-4">
                                              <div className="flex-grow-1 lh-sm">{ link.purchase_description }</div>
                                            </div>
                                          }

                                          {
                                            (link.purchase_name && link.purchase_name.length > 0) &&
                                            <div className="d-flex align-items-center pt-1">
                                              <div className="flex-grow-1 lh-sm">{ link.purchase_name }</div>
                                              <div>{ displayedTotal }</div>
                                            </div>
                                          }

                                          { link.requires_quantity && 
                                            <div className="pb-1 pt-1 row">
                                              <div className="col-8">
                                                  
                                                { quantitySelection &&
                                                  <label className="d-inline-block text-start" onClick={()=>{setShowQuantityDialog(true)}}>
                                                    <div className={`d-inline-block position-relative ${LightOrDark(textColor) == 'light' ? "hover-target-opacity-20" : "hover-target-opacity-10"}`} role="button">
                                                      <div className="rounded-1 hover-target-opacity" style={{ position: 'absolute', backgroundColor: textColor, top: 0, bottom: 0, left: 0, right: 0 }}/>
                                                      <div className="py-1 px-2 font-size-s">
                                                        Quantity: { (new Intl.NumberFormat()).format(quantity) }
                                                      </div>
                                                    </div>
                                                  </label>
                                                }

                                              </div>
                                              <div className="col-4 text-end">
                                                { quantity && quantity > 1 &&
                                                  <span className="font-size-s opacity-50">{ displayedEach } each</span>
                                                }
                                              </div>
                                            </div>
                                          }

                                          { link.discounts && link.discounts.length > 0 &&
                                            <div className="pt-2">
                                              <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                  <div type="button" className="hover-opacity-50 font-size-s" title="Click to see available discounts" onClick={ ()=>setShowDiscountsDialog(true) }>
                                                    Discount available
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          }

                                          <div className="pt-1">
                                            
                                            { link.included_vat != undefined &&
                                              <div style={{opacity: 0.7}}>
                                                <hr style={{ borderColor: textColor }}/>
                                                <div className="d-flex align-items-center font-size-s">
                                                  <VatRow
                                                    displayedVat={ displayedVat }
                                                    link={ link }
                                                    displayCurrency={ link.displayed_currency === 'local' ? undefined : link.displayed_currency }
                                                    vatCountry={ account.billing_address_country }
                                                    selectedBillingAddressCountry={ billingAddress?.country }
                                                    selectedShippingAddressCountry={ shippingAddress?.country }
                                                    selectedCountry={ country }
                                                  />
                                                </div>
                                              </div>
                                            }

                                            <hr style={{ borderColor: textColor }}/>

                                            <div className="d-flex align-items-center">
                                              <div className="flex-grow-1">Total</div>
                                              <div>{ displayedTotal }</div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    }

                                    { (link.type === 'donation' || link.type === 'tip') &&
                                      <div className='pt-4'>
                                        { (link.type == 'donation' || link.type == 'tip') && displayedSuggestedAmount1 && 
                                          <div className="mb-3">
                                            <button onClick={()=>{ setStartAmount(suggestedAmount1) }} type="submit" className={`btn rounded-2 btn-lg py-0 btn-primary ${link.suggestion2 && 'me-2'}`} style={{ background: 'transparent', border: 'transparent' }}>
                                              <div className="py-2 w-100" style={{ color: textColor }}>
                                                { displayedSuggestedAmount1 }
                                              </div>
                                            </button>
                                          </div>
                                        }
                                        { (link.type == 'donation' || link.type == 'tip') && displayedSuggestedAmount2 && 
                                          <div className="mb-3">
                                            <button onClick={()=>{ setStartAmount(suggestedAmount2) }} type="submit" className={`btn rounded-2 btn-lg py-0 btn-primary ${link.suggestion3 && 'me-2'}`} style={{ background: 'transparent', border: 'transparent' }}>
                                              <div className="py-2 w-100" style={{ color: textColor }}>
                                                { displayedSuggestedAmount2 }
                                              </div>
                                            </button>
                                          </div>
                                        }
                                        { (link.type == 'donation' || link.type == 'tip') && displayedSuggestedAmount3 && 
                                          <div className="mb-3">
                                            <button onClick={()=>{ setStartAmount(suggestedAmount3) }} type="submit" className={`btn rounded-2 btn-lg py-0 btn-primary`} style={{ background: 'transparent', border: 'transparent' }}>
                                              <div className="py-2 w-100" style={{ color: textColor }}>
                                                { displayedSuggestedAmount3 }
                                              </div>
                                            </button>
                                          </div>
                                        }
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="container row bg-grey px-4 pt-4 rounded-bottom pb-4">

                                { link.limited_quantity != undefined  &&
                                  <div className="pb-2">
                                    <div className="pt-1 pb-1">
                                      <div className="alert d-inline-block alert-info p-2 px-3 lh-sm mb-0 font-size-s font-weight-medium">
                                        {
                                          (link.limited_quantity - (link.quantity_sold || 0)) <= 0 &&
                                          <span>Sold out</span>
                                        }
                                        {
                                          (link.limited_quantity - (link.quantity_sold || 0)) > 0 &&
                                          <span>Only { formatQuantityNumber(link.limited_quantity - (link.quantity_sold || 0)) } left</span>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                }

                                { link.start_date != undefined && moment(link.start_date).toDate() > new Date() &&
                                  <div className="pb-2">
                                    <h5 className="h5 mb-0 letter-spacing-s">
                                      Starts in
                                    </h5>
                                    <div className="pt-1" style={{ height: '60px' }}>
                                      <FlipClock value={ moment(link.start_date).toISOString() }/>
                                    </div>
                                  </div>
                                }
                                { link.end_date != undefined && (!link.start_date || moment(link.start_date).toDate() <= new Date()) &&
                                  <div className="pb-2">
                                    <h5 className="h5 mb-0 letter-spacing-s">
                                      { new Date() < moment(link.end_date).toDate() && <>Ends in</>}
                                      { new Date() >= moment(link.end_date).toDate() && <>Ended</>}
                                    </h5>
                                    <div className="pt-1" style={{ height: '60px' }}>
                                      { new Date() < moment(link.end_date).toDate() && <FlipClock value={ moment(link.end_date).toISOString() }/>}
                                      { new Date() >= moment(link.end_date).toDate() && 
                                        <div className="alert alert-info p-2 px-3 lh-sm mb-0">
                                          <i className="fas fa-exclamation-circle me-2"></i>
                                          Ended { moment(link.end_date).format('LLL') }
                                        </div>
                                      }
                                    </div>
                                  </div>
                                }

                                <h2 className="h5 mb-0 letter-spacing-s">
                                  { link.type == 'payment' && <span>Pay </span> }
                                  { link.type == 'donation' && <span>Donate </span> }
                                  { link.type == 'tip' && <span>Tip </span> }
                                  with crypto
                                </h2>

                                <div className="pt-1 pb-3">
                                  { acceptedBlockchains?.map((name, index)=>{
                                    let blockchain = Blockchains.findByName(name)
                                    return(
                                      <img key={name} className={`rounded-1 ${index > 0 ? 'ms-1' : ''}`} title={`Supports payments on ${blockchain.label}`} src={ blockchain.logo } style={{ backgroundColor: blockchain.logoBackgroundColor, boxShadow: '0 0 4px rgba(0,0,0,0.08)', width: "28px", height: "28px", padding: '1px' }}/>
                                    )
                                  }) }
                                </div>

                                { (link.requires_name || link.requires_email || link.requires_wallet_address || link.requires_billing_address || link.requires_shipping_address || link.requires_country || link.custom_fields?.length > 0) &&
                                  <div className="pb-2">
                                    { link.requires_name && 
                                      <div className="pb-1">
                                        <label className="d-block text-start">
                                          <small className="text-black-50 pb-2">Full Name</small>
                                          <input id="fullName" required className="d-block form-control mb-1 rounded-1" placeholder="Enter your full name" type="text"
                                            value={ fullName || '' }
                                            onChange={(event)=>setFullName(event.target.value)}
                                          />
                                        </label>
                                      </div>
                                    }
                                    { link.requires_email && 
                                      <div className="pb-1">
                                        <label className="d-block text-start">
                                          <small className="text-black-50 pb-2">E-Mail</small>
                                          <input id="email" required className="d-block form-control mb-1 rounded-1" placeholder="your@email.com" type="email"/>
                                        </label>
                                      </div>
                                    }
                                    { link.requires_shipping_address && 
                                      <div className="pb-1">
                                        <small className="text-black-50 pb-2">Shipping Address</small>
                                        <AddressCompletion
                                          required
                                          fullName={ fullName }
                                          id="shippingAddress"
                                          label="shipping address"
                                          value={shippingAddress}
                                          onChange={(data)=>{ setShippingAddress(data) }}
                                        />
                                      </div>
                                    }
                                    { link.requires_billing_address &&
                                      <div className="pb-1">
                                        <small className="text-black-50 pb-2">Billing Address</small>
                                        <AddressCompletion
                                          required
                                          fullName={ fullName }
                                          useShippingAddress={ !!link.requires_shipping_address }
                                          shippingAddress={ link.requires_shipping_address ? (shippingAddress || {}) : undefined }
                                          id="billingAddress"
                                          label="billing address"
                                          value={billingAddress}
                                          onChange={(data)=>{ setBillingAddress(data) }}
                                        />
                                      </div>
                                    }
                                    { link.requires_country && 
                                      <div className="pb-1">
                                        <label className="d-block text-start">
                                          <small className="text-black-50 pb-2">Country</small>
                                          <CountryTypeahead
                                            required
                                            id="country"
                                            placeholder="Select your country"
                                            onChange={(value)=>{setCountry(value)}}
                                          />
                                        </label>
                                      </div>
                                    }
                                    {
                                      link.custom_fields?.length > 0 &&
                                      link.custom_fields.map((field, index)=>(
                                        <div className="pb-1" key={ index }>
                                          <label className="d-block text-start">
                                            <small className="text-black-50 pb-2">{ field.name }</small>
                                            { field.type == 'string' && <input type="text" required className="d-block form-control mb-1 rounded-1" placeholder={ `Please enter ${field.name.toLowerCase()}` }/> }
                                            { field.type == 'number' && <input type="number" required className="d-block form-control mb-1 rounded-1" placeholder={ `Please enter ${field.name.toLowerCase()}` }/> }
                                            { field.caption && <div className="font-size-s lh-sm pb-2 px-1"><small className="text-black-50">{ field.caption }</small></div> }
                                          </label>
                                        </div>
                                      ))
                                    }
                                  </div>
                                }
                                <div>
                                  {
                                    link.unlock_url != null &&
                                    <div className="w-100">
                                      <button onClick={ ()=>{} } type="button" className="btn btn-sm btn-light rounded-2 py-2 opacity-50 w-100" title="Unlocks content after payment.">
                                        <span className="py-1">
                                          <i className="fas fa-unlock me-2"></i>
                                          <span className="ms-1">unlocks content</span>
                                        </span>
                                      </button>
                                    </div>
                                  }
                                  { !(((link.start_date && moment(link.start_date).toDate() > new Date()) || (link.end_date && new Date() >= moment(link.end_date).toDate())) ? true : false) &&
                                    !(link.limited_quantity == null ? false : (link.limited_quantity - (link.quantity_sold || 0) <= 0)) &&
                                    <div className="pt-2">
                                      { link.type == 'payment' &&
                                        <button
                                          type="submit"
                                          className="btn rounded-2 btn-lg btn-primary py-2 w-100"
                                          style={{ background: link?.configuration?.style?.colors?.primary || account.color || '#ea357a', borderColor: link?.configuration?.style?.colors?.primary || account.color || '#ea357a', color: link.configuration.style.colors.buttonText || (account.color ? (LightOrDark(account.color) == 'light' ? '#000000' : '#FFFFFF') : undefined) || '#FFFFFF'}}
                                        >
                                          <div className="py-1 w-100" style={{color: link.configuration.style.colors.buttonText || (account.color ? (LightOrDark(account.color) == 'light' ? '#000000' : '#FFFFFF') : undefined) || '#FFFFFF'}}>
                                            Pay
                                          </div>
                                        </button>
                                      }
                                      { link.type == 'donation' &&
                                        <button onClick={()=> setStartAmount()} type="submit" className="btn rounded-2 btn-lg py-2 w-100" style={{ background: link?.configuration?.style?.colors?.primary || account.color || '#ea357a', borderColor: link?.configuration?.style?.colors?.primary || account.color || '#ea357a', color: link.configuration.style.colors.buttonText || (account.color ? (LightOrDark(account.color) == 'light' ? '#000000' : '#FFFFFF') : undefined) || '#FFFFFF' }}>
                                          <div className="py-1 w-100" style={{color: link.configuration.style.colors.buttonText || (account.color ? (LightOrDark(account.color) == 'light' ? '#000000' : '#FFFFFF') : undefined) || '#FFFFFF'}}>
                                            Donate
                                          </div>
                                        </button>
                                      }
                                      { link.type == 'tip' &&
                                        <button onClick={()=> setStartAmount()} type="submit" className="btn rounded-2 btn-lg py-2 w-100" style={{ background: link?.configuration?.style?.colors?.primary || account.color || '#ea357a', borderColor: link?.configuration?.style?.colors?.primary || account.color || '#ea357a', color: link.configuration.style.colors.buttonText || (account.color ? (LightOrDark(account.color) == 'light' ? '#000000' : '#FFFFFF') : undefined) || '#FFFFFF' }}>
                                          <div className="py-1 w-100" style={{color: link.configuration.style.colors.buttonText || (account.color ? (LightOrDark(account.color) == 'light' ? '#000000' : '#FFFFFF') : undefined) || '#FFFFFF'}}>
                                            Tip
                                          </div>
                                        </button>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="py-1 ps-4 text-black-50" title="Sends a confirmation email to your account for every payment.">
                        <small>
                          ID: { link.external_id }
                        </small>
                      </div>
                      { link.confirmation_email_account && 
                        <div className="py-1 ps-4 text-black-50" title="Sends a confirmation email to your account for every payment.">
                          <small>
                            <i className="far fa-envelope me-2 pt-1"></i>
                            <i className="fas fa-long-arrow-alt-right me-2 pt-1"></i>
                            { account.email }
                          </small>
                        </div>
                      }
                      { link.confirmation_email_users && 
                        <div className="py-1 ps-4 text-black-50" title="Sends a confirmation email to your users for every payment.">
                          <small>
                            <i className="far fa-envelope me-2 pt-1"></i>
                            <i className="fas fa-long-arrow-alt-right me-2 pt-1"></i>
                            <i className="fas fa-user pt-1"></i>
                          </small>
                        </div>
                      }
                      { link.unlock_url != null && 
                        <div className="py-1 ps-4 text-black-50" title="Unlocks a URL upon succesful payment.">
                          <small>
                            <i className="fa-solid fa-lock-open me-2 pt-1"></i>
                            { link.unlock_url }
                          </small>
                        </div>
                      }
                      { link.redirect && 
                        <div className="py-1 ps-4 text-black-50" title={`Redirects users after completed payment to ${link.redirect}.`}>
                          <small>
                            <i className="fas fa-user me-2 pt-1"></i>
                            <i className="fas fa-long-arrow-alt-right me-2 pt-1"></i>
                            { link.redirect }
                          </small>
                        </div>
                      }
                      { link.callback != null && 
                        <div className="py-1 ps-4 text-black-50" title={`Sends payment callback via API.`}>
                          <small>
                            <i className="fas fa-satellite-dish me-2 pt-1"></i>
                            <i className="fas fa-long-arrow-alt-right me-2 pt-1"></i>
                            { link.callback }
                          </small>
                        </div>
                      }
                      <div className="pt-1">
                        <h2 className="h5 pt-2 text-black opacity-50 font-size-s">
                          QR Code
                        </h2>
                        <div className="position-relative d-block">
                          <div type="button" onClick={()=>{ setQrCodeExpanded(!qrCodeExpanded) }} className={ qrCodeExpanded ? "overflow-hidden rounded shadow p-3 bg-white" : "overflow-hidden rounded-1 shadow-sm p-1 bg-white d-inline-block" } style={ qrCodeExpanded ? {} : { width: '40px', height: 'auto' }}>
                            <div className="ratio ratio-1x1" ref={ qrCodeRef } />
                          </div>
                          <div className={ qrCodeExpanded ? 'd-none' : 'd-inline-block' } style={{ left: '4px', top: '-15px', marginBottom: '-15px', position: 'relative' }}>
                            <Dropdown
                              direction='left'
                              toggle={
                                <button className="btn btn-action-hover" type="button">
                                  <i className="fas fa-ellipsis-v text-black-50"></i>
                                </button>
                              }
                              children={[
                                ['Download Image', ()=>{ qrCode.download({ name: 'QR Code - DePay Payment Link', extension: "png" }) }, <i className="fa-solid fa-image me-2"></i>],
                                ['Download Vector', ()=>{ qrCode.download({ name: 'QR Code - DePay Payment Link', extension: "svg" }) }, <i className="fa-solid fa-vector-square me-2"></i>],
                              ]}
                            />
                          </div>
                          <div className={ qrCodeExpanded ? 'd-block pt-3' : 'd-none' }>
                            <button type="button" className="btn btn-action btn-sm me-3 mb-2" onClick={()=>{ qrCode.download({ name: 'QR Code - DePay Payment Link', extension: "png" }) }}><i className="fa-solid fa-image me-2"></i>Download Image</button>
                            <button type="button" className="btn btn-action btn-sm me-3 mb-2" onClick={()=>{ qrCode.download({ name: 'QR Code - DePay Payment Link', extension: "svg" }) }}><i className="fa-solid fa-vector-square me-2"></i>Download Vector</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
