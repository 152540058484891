import AddressEllipsis from "../../../helpers/AddressEllipsis"
import Blockchains from '@depay/web3-blockchains'
import React, { useState, useEffect, useMemo, useRef } from "react"
import WalletIcon from "../../../components/WalletIcon"
import { TokenImage } from '@depay/react-token-image'
import { Typeahead, Highlighter, TypeaheadMenu } from 'react-bootstrap-typeahead'

export default function(props) {

  const index = props.index
  const typeaheadInputEl = useRef()
  const blockchain = Blockchains.findByName(props.payment.token.blockchain)
  const isUnroutable = false
  const [ selectedWalletOption, setSelectedWalletOption ] = useState(
    props.accountWalletsAsOptionData.find((wallet)=>wallet.external_id === props.payment.receiver)
  )

  const applicatedAmount = props.payment.amount || props.amounts?.find((amount)=>(amount.token.blockchain === props.payment.token.blockchain && amount.token.address === props.payment.token.address))?.amount
  const invalidAmount = !applicatedAmount || parseFloat(applicatedAmount) <= 0

  return(
    <div className="accepted-payment mb-2">
      <div className="card d-block px-2 pb-3 position-relative">
        <div className="d-flex px-2 pb-1">
          <div className="col-2 d-flex align-items-center">
            <div className="position-relative d-inline-block">
              <div className="px-1">
                { props.payment.token.logo && <img className="w-100 rounded-circle" src={props.payment.token.logo} /> }
                { !props.payment.token.logo && <TokenImage className="w-100" blockchain={ props.payment.token.blockchain } address={ props.payment.token.address } /> }
              </div>
              <img className="blockchain-logo" style={{ backgroundColor: blockchain.logoBackgroundColor, width: '40%', height: 'auto' }} src={ blockchain.logo } title={`on ${ blockchain.label }`}/> 
            </div>
          </div>
          <div className="col-10 ps-3 pt-1">
            { isUnroutable &&
              <div>
                <div className="alert alert-danger mb-0 mt-1 px-3 py-2">
                  This token is not convertible! <a href="https://depay.com/docs/payments/supported/tokens" target="_blank" rel="noopener noreferrer">Learn why here.</a>
                </div>
              </div>
            }
            <div>
              <div className="font-size-m d-block">
                <strong>{props.payment.token.symbol}</strong> ({props.payment.token.name})&nbsp;
              </div>
              <div className="font-size-xs d-block">
                <a href={blockchain.explorerUrlFor({ token: props.payment.token.address })} target="_blank">{ AddressEllipsis(props.payment.token.address) }</a>
                <span> on { blockchain.label }</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-2 px-2">
          <div className="pt-1">
            { props.withAmounts &&
              <div>
                <label htmlFor={`receiver-${props.index}`} className="opacity-50 font-weight-medium pt-1">
                  <small>Receiver</small>
                </label>
              </div>
            }
            <div className="font-size-s input-group has-validation w-100 position-relative d-block" title="Select or enter receiver address">
              { selectedWalletOption &&
                <div
                  className={ ["d-flex ps-2 align-items-center wallet-selection rounded-2", (props.accountManaged !== true ? 'editable' : undefined), (props.payment.error ? 'is-invalid border border-danger' : undefined)].filter(Boolean).join(' ')}
                  onClick={()=>{
                    if(props.accountManaged === true) { return }
                    setSelectedWalletOption(undefined)
                    setTimeout(()=>{
                      typeaheadInputEl.current.inputNode.click()
                      typeaheadInputEl.current.inputNode.focus()
                    }, 100)
                  }}
                >
                  <div className="ps-1 pe-2 ps-1">
                    <WalletIcon wallet={ selectedWalletOption } size={ 30 }/>
                  </div>
                  <div className="ps-1 flex-grow-1 ps-1 pe-2">
                    <div className="text-truncate font-weight-medium">
                      { selectedWalletOption.name || "Unnamed Wallet" }
                    </div>
                    <div>
                      <small className="font-weight-medium opacity-50" title={ selectedWalletOption.external_id }>{ AddressEllipsis(selectedWalletOption.external_id) }</small>
                    </div>
                  </div>
                  { props.accountManaged !== true &&
                    <div className="text-end pe-2">
                      <i className="fa-solid fa-pen-to-square me-1"/>
                    </div>
                  }
                </div>
              }
              { !selectedWalletOption && props.accountManaged &&
                <div className="d-block pe-2">
                  <div title={ props.payment?.receiver } className="font-weight-medium text-truncate w-100 d-block position-relative">
                    <small>{ props.payment?.receiver }</small>
                  </div>
                </div>
              }
              { props.accountManaged !== true &&
                <Typeahead
                  ref={ typeaheadInputEl }
                  autocomplete={'off'}
                  selected={ [selectedWalletOption].filter(Boolean) }
                  required={ true }
                  allowNew={ false }
                  id={ 'receiverWallet' }
                  placeholder={ "Select or enter receiver wallet" }
                  multiple={ false }
                  className={ selectedWalletOption ? 'd-none' : "w-100" }
                  newSelectionPrefix=""
                  emptyLabel={null}
                  highlightOnlyResult={true}
                  defaultInputValue={ !selectedWalletOption ? props.payment.receiver : ''}
                  inputProps={{ id: `receiver-${props.index}`, name: `receiver-${props.index}`, className: ["d-block form-control w-100", props.payment.error ? 'is-invalid border border-danger' : undefined].filter(Boolean).join(' ') }}
                  renderMenu={(results, menuProps, props) => {
                    if (!results || !results.length) { return null }
                    return <TypeaheadMenu
                      {...menuProps}
                      labelKey={props.labelKey}
                      options={results}
                      text={props.text}
                    />
                  }}
                  renderMenuItemChildren={(option, { text }) => (
                    <div className="d-flex align-items-center flex-row py-1" title={ option.value }>
                      <div className="pe-2"><WalletIcon size={29} wallet={option} /></div>
                      <div className="ps-2 font-weight-medium font-size-s line-height-extra-small">
                        <div>
                          {option.name}
                        </div>
                        <div className="font-size-s opacity-50">
                          <span>{AddressEllipsis(option.value)}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  onInputChange={(text, event) => {
                    let newAcceptedPayments = props.acceptedPayments.slice()
                    let receiver = text
                    newAcceptedPayments = props.setReceiver(newAcceptedPayments, receiver, props.index, props.payment.token.blockchain)
                    newAcceptedPayments[props.index] = {... newAcceptedPayments[props.index], receiver}
                    props.onChange(newAcceptedPayments)
                  }}
                  onChange={(selection) => {
                    setSelectedWalletOption(selection[0])
                    let newAcceptedPayments = props.acceptedPayments.slice()
                    let receiver = selection[0].value
                    newAcceptedPayments = props.setReceiver(newAcceptedPayments, receiver, props.index, props.payment.token.blockchain)
                    newAcceptedPayments[props.index] = {... newAcceptedPayments[props.index], receiver}
                    props.onChange(newAcceptedPayments)
                  }}
                  options={ props.accountWalletsAsOptionData }
                />
              }
              {
                props.payment.error &&
                <div>
                  <input type="hidden" className="form-control is-invalid"/>
                  <div className="invalid-feedback font-size-m ps-2 font-weight-bold">
                    { props.payment.error }
                    <input required className="d-inline-block" tabIndex="-1" type="text" style={{ opacity: 0, position: 'absolute', bottom: 0, width: '3px', height: '3px', left: '50%' }}/>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        { props.withAmounts &&
          <div className="pt-2 pb-1 px-2">
            <div>
              <label htmlFor={`amount-${props.index}`} className="opacity-50 font-weight-medium">
                <small>Amount</small>
              </label>
            </div>
            <div className="font-size-s input-group has-validation">
              <div className="col-6">
                <input
                  id={`amount-${props.index}`}
                  required
                  placeholder='Amount'
                  name={`amount${props.index}`}
                  className={ `form-control rounded-2 py-2 px-3 ${invalidAmount ? 'is-invalid border border-danger' : ''}` }
                  type='number'
                  min="0"
                  value={ props.payment.amount || props.amounts?.find((amount)=>(amount.token.blockchain === props.payment.token.blockchain && amount.token.address === props.payment.token.address))?.amount || '' }
                  onChange={ (event)=>{
                    props.updatePaymentAmount(props.index, event.target.value)
                  }}
                />
              </div>
              {
                invalidAmount &&
                <div>
                  <input type="hidden" className="form-control is-invalid"/>
                  <div className="invalid-feedback font-size-m ps-2 font-weight-bold">
                    Please enter a valid amount!
                  </div>
                </div>
              }
            </div>
          </div>
        }
        {
          props.accountManaged !== true &&
          <button title="Remove" style={{ top: 0, right: 0 }} type="button" className="btn mt-1 me-1 btn-sm btn-grey font-size-xs position-absolute" onClick={ ()=> props.removeAcceptedPayment(props.index) }>
            <i className="far fa-trash-alt"></i>
          </button>
        }
      </div>
    </div>
  )
}
