import groupPayments from '../helpers/groupPayments'

const addPayments = (state, payments)=>{
  let newState = groupPayments(payments, state)
  return newState
}

const newState = ()=>({ byId: {}, groups: [], byGroupId: {} })

export default function PaymentReducer(state = newState(), action) {
  switch (action.type) {
    case 'payments/set':
      return addPayments(newState(), action.payments)
    case 'payments/add':
      return addPayments(state, action.payments)
    default:
      return state
  }
}
