export default function AccountUserReducer(state = [], action) {
  let newState
  switch (action.type) {
    case 'accountUsers/add':
      newState = (state).slice()
      newState.byExternalId = {}
      newState.push(action.accountUser)
      newState.byExternalId[action.accountUser.external_id] = action.accountUser
      return newState
    case 'accountUsers/set':
      newState = action.accountUsers.slice()
      newState.byExternalId = {}
      newState.forEach((accountUser)=>{
        newState.byExternalId = { [accountUser.external_id]: accountUser }
      })
      return newState
    case 'accountUsers/update':
      newState = (state).slice()
      let updatedLinkIndex = newState.findIndex((i)=>action.accountUser.id==i.id)
      if(updatedLinkIndex > -1){
        newState[updatedLinkIndex] = action.accountUser
        newState.byExternalId = newState.byExternalId || {}
        newState.byExternalId[action.accountUser.external_id] = action.accountUser
      }
      return newState
    case 'accountUsers/delete':
      let deletedAccountWallet
      newState = state.filter((accountUser)=>{
        if(accountUser.id == action.id) {
          deletedAccountWallet = accountUser
          return false
        } else {
          return true
        }
      })
      return newState
    default:
      return state
  }
}
