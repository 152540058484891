export default (country)=>{

  let data = {
    "CH": { "pattern": "CHE-\\d{3}\\.\\d{3}\\.\\d{3} MWST", "example": "CHE-421.972.173 MWST" },
    "DE": { "pattern": "DE\\d{9}", "example": "DE123456789" },
    "GB": { "pattern": "GB\\d{9}|GB\\d{12}|GBGD\\d{3}|GBHA\\d{3}", "example": "GB999999973" },
    "AT": { "pattern": "ATU\\d{8}", "example": "ATU99999999" },
    "BE": { "pattern": "BE0\\d{9}", "example": "BE0999999999" },
    "BG": { "pattern": "BG\\d{9,10}", "example": "BG999999999" },
    "CY": { "pattern": "CY\\d{8}L", "example": "CY99999999L" },
    "CZ": { "pattern": "CZ\\d{8,10}", "example": "CZ999999999" },
    "DK": { "pattern": "DK\\d{8}", "example": "DK99999999" },
    "EE": { "pattern": "EE\\d{9}", "example": "EE999999999" },
    "ES": { "pattern": "ES[A-Z]\\d{7}[A-Z]", "example": "ESA99999999" },
    "FI": { "pattern": "FI\\d{8}", "example": "FI99999999" },
    "FR": { "pattern": "FR[A-Z0-9]{2}\\d{9}", "example": "FRXX999999999" },
    "GR": { "pattern": "GR\\d{9}", "example": "GR999999999" },
    "HR": { "pattern": "HR\\d{11}", "example": "HR99999999999" },
    "HU": { "pattern": "HU\\d{8}", "example": "HU99999999" },
    "IE": { "pattern": "IE\\d{7}[A-W][A-I]", "example": "IE9S99999L" },
    "IT": { "pattern": "IT\\d{11}", "example": "IT99999999999" },
    "LT": { "pattern": "LT\\d{9}|LT\\d{12}", "example": "LT999999999" },
    "LU": { "pattern": "LU\\d{8}", "example": "LU99999999" },
    "LV": { "pattern": "LV\\d{11}", "example": "LV99999999999" },
    "MT": { "pattern": "MT\\d{8}", "example": "MT99999999" },
    "NL": { "pattern": "NL\\d{9}B\\d{2}", "example": "NL999999999B99" },
    "PL": { "pattern": "PL\\d{10}", "example": "PL9999999999" },
    "PT": { "pattern": "PT\\d{9}", "example": "PT999999999" },
    "RO": { "pattern": "RO\\d{2,10}", "example": "RO999999999" },
    "SE": { "pattern": "SE\\d{12}", "example": "SE999999999999" },
    "SI": { "pattern": "SI\\d{8}", "example": "SI99999999" },
    "SK": { "pattern": "SK\\d{10}", "example": "SK9999999999" }
  }

  if(data[country]) {
    return data[country].example
  }
}
