import copy from 'copy-to-clipboard'
import React, { useState } from "react"
import TooltipDrawer from './TooltipDrawer'

export default (props)=>{

  const [ showTooltip, setShowTooltip ] = useState()

  const onClickCopy = ()=>{
    setShowTooltip(true)
    copy(props.publicKey)
  }
  
  return(
    <div className="d-inline-block position-relative">
      <div className='d-inline-block position-relative'>
        <button type="button" onClick={ onClickCopy } className='btn btn-sm btn-action text-nowrap' title="Click to copy">
          <i className="fa-solid fa-fingerprint me-2"></i>
          Public Key
        </button>
      </div>
      <TooltipDrawer show={showTooltip} set={setShowTooltip} text={"Copied"}/>
    </div>    
  )  
}
