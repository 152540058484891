export default function AccountUserReducer(state = [], action) {
  let newState
  switch (action.type) {
    case 'users/add':
      newState = (state).slice()
      newState.byId = state.byId ? JSON.parse(JSON.stringify(state.byId)) : {}
      newState.push(action.user)
      newState.byId[action.user.id] = action.user
      return newState
    case 'users/set':
      newState = action.users.slice()
      newState.byId = state.byId ? JSON.parse(JSON.stringify(state.byId)) : {}
      action.users.forEach((user)=>{
        newState.byId[user.id] = user
      })
      return newState
    case 'users/update':
      newState = (state).slice()
      newState.byId = state.byId ? JSON.parse(JSON.stringify(state.byId)) : {}
      let updatedLinkIndex = newState.findIndex((i)=>action.user.id==i.id)
      if(updatedLinkIndex > -1){
        newState[updatedLinkIndex] = action.user
        newState.byId = newState.byId || {}
        newState.byId[action.user.id] = action.user
      }
      return newState
    case 'users/delete':
      newState = state.filter((user)=>{
        if(user.id == action.id) {
          return false
        } else {
          return true
        }
      })
      newState.byId = state.byId ? JSON.parse(JSON.stringify(state.byId)) : {}
      delete newState.byId[action.id]
      return newState
    default:
      return state
  }
}
