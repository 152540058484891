export default function PaywallReducer(state = null, action) {
  switch (action.type) {
    case 'paywall/show':
      return true
    case 'paywall/hide':
      return false
    default:
      return state
  }
}
